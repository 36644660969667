import { render, staticRenderFns } from "./Detail.vue?vue&type=template&id=2aca4d56&"
import script from "./Detail.vue?vue&type=script&lang=ts&"
export * from "./Detail.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VAlert,VCard,VCol,VContainer,VRow,VSimpleTable})


/* hot reload */
if (module.hot) {
  var api = require("/builds/riverline/optincollect-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2aca4d56')) {
      api.createRecord('2aca4d56', component.options)
    } else {
      api.reload('2aca4d56', component.options)
    }
    module.hot.accept("./Detail.vue?vue&type=template&id=2aca4d56&", function () {
      api.rerender('2aca4d56', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/campaignFactory/Detail.vue"
export default component.exports