var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "v-row",
        { attrs: { dense: true } },
        [
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: { name: "email", label: "Email", dense: true },
                model: {
                  value: _vm.collectedData.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.collectedData, "email", $$v)
                  },
                  expression: "collectedData.email",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: { name: "cookieUid", label: "Cookie UID", dense: true },
                model: {
                  value: _vm.collectedData.cookieUid,
                  callback: function ($$v) {
                    _vm.$set(_vm.collectedData, "cookieUid", $$v)
                  },
                  expression: "collectedData.cookieUid",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: true } },
        [
          _c("v-text-field", {
            attrs: { name: "ip", label: "IP Address", dense: true },
            model: {
              value: _vm.collectedData.ip,
              callback: function ($$v) {
                _vm.$set(_vm.collectedData, "ip", $$v)
              },
              expression: "collectedData.ip",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: true } },
        [
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: { name: "firstname", label: "Firstname", dense: true },
                model: {
                  value: _vm.collectedData.firstname,
                  callback: function ($$v) {
                    _vm.$set(_vm.collectedData, "firstname", $$v)
                  },
                  expression: "collectedData.firstname",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: { name: "lastname", label: "Lastname", dense: true },
                model: {
                  value: _vm.collectedData.lastname,
                  callback: function ($$v) {
                    _vm.$set(_vm.collectedData, "lastname", $$v)
                  },
                  expression: "collectedData.lastname",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: true } },
        [
          _c(
            "v-col",
            [
              _c("v-select", {
                attrs: {
                  items: _vm.civilities,
                  name: "civility",
                  label: "Civility",
                  dense: true,
                },
                model: {
                  value: _vm.collectedData.civility,
                  callback: function ($$v) {
                    _vm.$set(_vm.collectedData, "civility", $$v)
                  },
                  expression: "collectedData.civility",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: { name: "birthday", label: "Birthday", dense: true },
                model: {
                  value: _vm.collectedData.birthday,
                  callback: function ($$v) {
                    _vm.$set(_vm.collectedData, "birthday", $$v)
                  },
                  expression: "collectedData.birthday",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: true } },
        [
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: { name: "zipcode", label: "Zipcode", dense: true },
                model: {
                  value: _vm.collectedData.zipcode,
                  callback: function ($$v) {
                    _vm.$set(_vm.collectedData, "zipcode", $$v)
                  },
                  expression: "collectedData.zipcode",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: { name: "city", label: "City", dense: true },
                model: {
                  value: _vm.collectedData.city,
                  callback: function ($$v) {
                    _vm.$set(_vm.collectedData, "city", $$v)
                  },
                  expression: "collectedData.city",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: true } },
        [
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: { name: "address", label: "Address", dense: true },
                model: {
                  value: _vm.collectedData.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.collectedData, "address", $$v)
                  },
                  expression: "collectedData.address",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: true } },
        [
          _c(
            "v-col",
            { attrs: { "align-self": "end" } },
            [
              _c("v-text-field", {
                attrs: { name: "phone", label: "Phone", dense: true },
                model: {
                  value: _vm.collectedData.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.collectedData, "phone", $$v)
                  },
                  expression: "collectedData.phone",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { "align-self": "end" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.countries,
                  name: "country",
                  label: "Country",
                  dense: true,
                },
                model: {
                  value: _vm.collectedData.country,
                  callback: function ($$v) {
                    _vm.$set(_vm.collectedData, "country", $$v)
                  },
                  expression: "collectedData.country",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: true } },
        [
          _c(
            "v-col",
            { attrs: { "align-self": "end" } },
            [
              _c("v-text-field", {
                attrs: { name: "custom1", label: "Custom1", dense: true },
                model: {
                  value: _vm.collectedData.custom1,
                  callback: function ($$v) {
                    _vm.$set(_vm.collectedData, "custom1", $$v)
                  },
                  expression: "collectedData.custom1",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { "align-self": "end" } },
            [
              _c("v-text-field", {
                attrs: { name: "custom2", label: "Custom2", dense: true },
                model: {
                  value: _vm.collectedData.custom2,
                  callback: function ($$v) {
                    _vm.$set(_vm.collectedData, "custom2", $$v)
                  },
                  expression: "collectedData.custom2",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { "align-self": "end" } },
            [
              _c("v-text-field", {
                attrs: { name: "referer", label: "Referer", dense: true },
                model: {
                  value: _vm.collectedData.referer,
                  callback: function ($$v) {
                    _vm.$set(_vm.collectedData, "referer", $$v)
                  },
                  expression: "collectedData.referer",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mb-5", attrs: { dense: true } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-textarea", {
                attrs: { name: "complex", label: "Complex", dense: true },
                on: { change: _vm.formatComplex },
                model: {
                  value: _vm.collectedData.complex,
                  callback: function ($$v) {
                    _vm.$set(_vm.collectedData, "complex", $$v)
                  },
                  expression: "collectedData.complex",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("complex-modal", {
                attrs: { complex: _vm.collectedData.complex },
                on: { "complex-generated": _vm.updateComplex },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-textarea", {
                attrs: { name: "profiling", label: "Profiling", dense: true },
                on: { change: _vm.formatProfiling },
                model: {
                  value: _vm.collectedData.profiling,
                  callback: function ($$v) {
                    _vm.$set(_vm.collectedData, "profiling", $$v)
                  },
                  expression: "collectedData.profiling",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("profiling-modal", {
                attrs: { profiling: _vm.collectedData.profiling },
                on: { "profiling-generated": _vm.updateProfiling },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }