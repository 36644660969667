"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_property_decorator_1 = require("vue-property-decorator");
var CampaignFactoryButtonsComponent = (function (_super) {
    __extends(CampaignFactoryButtonsComponent, _super);
    function CampaignFactoryButtonsComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.displayJsonFactory = false;
        return _this;
    }
    Object.defineProperty(CampaignFactoryButtonsComponent.prototype, "jsonFactory", {
        get: function () {
            return JSON.stringify({
                type: this.campaignFactory.type,
                class: this.campaignFactory.className,
                parameters: this.campaignFactory.parameters
            });
        },
        enumerable: false,
        configurable: true
    });
    CampaignFactoryButtonsComponent.prototype.submitForm = function () {
        this.$emit('form-is-submitted', true);
    };
    CampaignFactoryButtonsComponent.prototype.copyJson = function () {
        this.displayJsonFactory = true;
        this.$nextTick(function () {
            var jsonFactoryElement = document.querySelector('#json-factory');
            jsonFactoryElement.select();
            try {
                var success = document.execCommand('copy');
                this.displayJsonFactory = false;
                if (success) {
                    this.$emit('success', this.$tc('campaign_factory.information.success.copy'));
                }
                else {
                    this.$emit('error', this.$tc('campaign_factory.information.success.error'));
                }
            }
            catch (err) {
                this.$emit('error', this.$tc('campaign_factory.information.success.error'));
            }
        });
    };
    __decorate([
        vue_property_decorator_1.Prop(),
        __metadata("design:type", Boolean)
    ], CampaignFactoryButtonsComponent.prototype, "sendButtonEnabled", void 0);
    __decorate([
        vue_property_decorator_1.Prop(),
        __metadata("design:type", Object)
    ], CampaignFactoryButtonsComponent.prototype, "campaignFactory", void 0);
    CampaignFactoryButtonsComponent = __decorate([
        vue_class_component_1.default
    ], CampaignFactoryButtonsComponent);
    return CampaignFactoryButtonsComponent;
}(vue_1.default));
exports.default = CampaignFactoryButtonsComponent;
