var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12", md: "10", "offset-md": "1" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    [
                      _vm.error
                        ? [
                            _c("h4", [
                              _vm._v(_vm._s(_vm.$t("errors.no_campaign"))),
                            ]),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.forbidden
                        ? [
                            _c("h4", [
                              _vm._v(_vm._s(_vm.$t("errors.forbidden_access"))),
                            ]),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.campaign
                        ? [
                            _c(
                              "v-row",
                              {
                                staticClass: "mt-2 ml-2",
                                attrs: { align: "center" },
                              },
                              [
                                _c("h4", [
                                  _vm._v(
                                    _vm._s(_vm.$t("conversions.campaign_title"))
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-row",
                              {
                                staticClass: "mt-2 ml-2",
                                attrs: { align: "center" },
                              },
                              [
                                _c("campaign-flag-component", {
                                  staticClass: "mr-2",
                                  attrs: { campaign: _vm.campaign },
                                }),
                                _vm._v(" "),
                                _c("campaign-state-component", {
                                  staticClass: "mr-2",
                                  attrs: {
                                    state: _vm.campaign.state,
                                    small: "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("h4", { staticClass: "text-body-2" }, [
                                  _vm._v(_vm._s(_vm.campaign.name)),
                                ]),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.campaign
                        ? [
                            _vm.campaign.isTypeSponsoring() ||
                            _vm.campaign.isTypeSponsoringComplex()
                              ? _c("sponsoring-bat-component", {
                                  attrs: {
                                    campaign: _vm.campaign,
                                    token: _vm.token,
                                  },
                                })
                              : _vm.campaign.isTypeSponsoringCheckbox()
                              ? _c("SponsoringCheckboxBatComponent", {
                                  attrs: {
                                    campaign: _vm.campaign,
                                    token: _vm.token,
                                  },
                                })
                              : _c("coregistration-bat-component", {
                                  attrs: {
                                    campaign: _vm.campaign,
                                    token: _vm.token,
                                  },
                                }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }