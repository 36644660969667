var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "900" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    attrs: { color: "primary", dark: "" },
                    on: { click: _vm.parseUrl },
                  },
                  on
                ),
                [_vm._v(_vm._s(_vm.$t("campaign_factory.form.manage_url")))]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "text-h5" }, [
            _vm._v(_vm._s(_vm.$t("campaign_factory.form.url_parameters"))),
          ]),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.send.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._l(
                    _vm.urlParameters,
                    function (urlParameter, urlParameterIndex) {
                      return _c(
                        "v-row",
                        { key: urlParameterIndex },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "4", sm: "12", md: "4" } },
                            [
                              _c("v-text-field", {
                                attrs: { label: "Key" },
                                model: {
                                  value: urlParameter.key,
                                  callback: function ($$v) {
                                    _vm.$set(urlParameter, "key", $$v)
                                  },
                                  expression: "urlParameter.key",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "4", sm: "12", md: "4" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  label: _vm.$t(
                                    "campaign_factory.form.label.common_values"
                                  ),
                                  items: _vm.collectedDataCodes,
                                  clearable: true,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.updateValueInput(
                                      urlParameterIndex
                                    )
                                  },
                                },
                                model: {
                                  value: urlParameter.commonValue,
                                  callback: function ($$v) {
                                    _vm.$set(urlParameter, "commonValue", $$v)
                                  },
                                  expression: "urlParameter.commonValue",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "3", sm: "12", md: "3" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: _vm.$t(
                                    "campaign_factory.form.label.value"
                                  ),
                                  disabled:
                                    urlParameter.commonValue !== null &&
                                    urlParameter.commonValue !== undefined,
                                },
                                model: {
                                  value: urlParameter.value,
                                  callback: function ($$v) {
                                    _vm.$set(urlParameter, "value", $$v)
                                  },
                                  expression: "urlParameter.value",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "1", sm: "12", md: "1" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mt-5",
                                  attrs: { small: "", color: "red" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeUrlParameter(
                                        urlParameterIndex
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { color: "white" } }, [
                                    _vm._v("mdi-minus"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: { click: _vm.addUrlParameter },
                    },
                    [
                      _c("v-icon", { attrs: { dark: "" } }, [
                        _vm._v("mdi-plus"),
                      ]),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "grey" },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("actions.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "secondary" },
                  on: { click: _vm.generateUrl },
                },
                [_vm._v(_vm._s(_vm.$t("actions.valid")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }