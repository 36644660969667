var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      !_vm.user
        ? _c("v-speed-dial", {
            attrs: {
              top: false,
              bottom: true,
              right: true,
              left: false,
              direction: "left",
              transition: "slide-y-reverse-transition",
              fixed: "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "activator",
                  fn: function () {
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "pink darken-4",
                          attrs: { dark: "", rounded: "" },
                          on: {
                            click: function ($event) {
                              _vm.state.show = true
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("users.actions.add_user")))]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "pink darken-4",
                          attrs: { dark: "", rounded: "" },
                          on: {
                            click: function ($event) {
                              _vm.state.show = true
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("add")])],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1483433819
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "800px" },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              _vm.state.show = false
            },
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                return [
                  _vm.user
                    ? _c(
                        "v-btn",
                        _vm._g(
                          {
                            attrs: { icon: "", small: "", color: "secondary" },
                          },
                          on
                        ),
                        [_c("v-icon", [_vm._v("mode_edit")])],
                        1
                      )
                    : _vm._e(),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.show,
            callback: function ($$v) {
              _vm.$set(_vm.state, "show", $$v)
            },
            expression: "state.show",
          },
        },
        [
          _vm._v(" "),
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "primary white--text" }, [
                _vm.user
                  ? _c("span", { staticClass: "text-h5" }, [
                      _vm._v(_vm._s(_vm.$t("users.modal.title_edit"))),
                    ])
                  : _c("span", { staticClass: "text-h5" }, [
                      _vm._v(_vm._s(_vm.$t("users.modal.title_create"))),
                    ]),
              ]),
              _vm._v(" "),
              _c(
                "v-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-alert",
                        {
                          attrs: {
                            color: "error",
                            value: _vm.globalError ? true : false,
                            icon: "warning",
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.globalError) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|email",
                            expression: "'required|email'",
                          },
                        ],
                        attrs: {
                          placeholder: _vm.$t("users.modal.form.email"),
                          required: "",
                          name: "email",
                          "error-messages": _vm.errors.has("email")
                            ? [_vm.errors.first("email")]
                            : [],
                          disabled: _vm.user !== undefined,
                        },
                        model: {
                          value: _vm.model.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "email", $$v)
                          },
                          expression: "model.email",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { attrs: { justify: "center", align: "center" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "users.modal.form.firstname"
                                  ),
                                  name: "firstname",
                                  "error-messages": _vm.errors.has("firstname")
                                    ? [_vm.errors.first("firstname")]
                                    : [],
                                },
                                model: {
                                  value: _vm.model.firstname,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "firstname", $$v)
                                  },
                                  expression: "model.firstname",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  placeholder: _vm.$t(
                                    "users.modal.form.lastname"
                                  ),
                                  name: "lastname",
                                  "error-messages": _vm.errors.has("firstname")
                                    ? [_vm.errors.first("firstname")]
                                    : [],
                                },
                                model: {
                                  value: _vm.model.lastname,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "lastname", $$v)
                                  },
                                  expression: "model.lastname",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-select", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: {
                          items: _vm.roles,
                          name: "role",
                          placeholder: _vm.$t("users.modal.form.role"),
                          error: _vm.errors.has("role"),
                          disabled: _vm.user && _vm.user.hasRoleDPO(),
                        },
                        model: {
                          value: _vm.model.role,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "role", $$v)
                          },
                          expression: "model.role",
                        },
                      }),
                      _vm._v(" "),
                      _vm.user
                        ? _c("v-select", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            attrs: {
                              items: _vm.states,
                              required: "",
                              name: "enabled",
                              "error-messages": _vm.errors.has("enabled")
                                ? [_vm.errors.first("enabled")]
                                : [],
                            },
                            model: {
                              value: _vm.model.enabled,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "enabled", $$v)
                              },
                              expression: "model.enabled",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: _vm.user ? "" : "required",
                            expression: "user ? '' : 'required'",
                          },
                        ],
                        attrs: {
                          type: "password",
                          name: "password",
                          placeholder: _vm.user
                            ? _vm.$t("users.modal.form.new_password")
                            : _vm.$t("users.modal.form.password"),
                          "error-messages": _vm.errors.has("password")
                            ? [_vm.errors.first("password")]
                            : [],
                        },
                        model: {
                          value: _vm.model.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.model, "password", $$v)
                          },
                          expression: "model.password",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          nativeOn: {
                            click: function ($event) {
                              return _vm.cancel.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("actions.cancel")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.inProgress
                        ? _c(
                            "v-btn",
                            { staticClass: "secondary" },
                            [
                              _c("v-progress-circular", {
                                attrs: { size: "20", indeterminate: "" },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "v-btn",
                            {
                              staticClass: "secondary",
                              attrs: { type: "submit" },
                            },
                            [
                              !_vm.user
                                ? [_vm._v(_vm._s(_vm.$t("actions.create")))]
                                : [_vm._v(_vm._s(_vm.$t("actions.save")))],
                            ],
                            2
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }