"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var ConsentFormComponent = (function (_super) {
    __extends(ConsentFormComponent, _super);
    function ConsentFormComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.dialog = true;
        _this.model = { email: '' };
        return _this;
    }
    ConsentFormComponent.prototype.search = function () {
        var _this = this;
        this.$validator.validateAll().then(function (result) {
            if (result === true) {
                _this.$router.push({
                    name: 'consents_list',
                    params: { email: _this.model.email }
                });
            }
        });
    };
    ConsentFormComponent = __decorate([
        vue_class_component_1.default
    ], ConsentFormComponent);
    return ConsentFormComponent;
}(vue_1.default));
exports.default = ConsentFormComponent;
