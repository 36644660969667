var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.campaignFactory
        ? _c(
            "v-card",
            [
              _c("campaign-factory-alert-component", {
                ref: "campaignFactoryAlert",
              }),
              _vm._v(" "),
              _c("campaign-factory-title-component", {
                attrs: {
                  type: _vm.campaignFactoryType,
                  update:
                    undefined !== _vm.campaignFactory &&
                    undefined !== _vm.campaignFactory.id,
                },
              }),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6", sm: "12", md: "6" } },
                    [
                      _vm.campaignFactory
                        ? _c("campaign-factory-form-component", {
                            ref: "campaignFactoryForm",
                            attrs: {
                              "campaign-factory": _vm.campaignFactory,
                              type: _vm.campaignFactoryType,
                            },
                            on: {
                              "form-is-loaded": _vm.initializeForm,
                              error: _vm.displayError,
                              "update-success": _vm.displaySuccess,
                              "create-success": _vm.createSuccess,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "6", sm: "12", md: "6" } },
                    [
                      _vm.campaignFactory.className
                        ? _c("campaign-factory-test-component", {
                            ref: "campaignFactoryTest",
                            attrs: {
                              "campaign-factory": _vm.campaignFactory,
                              "send-button-enabled": _vm.sendButtonEnabled,
                              "test-response": _vm.testResponse,
                            },
                            on: {
                              error: _vm.displayError,
                              "parameters-error": _vm.handleErrorResponse,
                              "send-test": _vm.sendTest,
                              success: _vm.displayTestResponse,
                              "start-send-test": _vm.startSendTest,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("campaign-factory-buttons-component", {
                attrs: {
                  "send-button-enabled": _vm.sendButtonEnabled,
                  "campaign-factory": _vm.campaignFactory,
                },
                on: {
                  "form-is-submitted": _vm.submitForm,
                  success: _vm.displaySuccess,
                  error: _vm.displayError,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _vm.debug
            ? _c(
                "v-alert",
                { attrs: { type: "info" } },
                [
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-simple-table",
                        { attrs: { light: true, dense: true } },
                        [
                          _c(
                            "tbody",
                            [
                              _vm.testResponse
                                ? _c("tr", [
                                    _c("td", [_vm._v("cacheable")]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(_vm.testResponse.cacheable)
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(_vm.debug, function (value, name) {
                                return _c("tr", { key: value.name }, [
                                  _c("td", [_vm._v(_vm._s(name))]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("truncate")(value, 2000, "...")
                                      )
                                    ),
                                  ]),
                                ])
                              }),
                            ],
                            2
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }