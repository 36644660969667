"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var optin_client_1 = require("@riverline/optin-client");
var market_model_1 = require("../../model/market.model");
var vue_property_decorator_1 = require("vue-property-decorator");
var SponsoringCheckboxModalComponent = (function (_super) {
    __extends(SponsoringCheckboxModalComponent, _super);
    function SponsoringCheckboxModalComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.ads = [];
        return _this;
    }
    SponsoringCheckboxModalComponent.prototype.mounted = function () {
        this.renderAd();
    };
    SponsoringCheckboxModalComponent.prototype.renderAd = function () {
        var _this = this;
        var locale;
        switch (this.market) {
            case market_model_1.MarketEnum.IT:
                locale = 'it_IT';
                break;
            case market_model_1.MarketEnum.ES:
                locale = 'es_ES';
                break;
            default:
                locale = 'fr_FR';
        }
        optin_client_1.OptinCollect.Configuration.setParameter(optin_client_1.OptinCollect.ParameterEnum.Locale, locale);
        optin_client_1.OptinCollect.Configuration.setParameter(optin_client_1.OptinCollect.ParameterEnum.DataSource, 'memory');
        var configuration = new optin_client_1.OptinCollect.SponsoringCheckbox.Configuration(945, 'sponsors_checkbox', 'application-tracking');
        var data = new optin_client_1.OptinCollect.CollectedData();
        optin_client_1.OptinCollect.InMemoryRepository.setAds([this.ad]);
        this.sponsoringCheckboxRenderer =
            new optin_client_1.OptinCollect.SponsoringCheckbox.Renderer(configuration, data);
        this.sponsoringCheckboxRenderer.addEventListener('render', function (e) {
            _this.ads = e.detail.ads;
        });
        this.sponsoringCheckboxRenderer.render();
        var infoButtons = Array.from(document.querySelectorAll('.toggle-information'));
        var closeInfoButtons = Array.from(document.querySelectorAll('.container-consent__container-submit__button__information__close'));
        var close = function (infoButton) {
            var _a, _b;
            var informationModal = (_b = (_a = infoButton === null || infoButton === void 0 ? void 0 : infoButton.parentNode) === null || _a === void 0 ? void 0 : _a.querySelector('.container-consent__container-submit__button__information')) !== null && _b !== void 0 ? _b : infoButton === null || infoButton === void 0 ? void 0 : infoButton.parentNode;
            infoButton.addEventListener('click', function () {
                if (!informationModal) {
                    return;
                }
                var currentState = informationModal.dataset.state;
                informationModal.dataset.state =
                    currentState === 'hidden' ? 'show' : 'hidden';
            });
        };
        infoButtons.concat(closeInfoButtons).forEach(function (btn) { return close(btn); });
    };
    SponsoringCheckboxModalComponent.prototype.acceptChoices = function () {
        if (!this.ads[0]) {
            return;
        }
        var spcContainer = document.querySelector('.container-consent');
        var spcError = document.querySelector('.container-sponso_error');
        var isAccepted = this.ads[0].accepted;
        spcContainer.dataset.state = isAccepted ? 'idle' : 'error';
        spcError.dataset.state = isAccepted ? 'idle' : 'error';
    };
    __decorate([
        vue_property_decorator_1.Prop(),
        __metadata("design:type", Object)
    ], SponsoringCheckboxModalComponent.prototype, "ad", void 0);
    __decorate([
        vue_property_decorator_1.Prop(),
        __metadata("design:type", String)
    ], SponsoringCheckboxModalComponent.prototype, "market", void 0);
    SponsoringCheckboxModalComponent = __decorate([
        vue_class_component_1.default({})
    ], SponsoringCheckboxModalComponent);
    return SponsoringCheckboxModalComponent;
}(vue_1.default));
exports.default = SponsoringCheckboxModalComponent;
