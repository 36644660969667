"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SERVICE_IDENTIFIER = {
    LOCAL_STORAGE: Symbol('LocalStorage'),
    ACCESS_TOKEN_STORAGE: Symbol('AccessTokenStorage'),
    CONTAINER: Symbol('Container'),
    SERVICE_CLIENT: Symbol('ClientService'),
    SERVICE_LOCALE: Symbol('LocaleService'),
    SERVICE_LOGIN: Symbol('LoginService'),
    SERVICE_DOWNLOAD_LEADS: Symbol('DownloadLeadsService'),
    REPOSITORY_ADVERTISER: Symbol('AdvertiserRepository'),
    REPOSITORY_CAMPAIGN: Symbol('CampaignRepository'),
    REPOSITORY_AD: Symbol('AdRepository'),
    REPOSITORY_CONVERSION: Symbol('ConversionRepository'),
    REPOSITORY_CAMPAIGN_STATISTIC: Symbol('CampaignStatisticRepository'),
    REPOSITORY_USER: Symbol('UserRepository'),
    REPOSITORY_COLLECT: Symbol('CollectRepository'),
    REPOSITORY_OPPOSITION: Symbol('OppositionRepository'),
    REPOSITORY_CAMPAIGN_FACTORY: Symbol('CampaignFactoryRepository'),
    REPOSITORY_LOG: Symbol('LogRepository'),
    I18N_FACTORY: Symbol('I18nFactory')
};
exports.default = SERVICE_IDENTIFIER;
