"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var decorators_1 = require("../../decorator/decorators");
var user_repository_1 = require("../../repositories/user.repository");
var config_1 = require("../../dic/config");
var ProfileComponent = (function (_super) {
    __extends(ProfileComponent, _super);
    function ProfileComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProfileComponent.prototype.mounted = function () {
        var _this = this;
        this.userRepository.me().then(function (user) { return (_this.user = user); });
    };
    ProfileComponent.prototype.data = function () {
        return {
            user: this.user
        };
    };
    __decorate([
        decorators_1.Container(config_1.default.REPOSITORY_USER),
        __metadata("design:type", user_repository_1.UserRepository)
    ], ProfileComponent.prototype, "userRepository", void 0);
    ProfileComponent = __decorate([
        vue_class_component_1.default
    ], ProfileComponent);
    return ProfileComponent;
}(vue_1.default));
exports.default = ProfileComponent;
