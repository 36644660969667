"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var UrlModal_vue_1 = require("./UrlModal.vue");
var decorators_1 = require("../../decorator/decorators");
var config_1 = require("../../dic/config");
var campaignFactory_repository_1 = require("../../repositories/campaignFactory.repository");
var vuex_class_1 = require("vuex-class");
var vue_property_decorator_1 = require("vue-property-decorator");
var layoutModule = vuex_class_1.namespace('layout');
var CampaignFactoryFormComponent = (function (_super) {
    __extends(CampaignFactoryFormComponent, _super);
    function CampaignFactoryFormComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CampaignFactoryFormComponent.prototype.data = function () {
        return {
            campaignFactoryForms: this.campaignFactoryForms,
            selectedCampaignFactoryForm: this.selectedCampaignFactoryForm
        };
    };
    CampaignFactoryFormComponent.prototype.mounted = function () {
        this.campaignUuid = this.$route.params['campaignUuid'];
        this.updateCampaignFactoryForms();
        this.$validator.extend('factoryUrl', function (value) {
            try {
                new URL(value);
            }
            catch (_) {
                return false;
            }
            return true;
        });
    };
    CampaignFactoryFormComponent.prototype.updateCampaignFactoryForms = function () {
        var _this = this;
        this.showProgressBar();
        this.campaignFactoryRepository
            .getCampaignFactoryFormDefinitions(this.campaignFactory.type)
            .then(function (campaignFactoryForms) {
            _this.campaignFactoryForms = campaignFactoryForms;
            _this.loadForm();
            _this.hideProgressBar();
        });
    };
    CampaignFactoryFormComponent.prototype.loadForm = function () {
        var _this = this;
        this.selectedCampaignFactoryForm = this.campaignFactoryForms.find(function (campaignFactoryForm) {
            return campaignFactoryForm.formType == _this.campaignFactory.className;
        });
        if (undefined !== this.selectedCampaignFactoryForm) {
            this.initializeParameters(this.selectedCampaignFactoryForm.fields, this.campaignFactory.parameters);
            this.$validator.errors.clear();
            this.globalError = '';
            this.$emit('form-is-loaded');
        }
        else if (undefined !== this.campaignFactory.id) {
            this.$emit('error', this.$tc('campaign_factory.information.error.unknown_classname') +
                ' ' +
                this.campaignFactory.className);
        }
    };
    CampaignFactoryFormComponent.prototype.formatChoices = function (choices, fieldKey) {
        var _this = this;
        var options = [];
        var optionsValues = Object.keys(choices);
        optionsValues.forEach(function (optionValue) {
            var option = { value: '', text: '' };
            var optionKey = choices[optionValue];
            var trans = _this.$tc('campaign_factory.form.choice.' + fieldKey + '.' + optionValue);
            var optionText = trans !== 'campaign_factory.form.choice.' + fieldKey + '.' + optionValue
                ? trans
                : optionValue;
            option['value'] = optionKey;
            option['text'] = optionText;
            options.push(option);
        });
        return options;
    };
    CampaignFactoryFormComponent.prototype.hasConstraint = function (field, constraintName, primitive) {
        if (field.constraints.length === 0) {
            return false;
        }
        var constraint = field.constraints
            .filter(function (constraint) { return constraint.id === constraintName; })
            .shift();
        if (undefined === constraint) {
            return false;
        }
        if (constraint.id === 'Type') {
            return constraint.options.type === primitive;
        }
        else {
            return true;
        }
    };
    CampaignFactoryFormComponent.prototype.getRangeSide = function (field, side) {
        if (field.constraints.length === 0) {
            return false;
        }
        var constraint = field.constraints
            .filter(function (constraint) { return constraint.id === 'Range'; })
            .shift();
        if (undefined === constraint) {
            return false;
        }
        if ('min' === side && undefined !== constraint.options.min) {
            return constraint.options.min;
        }
        if ('max' === side && undefined !== constraint.options.max) {
            return constraint.options.max;
        }
        return false;
    };
    CampaignFactoryFormComponent.prototype.updateUrl = function (key, urlValue) {
        vue_1.default.set(this.campaignFactory.parameters, key, urlValue);
    };
    CampaignFactoryFormComponent.prototype.send = function () {
        var _this = this;
        if (undefined === this.selectedCampaignFactoryForm) {
            return false;
        }
        this.globalError = '';
        this.showProgressBar();
        this.$validator.errors.clear();
        this.validateAll().then(function (valid) {
            if (!valid) {
                _this.hideProgressBar();
                _this.globalError = _this.$tc('errors.form_validation_generic');
                _this.$emit('error', _this.globalError);
            }
            else {
                var callbackFail = function (response) {
                    _this.handleErrorResponse(response);
                };
                if (undefined !== _this.campaignFactory.id) {
                    _this.campaignFactoryRepository
                        .update(_this.campaignFactory, _this.campaignUuid)
                        .then(function () {
                        _this.hideProgressBar();
                        _this.$emit('update-success', _this.$t('campaign_factory.information.success.update'));
                    })
                        .catch(callbackFail);
                }
                else {
                    _this.campaignFactoryRepository
                        .create(_this.campaignFactory, _this.campaignUuid)
                        .then(function (campaignFactory) {
                        _this.hideProgressBar();
                        _this.$emit('create-success', campaignFactory);
                    })
                        .catch(callbackFail);
                }
            }
        });
        return true;
    };
    CampaignFactoryFormComponent.prototype.initializeParameters = function (fields, campaignFactoryParameters) {
        var param = campaignFactoryParameters;
        for (var key in fields) {
            var field = fields[key];
            if (field.type === 'collection') {
                if (undefined !== field.fields) {
                    var cfParam = campaignFactoryParameters[key]
                        ? campaignFactoryParameters[key]
                        : undefined;
                    if (undefined === cfParam || cfParam.length === 0) {
                        var init = this.initializeParameters(field.fields, cfParam ? cfParam[0] : {});
                        vue_1.default.set(campaignFactoryParameters, key, [init ? init : {}]);
                    }
                    else {
                        vue_1.default.set(campaignFactoryParameters, key, cfParam);
                    }
                }
                else if (undefined === campaignFactoryParameters[key]) {
                    vue_1.default.set(campaignFactoryParameters, key, [{}]);
                }
            }
            else {
                if (undefined !== field.data && undefined === param[key]) {
                    vue_1.default.set(campaignFactoryParameters, key, field.data);
                }
            }
        }
        return param;
    };
    CampaignFactoryFormComponent.prototype.validateAll = function () {
        return this.$validator.validateAll();
    };
    CampaignFactoryFormComponent.prototype.handleErrorResponse = function (response) {
        var _this = this;
        this.hideProgressBar();
        switch (response.status) {
            case 400:
                if (undefined !== response.data && response.data.errors.length > 0) {
                    response.data.errors.forEach(function (error) {
                        var errorPropertyParts = error.property.split('.');
                        var errorProperty = '';
                        var errorMessage = null !== error.code
                            ? _this.$tc('errors.form.' + error.code)
                            : _this.$tc('errors.form.INVALID_VALUE');
                        if (errorPropertyParts.length > 2) {
                            errorProperty =
                                errorPropertyParts[errorPropertyParts.length - 1] +
                                    '-' +
                                    errorPropertyParts[errorPropertyParts.length - 2];
                        }
                        else {
                            errorProperty = errorPropertyParts[errorPropertyParts.length - 1];
                        }
                        _this.$validator.errors.add({
                            field: errorProperty,
                            msg: errorMessage
                        });
                    });
                    this.globalError = this.$tc('errors.form_validation_generic');
                }
                else {
                    this.globalError = this.$tc('errors.generic');
                }
                break;
            default:
                this.globalError = this.$tc('errors.generic');
                break;
        }
        this.$emit('error', this.globalError);
    };
    CampaignFactoryFormComponent.prototype.addRow = function (fieldKey) {
        this.campaignFactory.parameters[fieldKey].push({});
    };
    CampaignFactoryFormComponent.prototype.removeRow = function (fieldKey, index) {
        var parameterCollection = this.campaignFactory.parameters[fieldKey];
        parameterCollection.splice(index, 1);
    };
    CampaignFactoryFormComponent.prototype.getClassFromAttr = function (field) {
        var _a;
        return ((_a = field === null || field === void 0 ? void 0 : field.attr) === null || _a === void 0 ? void 0 : _a.class) ? field.attr.class : 'pa-4';
    };
    __decorate([
        decorators_1.Container(config_1.default.REPOSITORY_CAMPAIGN_FACTORY),
        __metadata("design:type", campaignFactory_repository_1.CampaignFactoryRepository)
    ], CampaignFactoryFormComponent.prototype, "campaignFactoryRepository", void 0);
    __decorate([
        vue_property_decorator_1.Prop(),
        __metadata("design:type", Object)
    ], CampaignFactoryFormComponent.prototype, "campaignFactory", void 0);
    __decorate([
        vue_property_decorator_1.Prop(),
        __metadata("design:type", String)
    ], CampaignFactoryFormComponent.prototype, "type", void 0);
    __decorate([
        layoutModule.Action('showProgressBar'),
        __metadata("design:type", Function)
    ], CampaignFactoryFormComponent.prototype, "showProgressBar", void 0);
    __decorate([
        layoutModule.Action('hideProgressBar'),
        __metadata("design:type", Function)
    ], CampaignFactoryFormComponent.prototype, "hideProgressBar", void 0);
    CampaignFactoryFormComponent = __decorate([
        vue_class_component_1.default({
            components: {
                CampaignFactoryUrlModalComponent: UrlModal_vue_1.default
            }
        })
    ], CampaignFactoryFormComponent);
    return CampaignFactoryFormComponent;
}(vue_1.default));
exports.default = CampaignFactoryFormComponent;
