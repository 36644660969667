"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var chart_js_1 = require("chart.js");
var vue_property_decorator_1 = require("vue-property-decorator");
var ChartComponent = (function (_super) {
    __extends(ChartComponent, _super);
    function ChartComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ChartComponent.prototype.onChartDataChange = function () {
        this.generateChart();
    };
    ChartComponent.prototype.mounted = function () {
        this.generateChart();
    };
    ChartComponent.prototype.generateChart = function () {
        var canvas = this.$refs.canvas;
        var context = canvas.getContext('2d');
        if (!context) {
            return;
        }
        var generateColor = function () {
            var r = Math.floor(Math.random() * 255);
            var g = Math.floor(Math.random() * 255);
            var b = Math.floor(Math.random() * 255);
            return 'rgb(' + r + ',' + g + ',' + b + ')';
        };
        if (this.data.datasets) {
            this.data.datasets.map(function (dataset) {
                if (!dataset.borderColor) {
                    dataset.borderColor = generateColor();
                }
                return dataset;
            });
        }
        new chart_js_1.Chart(context, {
            type: 'line',
            data: this.data,
            options: {
                legend: {
                    display: false
                },
                tooltips: {
                    mode: 'index',
                    callbacks: {
                        label: function (item, data) {
                            if (undefined === data.datasets ||
                                undefined === item.datasetIndex ||
                                !data.datasets[item.datasetIndex] ||
                                item.yLabel === undefined) {
                                return 'Error';
                            }
                            return (data.datasets[item.datasetIndex].label + ': ' + item.yLabel);
                        }
                    },
                    position: 'nearest',
                    itemSort: function (itemA, itemB) {
                        if (itemA.yLabel === undefined || itemB.yLabel === undefined) {
                            return 0;
                        }
                        return itemA.yLabel > itemB.yLabel ? -1 : 1;
                    }
                }
            }
        });
    };
    __decorate([
        vue_property_decorator_1.Prop(),
        __metadata("design:type", Object)
    ], ChartComponent.prototype, "data", void 0);
    __decorate([
        vue_property_decorator_1.Watch('data', { deep: true }),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], ChartComponent.prototype, "onChartDataChange", null);
    ChartComponent = __decorate([
        vue_class_component_1.default
    ], ChartComponent);
    return ChartComponent;
}(vue_1.default));
exports.default = ChartComponent;
