"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var decorators_1 = require("../../decorator/decorators");
var config_1 = require("../../dic/config");
var collect_repository_1 = require("../../repositories/collect.repository");
var vue_property_decorator_1 = require("vue-property-decorator");
var Type_vue_1 = require("../campaign/Type.vue");
var Flag_vue_1 = require("../campaign/Flag.vue");
var State_vue_1 = require("../campaign/State.vue");
var vuex_class_1 = require("vuex-class");
var layoutModule = vuex_class_1.namespace('layout');
var ConsentListComponent = (function (_super) {
    __extends(ConsentListComponent, _super);
    function ConsentListComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.limit = 4;
        return _this;
    }
    ConsentListComponent.prototype.created = function () {
        this.page = new Number(this.$route.query['page'] || 1).valueOf();
    };
    ConsentListComponent.prototype.mounted = function () {
        this.email = this.$route.params.email;
        this.updateCollect();
    };
    ConsentListComponent.prototype.data = function () {
        return {
            collects: this.collects,
            totalCount: this.totalCount,
            email: this.email,
            page: this.page
        };
    };
    ConsentListComponent.prototype.updateCollect = function () {
        var _this = this;
        this.showProgressBar();
        var callback = function (collectPaginationResult) {
            _this.collects = collectPaginationResult.collects;
            _this.totalCount = collectPaginationResult.totalCount;
            _this.hideProgressBar();
        };
        var paginate = {
            offset: (this.page - 1) * this.limit,
            limit: this.limit
        };
        this.collectRepository
            .getCollectsForEmail(this.email, paginate)
            .then(callback)
            .catch(function () {
            _this.hideProgressBar();
        });
    };
    ConsentListComponent.prototype.countPages = function () {
        return Math.ceil(this.totalCount / this.limit);
    };
    ConsentListComponent.prototype.getSupportUrl = function (collect) {
        return collect.getSupportUrl();
    };
    ConsentListComponent.prototype.onPageChanged = function (page, oldPage) {
        if (oldPage) {
            this.updateCollect();
            this.$router.push({
                path: this.$route.path,
                query: { page: page.toString() }
            });
        }
    };
    __decorate([
        decorators_1.Container(config_1.default.REPOSITORY_COLLECT),
        __metadata("design:type", collect_repository_1.CollectRepository)
    ], ConsentListComponent.prototype, "collectRepository", void 0);
    __decorate([
        layoutModule.Action('showProgressBar'),
        __metadata("design:type", Function)
    ], ConsentListComponent.prototype, "showProgressBar", void 0);
    __decorate([
        layoutModule.Action('hideProgressBar'),
        __metadata("design:type", Function)
    ], ConsentListComponent.prototype, "hideProgressBar", void 0);
    __decorate([
        vue_property_decorator_1.Watch('page'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number, Number]),
        __metadata("design:returntype", void 0)
    ], ConsentListComponent.prototype, "onPageChanged", null);
    ConsentListComponent = __decorate([
        vue_class_component_1.default({
            components: {
                CampaignTypeComponent: Type_vue_1.default,
                CampaignFlagComponent: Flag_vue_1.default,
                CampaignStateComponent: State_vue_1.default
            }
        })
    ], ConsentListComponent);
    return ConsentListComponent;
}(vue_1.default));
exports.default = ConsentListComponent;
