var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "py-0", attrs: { fluid: "" } },
    [
      _c(
        "v-form",
        { attrs: { name: "campaign_factory_test" } },
        [
          _vm.campaignFactory.type === "live"
            ? _c("live-request-form-component", {
                attrs: { "collected-data": _vm.collectedData },
              })
            : _vm.campaignFactory.type === "opposition"
            ? _c("opposition-form-component", {
                attrs: { "collected-data": _vm.collectedData },
              })
            : _vm.campaignFactory.type === "postCollect"
            ? _c("post-collect-form-component", {
                attrs: { "collected-data": _vm.collectedData },
              })
            : _c("collect-form-component", {
                attrs: { "collected-data": _vm.collectedData },
                on: { "update-collected-data": _vm.updateCollectedData },
              }),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              staticClass: "secondary",
              attrs: {
                type: "btn",
                disabled: !_vm.sendButtonEnabled,
                "data-cy": "btn-test",
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$emit("send-test")
                },
              },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("campaign_factory.test.test")) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: { type: "reset" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.reset.apply(null, arguments)
                },
              },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("campaign_factory.test.reset")) +
                  "\n    "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-container",
        { staticClass: "child-flex" },
        [
          _vm.testResponse
            ? _c(
                "v-alert",
                { attrs: { type: "info", dense: true } },
                [
                  _c(
                    "v-simple-table",
                    { attrs: { light: true, dense: true } },
                    [
                      _c("tbody", [
                        _c("tr", [
                          _c("td", [_vm._v("State")]),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "div",
                              {
                                staticClass: "font-weight-bold",
                                class: {
                                  "red--text": _vm.hasError,
                                  "green--text": _vm.isCollectable,
                                  "blue--text": _vm.isNotCollectable,
                                  "orange--text": _vm.didTimeout,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.$tc(
                                        "campaign_factory.test.state." +
                                          _vm.testResponse.state
                                      )
                                    ) +
                                    "\n              "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm.testResponse.errorCode > 0
                          ? _c("tr", [
                              _c("td", [_vm._v("ErrorCode")]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(_vm._s(_vm.testResponse.errorCode)),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.testResponse.errorMessage
                          ? _c("tr", [
                              _c("td", [_vm._v("ErrorMessage")]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("truncate")(
                                      _vm.testResponse.errorMessage,
                                      150,
                                      "..."
                                    )
                                  )
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }