"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_property_decorator_1 = require("vue-property-decorator");
var campaignFactory_model_1 = require("../../../model/campaignFactory.model");
var ComplexModal_vue_1 = require("../ComplexModal.vue");
var ComplexModal_vue_2 = require("../ComplexModal.vue");
var UrlModal_vue_1 = require("../UrlModal.vue");
var qs = require("qs");
var ProfilingModal_vue_1 = require("../ProfilingModal.vue");
var ProfilingModal_vue_2 = require("../ProfilingModal.vue");
var CollectFormComponent = (function (_super) {
    __extends(CollectFormComponent, _super);
    function CollectFormComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CollectFormComponent.prototype.data = function () {
        return {
            countries: [
                'au',
                'be',
                'ca',
                'ch',
                'de',
                'es',
                'fr',
                'gb',
                'it',
                'lu',
                'mc',
                'nl',
                'pl'
            ],
            civilities: ['mr', 'mrs', 'miss']
        };
    };
    CollectFormComponent.prototype.formatProfiling = function (profiling) {
        this.format(profiling, campaignFactory_model_1.CollectedDataEnum.PROFILING);
    };
    CollectFormComponent.prototype.formatComplex = function (complex) {
        this.format(complex, campaignFactory_model_1.CollectedDataEnum.COMPLEX);
    };
    CollectFormComponent.prototype.format = function (data, type) {
        if ('' === data || isNaN(Number(data.substring(0, 1)))) {
            return;
        }
        var dataFormated = {};
        var decoded = decodeURI(data);
        var complexParametersParts = decoded.split('&');
        complexParametersParts.forEach(function (part) {
            var parsedUrl = qs.parse(part);
            for (var fieldKey in parsedUrl) {
                var fieldValues = Object.entries(parsedUrl[fieldKey]);
                fieldValues
                    .filter(function (item) { return item[0] !== 'initial'; })
                    .forEach(function (item) {
                    var _a = __read(item, 2), key = _a[0], value = _a[1];
                    dataFormated[key] = value.replace(/\s+/g, '+');
                });
            }
        });
        this.$emit('update-collected-data', type, qs.stringify(dataFormated, {
            encode: false
        }));
    };
    CollectFormComponent.prototype.updateComplex = function (newComplex) {
        this.$emit('update-collected-data', campaignFactory_model_1.CollectedDataEnum.COMPLEX, newComplex);
    };
    CollectFormComponent.prototype.updateProfiling = function (newProfiling) {
        this.$emit('update-collected-data', campaignFactory_model_1.CollectedDataEnum.PROFILING, newProfiling);
    };
    __decorate([
        vue_property_decorator_1.Prop(),
        __metadata("design:type", Object)
    ], CollectFormComponent.prototype, "collectedData", void 0);
    CollectFormComponent = __decorate([
        vue_class_component_1.default({
            components: {
                ProfilingModal: ProfilingModal_vue_2.default,
                CampaignFactoryUrlModalComponent: UrlModal_vue_1.default,
                ComplexModal: ComplexModal_vue_2.default,
                CampaignFactoryComplexModalComponent: ComplexModal_vue_1.default,
                CampaignFactoryProfilingModalComponent: ProfilingModal_vue_1.default
            }
        })
    ], CollectFormComponent);
    return CollectFormComponent;
}(vue_1.default));
exports.default = CollectFormComponent;
