"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vuex_class_1 = require("vuex-class");
var store_1 = require("../../store/store");
var loginModule = vuex_class_1.namespace('login');
var layoutModule = vuex_class_1.namespace('layout');
var AutoConnectComponent = (function (_super) {
    __extends(AutoConnectComponent, _super);
    function AutoConnectComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AutoConnectComponent.prototype.mounted = function () {
        var _this = this;
        this.showProgressBar();
        this.loginAction({
            userId: this.$route.params['userId'],
            token: this.$route.params['token'],
            impersonatedUserId: this.$route.params['impersonatedUserId'] || undefined
        })
            .then(function () {
            _this.hideProgressBar();
            if (_this.$route.query.target) {
                _this.$router.push(_this.$route.query.target);
            }
            else {
                store_1.store
                    .dispatch('user/loadUser')
                    .then(function (user) {
                    var goTo = user.hasRoleLimitedOpposition()
                        ? 'oppositions'
                        : 'index';
                    _this.$router.push({
                        name: goTo
                    });
                })
                    .catch(function () {
                    _this.$router.push({ name: 'index' });
                });
            }
        })
            .catch(function () {
            _this.hideProgressBar();
            _this.$router.push({ name: 'logout' });
        });
    };
    __decorate([
        loginModule.Action('login'),
        __metadata("design:type", Function)
    ], AutoConnectComponent.prototype, "loginAction", void 0);
    __decorate([
        layoutModule.Action('showProgressBar'),
        __metadata("design:type", Function)
    ], AutoConnectComponent.prototype, "showProgressBar", void 0);
    __decorate([
        layoutModule.Action('hideProgressBar'),
        __metadata("design:type", Function)
    ], AutoConnectComponent.prototype, "hideProgressBar", void 0);
    AutoConnectComponent = __decorate([
        vue_class_component_1.default
    ], AutoConnectComponent);
    return AutoConnectComponent;
}(vue_1.default));
exports.default = AutoConnectComponent;
