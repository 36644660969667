"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var campaignFactory_model_1 = require("../../model/campaignFactory.model");
var vue_property_decorator_1 = require("vue-property-decorator");
var qs = require("qs");
var CampaignFactoryUrlModalComponent = (function (_super) {
    __extends(CampaignFactoryUrlModalComponent, _super);
    function CampaignFactoryUrlModalComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.dialog = false;
        return _this;
    }
    CampaignFactoryUrlModalComponent.prototype.data = function () {
        return {
            dialog: this.dialog,
            urlParameters: this.urlParameters,
            collectedDataCodes: this.collectedDataCodes
        };
    };
    CampaignFactoryUrlModalComponent.prototype.mounted = function () {
        this.collectedDataCodes = campaignFactory_model_1.collectedDataCodesUrl;
    };
    CampaignFactoryUrlModalComponent.prototype.parseUrl = function () {
        this.urlParameters = [];
        if (undefined === this.url) {
            return;
        }
        var urlParametersParts = this.url.split('?');
        if (urlParametersParts.length === 1) {
            return;
        }
        var parsedUrl = qs.parse(urlParametersParts[urlParametersParts.length - 1]);
        for (var fieldKey in parsedUrl) {
            var fieldValue = parsedUrl[fieldKey];
            if (typeof fieldValue === 'object') {
                for (var prop in fieldValue) {
                    this.urlParameters.push({
                        key: fieldKey + "[" + prop + "]",
                        commonValue: null,
                        value: fieldValue[prop]
                    });
                }
                continue;
            }
            var collectedDataCode = fieldValue.replace(/#/g, '');
            var fieldCommonValue = undefined !== fieldValue &&
                -1 !== this.collectedDataCodes.indexOf(collectedDataCode)
                ? collectedDataCode
                : null;
            this.urlParameters.push({
                key: fieldKey,
                commonValue: fieldCommonValue,
                value: fieldValue
            });
        }
    };
    CampaignFactoryUrlModalComponent.prototype.generateUrl = function () {
        var _this = this;
        this.newUrl = '';
        if (undefined !== this.url) {
            var urlParametersParts = this.url.split('?');
            this.newUrl = urlParametersParts[0];
        }
        this.newUrl += '?';
        this.urlParameters.forEach(function (urlParameter) {
            if ('' !== urlParameter.key) {
                _this.newUrl += urlParameter.key + '=' + urlParameter.value + '&';
            }
        }, this);
        this.$emit('url-generated', this.newUrl);
        this.dialog = false;
    };
    CampaignFactoryUrlModalComponent.prototype.addUrlParameter = function () {
        this.urlParameters.push({
            key: '',
            commonValue: null,
            value: ''
        });
    };
    CampaignFactoryUrlModalComponent.prototype.removeUrlParameter = function (urlParameterIndex) {
        this.urlParameters.splice(urlParameterIndex, 1);
    };
    CampaignFactoryUrlModalComponent.prototype.updateValueInput = function (urlParameterIndex) {
        if (undefined !== this.urlParameters[urlParameterIndex].commonValue) {
            this.urlParameters[urlParameterIndex].value =
                '#' + this.urlParameters[urlParameterIndex].commonValue + '#';
        }
        else {
            this.urlParameters[urlParameterIndex].value = '';
        }
    };
    __decorate([
        vue_property_decorator_1.Prop(),
        __metadata("design:type", String)
    ], CampaignFactoryUrlModalComponent.prototype, "url", void 0);
    CampaignFactoryUrlModalComponent = __decorate([
        vue_class_component_1.default
    ], CampaignFactoryUrlModalComponent);
    return CampaignFactoryUrlModalComponent;
}(vue_1.default));
exports.default = CampaignFactoryUrlModalComponent;
