"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var config_1 = require("../../dic/config");
var decorators_1 = require("../../decorator/decorators");
var vue_property_decorator_1 = require("vue-property-decorator");
var log_repository_1 = require("../../repositories/log.repository");
var log_model_1 = require("../../model/log.model");
var Filter_vue_1 = require("../filter/Filter.vue");
var vuex_class_1 = require("vuex-class");
var layoutModule = vuex_class_1.namespace('layout');
var ListComponent = (function (_super) {
    __extends(ListComponent, _super);
    function ListComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.limit = 10;
        _this.dateStartDialog = false;
        _this.dateEndDialog = false;
        return _this;
    }
    ListComponent.prototype.created = function () {
        this.filter = new log_repository_1.LogFilter();
        this.noDataText = this.$tc('history.no_history');
        this.page = new Number(this.$route.query['page'] || 1).valueOf();
    };
    ListComponent.prototype.mounted = function () {
        this.updateLogs();
    };
    ListComponent.prototype.data = function () {
        return {
            logs: this.logs,
            totalCount: this.totalCount,
            filter: this.filter,
            noDataText: this.noDataText,
            page: this.page
        };
    };
    ListComponent.prototype.resetFilter = function () {
        this.page = 1;
        this.updateLogs();
    };
    ListComponent.prototype.searchFilter = function () {
        this.page = 1;
        this.updateLogs();
    };
    ListComponent.prototype.updateLogs = function () {
        var _this = this;
        this.showProgressBar();
        var callback = function (result) {
            _this.logs = result.logs;
            _this.totalCount = result.totalCount;
            _this.hideProgressBar();
        };
        var paginate = {
            offset: (this.page - 1) * this.limit,
            limit: this.limit
        };
        this.logRepository
            .getLogs(paginate, this.filter)
            .then(callback)
            .catch(function () {
            _this.hideProgressBar();
            _this.noDataText = _this.$tc('errors.generic');
        });
    };
    ListComponent.prototype.countPages = function () {
        return Math.ceil(this.totalCount / this.limit);
    };
    ListComponent.prototype.setDateStart = function (date) {
        this.filter.dateStart = new Date(date);
        this.dateStartDialog = false;
    };
    ListComponent.prototype.setDateEnd = function (date) {
        this.filter.dateEnd = new Date(date);
        this.dateEndDialog = false;
    };
    ListComponent.prototype.resetDateStart = function () {
        this.filter.dateStart = null;
    };
    ListComponent.prototype.resetDateEnd = function () {
        this.filter.dateEnd = null;
    };
    ListComponent.prototype.getAction = function (logType, logExtraData) {
        var action;
        switch (logType) {
            case log_model_1.LogTypeEnum.LOGIN:
                action = this.$tc('history.log.action.login');
                break;
            case log_model_1.LogTypeEnum.SEARCH_CONSENT:
                action = this.$tc('history.log.action.search_consent', 1, {
                    email: logExtraData.email
                });
                break;
            case log_model_1.LogTypeEnum.EXPORT_LEAD:
                action = this.$tc('history.log.action.export_lead', 1, {
                    campaignName: logExtraData.campaignName,
                    dateStart: logExtraData.dateStart
                        ? this.$d(logExtraData.dateStart, 'short')
                        : this.$tc('history.log.extra_data.date_missing'),
                    dateEnd: logExtraData.dateEnd
                        ? this.$d(logExtraData.dateEnd, 'short')
                        : this.$tc('history.log.extra_data.date_missing')
                });
                break;
            default:
                action = this.$tc('history.log.action.missing_data');
                break;
        }
        return action;
    };
    Object.defineProperty(ListComponent.prototype, "headers", {
        get: function () {
            return [
                {
                    text: this.$tc('history.headers.date'),
                    value: 'date',
                    sortable: false
                },
                {
                    text: this.$tc('history.headers.name'),
                    value: 'name',
                    sortable: false
                },
                {
                    text: this.$tc('history.headers.email'),
                    value: 'email',
                    sortable: false
                },
                {
                    text: this.$tc('history.headers.action'),
                    value: 'action',
                    sortable: false
                }
            ];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ListComponent.prototype, "types", {
        get: function () {
            return [
                {
                    value: log_model_1.LogTypeEnum.LOGIN,
                    text: this.$tc('history.type.login')
                },
                {
                    value: log_model_1.LogTypeEnum.SEARCH_CONSENT,
                    text: this.$tc('history.type.search_consent')
                },
                {
                    value: log_model_1.LogTypeEnum.EXPORT_LEAD,
                    text: this.$tc('history.type.export_lead')
                }
            ];
        },
        enumerable: false,
        configurable: true
    });
    ListComponent.prototype.onPageChanged = function (page, oldPage) {
        if (oldPage) {
            this.updateLogs();
            this.$router.push({
                path: this.$route.path,
                query: { page: page.toString() }
            });
        }
    };
    __decorate([
        decorators_1.Container(config_1.default.REPOSITORY_LOG),
        __metadata("design:type", log_repository_1.LogRepository)
    ], ListComponent.prototype, "logRepository", void 0);
    __decorate([
        layoutModule.Action('showProgressBar'),
        __metadata("design:type", Function)
    ], ListComponent.prototype, "showProgressBar", void 0);
    __decorate([
        layoutModule.Action('hideProgressBar'),
        __metadata("design:type", Function)
    ], ListComponent.prototype, "hideProgressBar", void 0);
    __decorate([
        layoutModule.Getter('isProgressBarDisplay'),
        __metadata("design:type", Boolean)
    ], ListComponent.prototype, "progressBar", void 0);
    __decorate([
        vue_property_decorator_1.Watch('page'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number, Number]),
        __metadata("design:returntype", void 0)
    ], ListComponent.prototype, "onPageChanged", null);
    ListComponent = __decorate([
        vue_class_component_1.default({ components: { FilterComponent: Filter_vue_1.default } })
    ], ListComponent);
    return ListComponent;
}(vue_1.default));
exports.default = ListComponent;
