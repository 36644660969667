"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_property_decorator_1 = require("vue-property-decorator");
var campaign_model_1 = require("../../model/campaign.model");
var moment = require("moment");
var decorators_1 = require("../../decorator/decorators");
var config_1 = require("../../dic/config");
var download_leads_service_1 = require("../../services/collect/download_leads.service");
var vuex_class_1 = require("vuex-class");
var fileSaver = require("file-saver");
var layoutModule = vuex_class_1.namespace('layout');
var ExportComponent = (function (_super) {
    __extends(ExportComponent, _super);
    function ExportComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.dateStartDialog = false;
        _this.dateEndDialog = false;
        _this.inProgress = false;
        return _this;
    }
    ExportComponent.prototype.data = function () {
        return {
            globalError: this.globalError
        };
    };
    ExportComponent.prototype.setDateStart = function (date) {
        this.model.dateStart = new Date(date);
        var maxDateEnd = moment(this.model.dateStart).add(2, 'M');
        if (moment(this.model.dateEnd).isAfter(maxDateEnd)) {
            this.model.dateEnd = maxDateEnd.toDate();
        }
        if (moment(this.model.dateEnd).isBefore(moment(this.model.dateStart))) {
            this.model.dateEnd = moment(this.model.dateStart).add(1, 'd').toDate();
        }
        this.dateStartDialog = false;
    };
    ExportComponent.prototype.setDateEnd = function (date) {
        this.model.dateEnd = new Date(date);
        this.dateEndDialog = false;
    };
    ExportComponent.prototype.download = function () {
        var _this = this;
        this.$validator.validateAll().then(function (result) {
            if (result === true) {
                _this.showLoadingItems();
                _this.downloadLeadsService
                    .downloadLeads(_this.campaign.uuid, _this.model)
                    .then(function (blob) {
                    _this.globalError = undefined;
                    var filename = 'file_' +
                        _this.campaign.uuid +
                        '_' +
                        moment(_this.model.dateStart).format('YYYYMMDD') +
                        '_' +
                        moment(_this.model.dateEnd).format('YYYYMMDD') +
                        '.csv';
                    if (_this.environment !== 'test') {
                        fileSaver.saveAs(blob, filename);
                    }
                    _this.hideLoadingItems();
                })
                    .catch(function (response) {
                    switch (response.status) {
                        case 400:
                            (response.data || { errors: [] }).errors.forEach(function (error) {
                                _this.$validator.errors.add({
                                    field: error.property,
                                    msg: _this.$tc('errors.form.' + error.code)
                                });
                            });
                            break;
                        case 404:
                            _this.globalError = _this.$tc('errors.no_leads');
                            break;
                        default:
                            _this.globalError = _this.$tc('errors.generic');
                            break;
                    }
                    _this.hideLoadingItems();
                });
            }
        });
    };
    ExportComponent.prototype.cancel = function () {
        this.state.show = false;
        this.$validator.errors.clear();
    };
    ExportComponent.prototype.showLoadingItems = function () {
        this.showProgressBar();
        this.inProgress = true;
    };
    ExportComponent.prototype.hideLoadingItems = function () {
        this.hideProgressBar();
        this.inProgress = false;
    };
    ExportComponent.prototype.closeDialog = function () {
        if (this.dialogMode) {
            this.state.show = false;
        }
    };
    Object.defineProperty(ExportComponent.prototype, "maxDateEnd", {
        get: function () {
            var maxDate = moment(this.model.dateStart).add(2, 'M');
            var yesterday = moment().subtract(1, 'days');
            if (maxDate.isAfter(yesterday)) {
                return yesterday.format('YYYY-MM-DD');
            }
            return maxDate.format('YYYY-MM-DD');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ExportComponent.prototype, "maxDateStart", {
        get: function () {
            return moment().subtract(1, 'days').format('YYYY-MM-DD');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ExportComponent.prototype, "minDateEnd", {
        get: function () {
            return moment(this.model.dateStart).format('YYYY-MM-DD');
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        decorators_1.Container(config_1.default.SERVICE_DOWNLOAD_LEADS),
        __metadata("design:type", download_leads_service_1.DownloadLeadsService)
    ], ExportComponent.prototype, "downloadLeadsService", void 0);
    __decorate([
        decorators_1.Container('environment'),
        __metadata("design:type", String)
    ], ExportComponent.prototype, "environment", void 0);
    __decorate([
        layoutModule.Action('showProgressBar'),
        __metadata("design:type", Function)
    ], ExportComponent.prototype, "showProgressBar", void 0);
    __decorate([
        layoutModule.Action('hideProgressBar'),
        __metadata("design:type", Function)
    ], ExportComponent.prototype, "hideProgressBar", void 0);
    __decorate([
        vue_property_decorator_1.Prop({
            default: function () {
                return { show: false };
            }
        }),
        __metadata("design:type", Object)
    ], ExportComponent.prototype, "state", void 0);
    __decorate([
        vue_property_decorator_1.Prop({
            default: function () {
                var yesterday = moment().subtract(1, 'days');
                return {
                    dateStart: yesterday.toDate(),
                    dateEnd: yesterday.toDate(),
                    state: false
                };
            }
        }),
        __metadata("design:type", Object)
    ], ExportComponent.prototype, "model", void 0);
    __decorate([
        vue_property_decorator_1.Prop(campaign_model_1.Campaign),
        __metadata("design:type", campaign_model_1.Campaign)
    ], ExportComponent.prototype, "campaign", void 0);
    __decorate([
        vue_property_decorator_1.Prop({ default: true }),
        __metadata("design:type", Boolean)
    ], ExportComponent.prototype, "dialogMode", void 0);
    ExportComponent = __decorate([
        vue_class_component_1.default
    ], ExportComponent);
    return ExportComponent;
}(vue_1.default));
exports.default = ExportComponent;
