"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Collect = void 0;
var campaign_model_1 = require("./campaign.model");
var Collect = (function () {
    function Collect(data) {
        this.createdAt = new Date(data.createdAt);
        this.ipAddress = data.ipAddress;
        this.campaign = new campaign_model_1.Campaign(data.campaign);
        this.referer = data.referer;
        this.consents = data.consents || [];
    }
    Collect.prototype.getSupportUrl = function () {
        if (this.referer) {
            return this.referer;
        }
        var consentsWithUrl = this.consents.filter(function (consent) { return consent.url; });
        return consentsWithUrl.length > 0 ? consentsWithUrl[0].url : '';
    };
    return Collect;
}());
exports.Collect = Collect;
