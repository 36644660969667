var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-col",
    { attrs: { cols: "12", sm: "6" } },
    [
      _c(
        "v-card",
        { staticClass: "d-flex flex-column", attrs: { height: "100%" } },
        [
          _c(
            "v-card-title",
            { staticClass: "pt-3", attrs: { "primary-title": "" } },
            [
              _vm.campaign.ad
                ? _c("img", {
                    staticClass: "mr-2",
                    staticStyle: {
                      border: "solid 1px #eeeeee",
                      "max-height": "50px",
                    },
                    attrs: { src: _vm.campaign.ad.picture },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "mr-1", attrs: { left: "" } },
                [
                  _c("campaign-flag-component", {
                    attrs: { campaign: _vm.campaign },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "float-left" },
                [
                  _c("campaign-state-component", {
                    attrs: { state: _vm.campaign.state, small: "" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "hidden-md-and-down" }, [
                _c("h4", { staticClass: "text-body-2 mb-0 ml-2" }, [
                  _vm._v(_vm._s(_vm.campaign.name)),
                ]),
              ]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c("campaign-type-component", {
                attrs: { small: "", campaign: _vm.campaign },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "hidden-lg-and-up" }, [
                _c("h4", { staticClass: "text-body-2 mt-2" }, [
                  _vm._v(_vm._s(_vm.campaign.name)),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { staticClass: "fill-height" },
                [
                  _vm.campaign.filters.length > 0
                    ? [
                        _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
                          _c("span", { staticClass: "text-body-2 filters" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("campaigns.filters.title")) +
                                "\n            "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
                          _c("span", { staticClass: "filters" }, [
                            _c(
                              "ul",
                              _vm._l(
                                _vm.campaign.filters,
                                function (filter, index) {
                                  return _c(
                                    "li",
                                    { key: filter.type + "-" + index },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t(
                                              "campaigns.filters.items." +
                                                filter.type
                                            )
                                          ) +
                                          "\n                  " +
                                          _vm._s(filter.operatorSymbol) +
                                          "\n                  "
                                      ),
                                      filter.isArrayValue()
                                        ? [
                                            _vm._v(
                                              _vm._s(filter.value.join(","))
                                            ),
                                          ]
                                        : [_vm._v(_vm._s(filter.value))],
                                    ],
                                    2
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                        ]),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.campaign.isTypeAffiliate() &&
                  _vm.collectedData.length > 0
                    ? [
                        _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
                          _c("span", { staticClass: "text-body-2" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t("campaigns.collectedData.title")
                                ) +
                                "\n            "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
                          _c(
                            "span",
                            { staticStyle: { "max-height": "200px" } },
                            [_vm._v(_vm._s(_vm.collectedData.join(", ")))]
                          ),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            { staticClass: "pb-3 px-3 mt-auto" },
            [
              !_vm.campaign.isStateCreating()
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        to: {
                          name: "campaign_statistics",
                          params: { campaignUuid: _vm.campaign.uuid },
                        },
                        color: "action white--text ml-0 mr-2",
                        small: "",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("campaigns.actions.see_stats")))]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.campaign.isStateCreating() &&
              !_vm.campaign.isTypeAffiliate() &&
              _vm.user &&
              (_vm.user.hasRoleDPO() || _vm.user.hasRoleAdmin())
                ? _c("export-leads-component", {
                    attrs: { campaign: _vm.campaign },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.campaign.isTypeAffiliate() && !_vm.campaign.isStateFinished()
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        to: {
                          name: "campaign_conversions_logged",
                          params: { campaignUuid: _vm.campaign.uuid },
                        },
                        color: "action white--text ml-0 mr-2",
                        small: "",
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("campaigns.actions.tagtracking")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.campaign.isStateFinished() &&
              (_vm.campaign.isTypeCoregistration() ||
                _vm.campaign.isTypeComplexCoregistration() ||
                _vm.campaign.isTypeSponsoring() ||
                _vm.campaign.isTypeSponsoringCheckbox() ||
                _vm.campaign.isTypeSponsoringComplex())
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        to: {
                          name: "campaign_bat",
                          params: { campaignUuid: _vm.campaign.uuid },
                        },
                        color: "action white--text ml-0 mr-2",
                        small: "",
                      },
                    },
                    [_vm._v("\n        bat\n      ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }