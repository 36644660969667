"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var user_repository_1 = require("../../repositories/user.repository");
var decorators_1 = require("../../decorator/decorators");
var config_1 = require("../../dic/config");
var vuex_class_1 = require("vuex-class");
var layoutModule = vuex_class_1.namespace('layout');
var ResetPasswordComponent = (function (_super) {
    __extends(ResetPasswordComponent, _super);
    function ResetPasswordComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.inProgress = false;
        _this.passwordVisibility = false;
        _this.confirmPasswordVisibility = false;
        return _this;
    }
    ResetPasswordComponent.prototype.created = function () {
        this.model = { password: '', confirmationPassword: '' };
    };
    ResetPasswordComponent.prototype.data = function () {
        return {
            model: this.model,
            globalError: this.globalError
        };
    };
    ResetPasswordComponent.prototype.setPassword = function () {
        var _this = this;
        this.$validator.validateAll().then(function (result) {
            if (result === true) {
                _this.showLoadingItems();
                var callbackSuccess = function () {
                    _this.hideLoadingItems();
                    _this.$router.push({ name: 'logout' });
                };
                var callbackFail = function (response) {
                    _this.hideLoadingItems();
                    switch (response.status) {
                        case 400:
                            (response.data || { errors: [] }).errors.forEach(function (error) {
                                _this.$validator.errors.add({
                                    field: error.property,
                                    msg: _this.$tc('errors.form.' + error.code)
                                });
                            });
                            break;
                        case 401:
                            _this.globalError = _this.$tc('errors.expired_token');
                            break;
                        default:
                            _this.globalError = _this.$tc('errors.generic');
                            break;
                    }
                };
                _this.userRepository
                    .resetPassword(_this.model.password, _this.$route.params['token'])
                    .then(callbackSuccess)
                    .catch(callbackFail);
            }
        });
    };
    ResetPasswordComponent.prototype.showLoadingItems = function () {
        this.showProgressBar();
        this.inProgress = true;
    };
    ResetPasswordComponent.prototype.hideLoadingItems = function () {
        this.hideProgressBar();
        this.inProgress = false;
    };
    __decorate([
        decorators_1.Container(config_1.default.REPOSITORY_USER),
        __metadata("design:type", user_repository_1.UserRepository)
    ], ResetPasswordComponent.prototype, "userRepository", void 0);
    __decorate([
        layoutModule.Action('showProgressBar'),
        __metadata("design:type", Function)
    ], ResetPasswordComponent.prototype, "showProgressBar", void 0);
    __decorate([
        layoutModule.Action('hideProgressBar'),
        __metadata("design:type", Function)
    ], ResetPasswordComponent.prototype, "hideProgressBar", void 0);
    ResetPasswordComponent = __decorate([
        vue_class_component_1.default
    ], ResetPasswordComponent);
    return ResetPasswordComponent;
}(vue_1.default));
exports.default = ResetPasswordComponent;
