var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-progress-linear", {
    staticStyle: { position: "absolute", top: "0", left: "0" },
    attrs: { indeterminate: true, color: "pink darken-1", height: "4" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }