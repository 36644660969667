"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var inversify_1 = require("inversify");
var client_service_1 = require("../services/client.service");
var config_1 = require("./config");
var campaign_repository_1 = require("../repositories/campaign.repository");
var user_repository_1 = require("../repositories/user.repository");
var locale_service_1 = require("../services/i18n/locale.service");
var i18n_factory_1 = require("../services/i18n/i18n.factory");
var login_service_1 = require("../services/login/login.service");
var access_token_storage_1 = require("../services/login/access_token.storage");
var download_leads_service_1 = require("../services/collect/download_leads.service");
var campaign_statistic_repository_1 = require("../repositories/campaign_statistic.repository");
var collect_repository_1 = require("../repositories/collect.repository");
var opposition_repository_1 = require("../repositories/opposition.repository");
var log_repository_1 = require("../repositories/log.repository");
var advertiser_repository_1 = require("../repositories/advertiser.repository");
var tagtracking_repository_1 = require("../repositories/tagtracking.repository");
var campaignFactory_repository_1 = require("../repositories/campaignFactory.repository");
var container = new inversify_1.Container();
container
    .bind(config_1.default.SERVICE_CLIENT)
    .to(client_service_1.ClientService);
container
    .bind(config_1.default.SERVICE_LOCALE)
    .to(locale_service_1.LocaleService);
container.bind(config_1.default.SERVICE_LOGIN).to(login_service_1.LoginService);
container
    .bind(config_1.default.SERVICE_DOWNLOAD_LEADS)
    .to(download_leads_service_1.DownloadLeadsService);
container
    .bind(config_1.default.REPOSITORY_CAMPAIGN)
    .to(campaign_repository_1.CampaignRepository);
container
    .bind(config_1.default.REPOSITORY_CONVERSION)
    .to(tagtracking_repository_1.TagTrackingRepository);
container
    .bind(config_1.default.REPOSITORY_CAMPAIGN_STATISTIC)
    .to(campaign_statistic_repository_1.CampaignStatisticRepository);
container
    .bind(config_1.default.REPOSITORY_USER)
    .to(user_repository_1.UserRepository);
container
    .bind(config_1.default.REPOSITORY_COLLECT)
    .to(collect_repository_1.CollectRepository);
container
    .bind(config_1.default.REPOSITORY_OPPOSITION)
    .to(opposition_repository_1.OppositionRepository);
container
    .bind(config_1.default.REPOSITORY_CAMPAIGN_FACTORY)
    .to(campaignFactory_repository_1.CampaignFactoryRepository);
container
    .bind(config_1.default.REPOSITORY_LOG)
    .to(log_repository_1.LogRepository);
container
    .bind(config_1.default.REPOSITORY_ADVERTISER)
    .to(advertiser_repository_1.AdvertiserRepository);
container.bind('api.base_url').toConstantValue(__API_BASE_URL__);
container.bind('environment').toConstantValue(__ENVIRONMENT__);
container.bind(config_1.default.I18N_FACTORY).to(i18n_factory_1.I18nFactory);
container
    .bind(config_1.default.LOCAL_STORAGE)
    .toConstantValue(window.localStorage);
container
    .bind(config_1.default.ACCESS_TOKEN_STORAGE)
    .to(access_token_storage_1.AccessTokenStorage);
exports.default = container;
