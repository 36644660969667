var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "secondary",
          attrs: { type: "submit", disabled: !_vm.sendButtonEnabled },
          nativeOn: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.submitForm($event)
            },
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("actions.valid")) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          attrs: {
            type: "btn",
            disabled: !_vm.sendButtonEnabled,
            "data-cy": "btn-copy",
          },
          on: {
            click: function ($event) {
              return _vm.copyJson()
            },
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("actions.copy_json")) + "\n  ")]
      ),
      _vm._v(" "),
      _vm.displayJsonFactory
        ? _c("v-text-field", {
            attrs: { id: "json-factory", readonly: true },
            model: {
              value: _vm.jsonFactory,
              callback: function ($$v) {
                _vm.jsonFactory = $$v
              },
              expression: "jsonFactory",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }