"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CampaignFactoryRepository = void 0;
var inversify_1 = require("inversify");
var config_1 = require("../dic/config");
var client_service_1 = require("../services/client.service");
var CampaignFactoryRepository = (function () {
    function CampaignFactoryRepository(client) {
        this.client = client;
    }
    CampaignFactoryRepository.prototype.getCampaignFactoryFormDefinitions = function (campaignFactoryType) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.client.get({
                            endpoint: "/campaigns/factories/type/" + campaignFactoryType + "/parameters",
                            credentials: true
                        })];
                    case 1:
                        response = _a.sent();
                        return [2, response.data.sort(function (a, b) {
                                return a.formType.localeCompare(b.formType);
                            })];
                }
            });
        });
    };
    CampaignFactoryRepository.prototype.get = function (campaignUuid, campaignFactoryType, campaignFactoryId) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.client.get({
                            endpoint: "/campaign/" + campaignUuid + "/factory/" + campaignFactoryId + "/type/" + campaignFactoryType,
                            credentials: true
                        })];
                    case 1:
                        response = _a.sent();
                        return [2, response.data];
                }
            });
        });
    };
    CampaignFactoryRepository.prototype.create = function (campaignFactory, campaignUuid) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.client.post({
                            endpoint: "/campaign/" + campaignUuid + "/factory",
                            credentials: true,
                            data: campaignFactory
                        })];
                    case 1:
                        response = _a.sent();
                        return [2, response.data];
                }
            });
        });
    };
    CampaignFactoryRepository.prototype.testCampaignFactory = function (campaignFactory, collectedData, campaignUid) {
        return __awaiter(this, void 0, void 0, function () {
            var data, key, value, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        data = {
                            campaignFactory: {
                                type: campaignFactory.type,
                                className: campaignFactory.className,
                                parameters: campaignFactory.parameters
                            },
                            collectedData: [],
                            ip: collectedData.ip,
                            cookieUid: collectedData.cookieUid,
                            campaignUid: campaignUid
                        };
                        for (key in collectedData) {
                            if (['ip', 'cookieUid'].indexOf(key) === -1) {
                                value = collectedData[key];
                                if (value) {
                                    data.collectedData.push({ type: key, value: value });
                                }
                            }
                        }
                        return [4, this.client.post({
                                endpoint: "/campaign/factory/type/" + campaignFactory.type + "/test",
                                credentials: true,
                                data: data
                            })];
                    case 1:
                        response = _a.sent();
                        return [2, response.data];
                }
            });
        });
    };
    CampaignFactoryRepository.prototype.testExportCampaignFactory = function (campaignFactory, collectedData, campaignUid) {
        return __awaiter(this, void 0, void 0, function () {
            var data, key, value, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        data = {
                            campaignFactory: {
                                type: campaignFactory.type,
                                className: campaignFactory.className,
                                parameters: campaignFactory.parameters
                            },
                            collectedData: [],
                            ip: collectedData.ip,
                            cookieUid: collectedData.cookieUid,
                            campaignUid: campaignUid
                        };
                        for (key in collectedData) {
                            if (['ip', 'cookieUid'].indexOf(key) === -1) {
                                value = collectedData[key];
                                if (value) {
                                    data.collectedData.push({ type: key, value: value });
                                }
                            }
                        }
                        return [4, this.client.post({
                                endpoint: "/campaign/factory/type/" + campaignFactory.type + "/test",
                                credentials: true,
                                data: data
                            })];
                    case 1:
                        response = _a.sent();
                        return [2, new Blob([response.data], {
                                type: 'text/csv;charset=utf-8'
                            })];
                }
            });
        });
    };
    CampaignFactoryRepository.prototype.update = function (campaignFactory, campaignUuid) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.client.put({
                            endpoint: "/campaign/" + campaignUuid + "/factory/" + campaignFactory.id,
                            credentials: true,
                            data: {
                                type: campaignFactory.type,
                                className: campaignFactory.className,
                                parameters: campaignFactory.parameters
                            }
                        })];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    CampaignFactoryRepository.prototype.getDefaultData = function (campaignUuid, type) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.client.get({
                            endpoint: "/campaign/" + campaignUuid + "/factory/type/" + type + "/data",
                            credentials: true
                        })];
                    case 1:
                        response = _a.sent();
                        return [2, response.data];
                }
            });
        });
    };
    CampaignFactoryRepository = __decorate([
        inversify_1.injectable(),
        __param(0, inversify_1.inject(config_1.default.SERVICE_CLIENT)),
        __metadata("design:paramtypes", [client_service_1.ClientService])
    ], CampaignFactoryRepository);
    return CampaignFactoryRepository;
}());
exports.CampaignFactoryRepository = CampaignFactoryRepository;
