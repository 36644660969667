var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c("v-spacer"),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: "8" } },
        [
          _vm.ad && _vm.hasText
            ? _c("SponsoringCheckboxModalComponent", {
                attrs: { ad: _vm.ad, market: _vm.campaign.market },
              })
            : _c("v-alert", { attrs: { dense: "", type: "error" } }, [
                _vm._v(
                  "\n      Unable to display preview ( missing ad or text ? )\n    "
                ),
              ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-spacer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }