"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_property_decorator_1 = require("vue-property-decorator");
var qs = require("qs");
var CampaignFactoryComplexModalComponent = (function (_super) {
    __extends(CampaignFactoryComplexModalComponent, _super);
    function CampaignFactoryComplexModalComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.dialog = false;
        return _this;
    }
    CampaignFactoryComplexModalComponent.prototype.data = function () {
        return {
            dialog: this.dialog,
            complexParameters: []
        };
    };
    CampaignFactoryComplexModalComponent.prototype.parseComplex = function () {
        var _this = this;
        this.dialog = true;
        this.complexParameters = [];
        if (!this.complex || '' === this.complex) {
            return;
        }
        var complexParametersParts = this.complex.split('&');
        complexParametersParts.forEach(function (part) {
            var parsedUrl = qs.parse(part, {
                plainObjects: true,
                allowDots: true,
                allowPrototypes: true
            });
            for (var fieldKey in parsedUrl) {
                var fieldValue = parsedUrl[fieldKey];
                _this.complexParameters.push({
                    key: fieldKey,
                    value: fieldValue
                });
            }
        });
    };
    CampaignFactoryComplexModalComponent.prototype.generateComplex = function () {
        var _this = this;
        this.newUrl = '';
        this.complexParameters.forEach(function (complexParameter, index) {
            if ('' !== complexParameter.key) {
                _this.newUrl += complexParameter.key + "=" + complexParameter.value.replace(/\s+/g, '+');
                if (index < _this.complexParameters.length - 1) {
                    _this.newUrl += '&';
                }
            }
        }, this);
        this.$emit('complex-generated', this.newUrl);
        this.dialog = false;
    };
    CampaignFactoryComplexModalComponent.prototype.addComplexParameter = function () {
        this.complexParameters.push({
            key: '',
            value: ''
        });
    };
    CampaignFactoryComplexModalComponent.prototype.removeComplexParameter = function (complexParameterIndex) {
        this.complexParameters.splice(complexParameterIndex, 1);
    };
    __decorate([
        vue_property_decorator_1.Prop(),
        __metadata("design:type", String)
    ], CampaignFactoryComplexModalComponent.prototype, "complex", void 0);
    CampaignFactoryComplexModalComponent = __decorate([
        vue_class_component_1.default
    ], CampaignFactoryComplexModalComponent);
    return CampaignFactoryComplexModalComponent;
}(vue_1.default));
exports.default = CampaignFactoryComplexModalComponent;
