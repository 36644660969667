"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var campaign_model_1 = require("../../model/campaign.model");
var decorators_1 = require("../../decorator/decorators");
var config_1 = require("../../dic/config");
var campaign_repository_1 = require("../../repositories/campaign.repository");
var vue_property_decorator_1 = require("vue-property-decorator");
var SponsoringCheckboxModal_vue_1 = require("./SponsoringCheckboxModal.vue");
var SponsoringCheckboxBatComponent = (function (_super) {
    __extends(SponsoringCheckboxBatComponent, _super);
    function SponsoringCheckboxBatComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SponsoringCheckboxBatComponent.prototype.data = function () {
        return {
            hasText: false,
            ad: null
        };
    };
    SponsoringCheckboxBatComponent.prototype.mounted = function () {
        this.getAd();
    };
    SponsoringCheckboxBatComponent.prototype.getAd = function () {
        var _this = this;
        this.campaignRepository.getAd(this.campaign.uuid, this.token).then(function (ad) {
            _this.hasText = !!(ad.text && '' !== ad.text);
            _this.ad = ad;
        });
    };
    __decorate([
        decorators_1.Container(config_1.default.REPOSITORY_CAMPAIGN),
        __metadata("design:type", campaign_repository_1.CampaignRepository)
    ], SponsoringCheckboxBatComponent.prototype, "campaignRepository", void 0);
    __decorate([
        vue_property_decorator_1.Prop(),
        __metadata("design:type", campaign_model_1.Campaign)
    ], SponsoringCheckboxBatComponent.prototype, "campaign", void 0);
    __decorate([
        vue_property_decorator_1.Prop(),
        __metadata("design:type", Object)
    ], SponsoringCheckboxBatComponent.prototype, "token", void 0);
    SponsoringCheckboxBatComponent = __decorate([
        vue_class_component_1.default({ components: { SponsoringCheckboxModalComponent: SponsoringCheckboxModal_vue_1.default } })
    ], SponsoringCheckboxBatComponent);
    return SponsoringCheckboxBatComponent;
}(vue_1.default));
exports.default = SponsoringCheckboxBatComponent;
