"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_property_decorator_1 = require("vue-property-decorator");
var campaign_model_1 = require("../../model/campaign.model");
var TypeComponent = (function (_super) {
    __extends(TypeComponent, _super);
    function TypeComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(TypeComponent.prototype, "chipColor", {
        get: function () {
            var color = '';
            switch (true) {
                case this.campaign.isTypeCoregistration():
                    color = 'red darken-1';
                    break;
                case this.campaign.isTypeSponsoring():
                    color = 'light-green darken-1';
                    break;
                case this.campaign.isTypeAffiliate():
                    color = 'deep-purple';
                    break;
                case this.campaign.isTypeComplexCoregistration():
                    color = 'deep-orange';
                    break;
                case this.campaign.isTypeFacebook():
                    color = 'blue darken-3';
                    break;
                case this.campaign.isTypeRetargetingEmail():
                    color = 'grey';
                    break;
                case this.campaign.isTypeCallCenter():
                    color = 'teal darken-1';
                    break;
                case this.campaign.isTypeSponsoringCheckbox():
                    color = 'light-green darken-4';
                    break;
                case this.campaign.isTypeSponsoringComplex():
                    color = 'blue-grey';
                    break;
            }
            return color;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        vue_property_decorator_1.Prop(),
        __metadata("design:type", campaign_model_1.Campaign)
    ], TypeComponent.prototype, "campaign", void 0);
    __decorate([
        vue_property_decorator_1.Prop(),
        __metadata("design:type", Boolean)
    ], TypeComponent.prototype, "small", void 0);
    TypeComponent = __decorate([
        vue_class_component_1.default({
            filters: {
                capitalize: function (value) {
                    return value.toLocaleUpperCase();
                }
            }
        })
    ], TypeComponent);
    return TypeComponent;
}(vue_1.default));
exports.default = TypeComponent;
