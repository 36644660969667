var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "800px", "hide-overlay": "" },
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.$router.go(-1)
        },
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "primary white--text" }, [
            _c("span", { staticClass: "text-h5" }, [
              _vm._v(_vm._s(_vm.$t("consents.modal.title"))),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "v-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.search.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "v-card-text",
                [
                  _c("p", { staticClass: "text-body-2 mb-0" }, [
                    _vm._v(_vm._s(_vm.$t("consents.modal.headline"))),
                  ]),
                  _vm._v(" "),
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|email",
                        expression: "'required|email'",
                      },
                    ],
                    attrs: {
                      placeholder: "example@domain.com",
                      required: "",
                      name: "email",
                      "error-messages": _vm.errors.has("email")
                        ? [_vm.errors.first("email")]
                        : [],
                    },
                    model: {
                      value: _vm.model.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.model, "email", $$v)
                      },
                      expression: "model.email",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.$router.go(-1)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("actions.cancel")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    { staticClass: "secondary", attrs: { type: "submit" } },
                    [_vm._v(_vm._s(_vm.$t("actions.valid")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }