var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.error
    ? _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _vm._v(_vm._s(_vm.$t("errors.no_campaign"))),
              ]),
            ],
            1
          ),
        ],
        1
      )
    : _vm.campaign
    ? _c("export-leads-component", {
        attrs: {
          campaign: _vm.campaign,
          state: { show: true },
          "dialog-mode": false,
          model: _vm.model,
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }