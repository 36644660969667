"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var config_1 = require("../../dic/config");
var decorators_1 = require("../../decorator/decorators");
var campaign_repository_1 = require("../../repositories/campaign.repository");
var campaign_model_1 = require("../../model/campaign.model");
var vue_property_decorator_1 = require("vue-property-decorator");
var Card_vue_1 = require("./Card.vue");
var Filter_vue_1 = require("../filter/Filter.vue");
var paginate_model_1 = require("../../model/paginate.model");
var Market_vue_1 = require("../filter/campaign/Market.vue");
var State_vue_1 = require("../filter/campaign/State.vue");
var Type_vue_1 = require("../filter/campaign/Type.vue");
var vuex_class_1 = require("vuex-class");
var layoutModule = vuex_class_1.namespace('layout');
var ListComponent = (function (_super) {
    __extends(ListComponent, _super);
    function ListComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.limit = 10;
        return _this;
    }
    ListComponent.prototype.created = function () {
        this.filter = new campaign_repository_1.CampaignFilter();
        this.filter.states = [
            campaign_model_1.CampaignStatusEnum.CREATING,
            campaign_model_1.CampaignStatusEnum.RUNNING,
            campaign_model_1.CampaignStatusEnum.PAUSED,
            campaign_model_1.CampaignStatusEnum.OBJECTIVE_REACHED
        ];
        this.page = new Number(this.$route.query['page'] || 1).valueOf();
    };
    ListComponent.prototype.mounted = function () {
        this.updateCampaigns();
    };
    ListComponent.prototype.data = function () {
        return {
            campaigns: this.campaigns,
            totalCount: this.totalCount,
            filter: this.filter,
            page: this.page
        };
    };
    ListComponent.prototype.resetFilter = function () {
        this.page = 1;
        this.updateCampaigns();
    };
    ListComponent.prototype.searchFilter = function () {
        this.page = 1;
        this.updateCampaigns();
    };
    ListComponent.prototype.updateCampaigns = function () {
        var _this = this;
        var callback = function (result) {
            _this.hideProgressBar();
            _this.campaigns = result.campaigns;
            _this.totalCount = result.totalCount;
        };
        var paginate = {
            offset: (this.page - 1) * this.limit,
            limit: this.limit
        };
        this.showProgressBar();
        this.campaignRepository
            .getCampaigns(paginate, this.filter, {
            sort: 'id',
            order: paginate_model_1.OrderEnum.DESC
        })
            .then(callback);
    };
    ListComponent.prototype.countPages = function () {
        return Math.ceil(this.totalCount / this.limit);
    };
    ListComponent.prototype.onPageChanged = function (page, oldPage) {
        if (oldPage) {
            this.updateCampaigns();
            this.$router.push({
                path: this.$route.path,
                query: { page: page.toString() }
            });
        }
    };
    __decorate([
        decorators_1.Container(config_1.default.REPOSITORY_CAMPAIGN),
        __metadata("design:type", campaign_repository_1.CampaignRepository)
    ], ListComponent.prototype, "campaignRepository", void 0);
    __decorate([
        layoutModule.Action('showProgressBar'),
        __metadata("design:type", Function)
    ], ListComponent.prototype, "showProgressBar", void 0);
    __decorate([
        layoutModule.Action('hideProgressBar'),
        __metadata("design:type", Function)
    ], ListComponent.prototype, "hideProgressBar", void 0);
    __decorate([
        vue_property_decorator_1.Watch('page'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number, Number]),
        __metadata("design:returntype", void 0)
    ], ListComponent.prototype, "onPageChanged", null);
    ListComponent = __decorate([
        vue_class_component_1.default({
            components: {
                CampaignCardComponent: Card_vue_1.default,
                FilterComponent: Filter_vue_1.default,
                CampaignFilterMarket: Market_vue_1.default,
                CampaignFilterState: State_vue_1.default,
                CampaignFilterType: Type_vue_1.default
            }
        })
    ], ListComponent);
    return ListComponent;
}(vue_1.default));
exports.default = ListComponent;
