var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-icon",
    {
      attrs: {
        left: "",
        large: _vm.large,
        color: _vm.isRunning() ? "green" : "grey darken-3",
        title: _vm.$tc("campaigns.state." + _vm.state),
      },
    },
    [_vm._v("\n  " + _vm._s(_vm.stateIcon) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }