"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loginModule = void 0;
var container_1 = require("../dic/container");
var config_1 = require("../dic/config");
var loginService = container_1.default.get(config_1.default.SERVICE_LOGIN);
var accessTokenStorage = container_1.default.get(config_1.default.ACCESS_TOKEN_STORAGE);
exports.loginModule = {
    namespaced: true,
    state: {
        accessToken: accessTokenStorage.getAccessToken(),
        impersonatedUser: accessTokenStorage.hasImpersonatedUserId()
            ? Number.parseInt(accessTokenStorage.getImpersonatedUserId())
            : null
    },
    mutations: {
        setToken: function (state, token) {
            state.accessToken = token;
        },
        removeToken: function (state) {
            state.accessToken = null;
        },
        setImpersonatedUser: function (state, impersonatedUserId) {
            state.impersonatedUser = impersonatedUserId;
        },
        removeImpersonatedUser: function (state) {
            state.impersonatedUser = null;
        }
    },
    actions: {
        login: function (context, loginRequest) {
            return new Promise(function (success, reject) {
                loginService
                    .login(loginRequest)
                    .then(function (accessToken) {
                    accessTokenStorage.setAccessToken(accessToken);
                    context.commit('setToken', accessToken);
                    accessTokenStorage.removeImpersonatedUserId();
                    context.commit('removeImpersonatedUser');
                    if (loginRequest.impersonatedUserId) {
                        accessTokenStorage.setImpersonatedUserId(loginRequest.impersonatedUserId);
                        context.commit('setImpersonatedUser', Number.parseInt(loginRequest.impersonatedUserId));
                    }
                    success();
                })
                    .catch(reject);
            });
        },
        logout: function (context) {
            accessTokenStorage.removeAccessToken();
            accessTokenStorage.removeImpersonatedUserId();
            context.commit('removeToken');
            context.commit('removeImpersonatedUser');
        }
    }
};
