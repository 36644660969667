"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CampaignTypeEnum = exports.CampaignStatusEnum = exports.CampaignFilterOperator = exports.CampaignFilter = exports.ListCampaign = exports.Campaign = void 0;
var Campaign = (function () {
    function Campaign(data) {
        this.uuid = data.uuid;
        this.name = data.name;
        this.state = data.state;
        this.type = data.type;
        this.collectedData = data.collectedData || [];
        this.market = data.market;
        this.testLink = data.testLink;
    }
    Campaign.prototype.isStateCreating = function () {
        return this.state === CampaignStatusEnum.CREATING;
    };
    Campaign.prototype.isStateRunning = function () {
        return this.state === CampaignStatusEnum.RUNNING;
    };
    Campaign.prototype.isStatePaused = function () {
        return this.state === CampaignStatusEnum.PAUSED;
    };
    Campaign.prototype.isStateObjectiveReached = function () {
        return this.state === CampaignStatusEnum.OBJECTIVE_REACHED;
    };
    Campaign.prototype.isStateFinished = function () {
        return this.state === CampaignStatusEnum.FINISHED;
    };
    Campaign.prototype.isTypeSponsoring = function () {
        return this.type === CampaignTypeEnum.SPONSORING;
    };
    Campaign.prototype.isTypeSponsoringCheckbox = function () {
        return this.type === CampaignTypeEnum.SPONSORING_CHECKBOX;
    };
    Campaign.prototype.isTypeSponsoringComplex = function () {
        return this.type === CampaignTypeEnum.SPONSORING_COMPLEX;
    };
    Campaign.prototype.isTypeAffiliate = function () {
        return this.type === CampaignTypeEnum.AFFILIATE;
    };
    Campaign.prototype.isTypeCoregistration = function () {
        return this.type === CampaignTypeEnum.COREGISTRATION;
    };
    Campaign.prototype.isTypeComplexCoregistration = function () {
        return this.type === CampaignTypeEnum.COMPLEX_COREGISTRATION;
    };
    Campaign.prototype.isTypeFacebook = function () {
        return this.type === CampaignTypeEnum.FACEBOOK;
    };
    Campaign.prototype.isTypeRetargetingEmail = function () {
        return this.type === CampaignTypeEnum.RETARGETING_EMAIL;
    };
    Campaign.prototype.isTypeCallCenter = function () {
        return this.type === CampaignTypeEnum.CALL_CENTER;
    };
    return Campaign;
}());
exports.Campaign = Campaign;
var ListCampaign = (function (_super) {
    __extends(ListCampaign, _super);
    function ListCampaign(data) {
        var _this = _super.call(this, data) || this;
        _this.filters =
            data.filters.map(function (filter) { return new CampaignFilter(filter); }) || [];
        _this.ad = data.ad;
        return _this;
    }
    return ListCampaign;
}(Campaign));
exports.ListCampaign = ListCampaign;
var CampaignFilter = (function () {
    function CampaignFilter(data) {
        this.type = data.type;
        this.value = data.value;
        this.operator = data.operator;
    }
    CampaignFilter.prototype.isArrayValue = function () {
        return Array.isArray(this.value);
    };
    Object.defineProperty(CampaignFilter.prototype, "operatorSymbol", {
        get: function () {
            var symbol = '';
            switch (this.operator) {
                case CampaignFilterOperator.EQUAL:
                    symbol = '=';
                    break;
                case CampaignFilterOperator.GREATER_EQUAL:
                    symbol = '>=';
                    break;
                case CampaignFilterOperator.GREATER_THAN:
                    symbol = '>';
                    break;
                case CampaignFilterOperator.IN:
                    symbol = 'IN';
                    break;
                case CampaignFilterOperator.IS_MOBILE:
                    symbol = 'IS MOBILE';
                    break;
                case CampaignFilterOperator.IS_NOT_MOBILE:
                    symbol = 'IS NOT MOBILE';
                    break;
                case CampaignFilterOperator.IS_VALID:
                    symbol = 'IS VALID';
                    break;
                case CampaignFilterOperator.LESS_EQUAL:
                    symbol = '<=';
                    break;
                case CampaignFilterOperator.LESS_THAN:
                    symbol = '<';
                    break;
                case CampaignFilterOperator.NOT_EMPTY:
                    symbol = '';
                    break;
                case CampaignFilterOperator.NOT_EQUAL:
                    symbol = '!=';
                    break;
                case CampaignFilterOperator.NOT_IN:
                    symbol = 'NOT IN';
                    break;
                case CampaignFilterOperator.REGEX_MATCH:
                    symbol = 'REGEX';
                    break;
                case CampaignFilterOperator.REGEX_NOT_MATCH:
                    symbol = 'NOT REGEX';
                    break;
            }
            return symbol;
        },
        enumerable: false,
        configurable: true
    });
    return CampaignFilter;
}());
exports.CampaignFilter = CampaignFilter;
var CampaignFilterOperator;
(function (CampaignFilterOperator) {
    CampaignFilterOperator["EQUAL"] = "equal";
    CampaignFilterOperator["GREATER_EQUAL"] = "greater_equal";
    CampaignFilterOperator["GREATER_THAN"] = "greater_than";
    CampaignFilterOperator["IN"] = "in";
    CampaignFilterOperator["IS_MOBILE"] = "is_mobile";
    CampaignFilterOperator["IS_NOT_MOBILE"] = "is_not_mobile";
    CampaignFilterOperator["IS_VALID"] = "is_valid";
    CampaignFilterOperator["LESS_EQUAL"] = "less_equal";
    CampaignFilterOperator["LESS_THAN"] = "less_than";
    CampaignFilterOperator["NOT_EMPTY"] = "not_empty";
    CampaignFilterOperator["NOT_EQUAL"] = "not_equal";
    CampaignFilterOperator["NOT_IN"] = "not_in";
    CampaignFilterOperator["REGEX_MATCH"] = "regex_match";
    CampaignFilterOperator["REGEX_NOT_MATCH"] = "regex_not_match";
})(CampaignFilterOperator = exports.CampaignFilterOperator || (exports.CampaignFilterOperator = {}));
var CampaignStatusEnum;
(function (CampaignStatusEnum) {
    CampaignStatusEnum["RUNNING"] = "running";
    CampaignStatusEnum["PAUSED"] = "paused";
    CampaignStatusEnum["OBJECTIVE_REACHED"] = "objective_reached";
    CampaignStatusEnum["FINISHED"] = "finished";
    CampaignStatusEnum["CREATING"] = "creating";
})(CampaignStatusEnum = exports.CampaignStatusEnum || (exports.CampaignStatusEnum = {}));
var CampaignTypeEnum;
(function (CampaignTypeEnum) {
    CampaignTypeEnum["SPONSORING"] = "sponsoring";
    CampaignTypeEnum["SPONSORING_CHECKBOX"] = "sponsoring_checkbox";
    CampaignTypeEnum["SPONSORING_COMPLEX"] = "sponsoring_complex";
    CampaignTypeEnum["AFFILIATE"] = "affiliate";
    CampaignTypeEnum["COREGISTRATION"] = "coregistration";
    CampaignTypeEnum["COMPLEX_COREGISTRATION"] = "complex_coregistration";
    CampaignTypeEnum["FACEBOOK"] = "facebook";
    CampaignTypeEnum["RETARGETING_EMAIL"] = "retargeting_email";
    CampaignTypeEnum["CALL_CENTER"] = "call_center";
})(CampaignTypeEnum = exports.CampaignTypeEnum || (exports.CampaignTypeEnum = {}));
