var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "v-row",
        { attrs: { dense: true } },
        [
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: { name: "email", label: "Email", dense: true },
                model: {
                  value: _vm.collectedData.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.collectedData, "email", $$v)
                  },
                  expression: "collectedData.email",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: { name: "cookieUid", label: "Cookie UID", dense: true },
                model: {
                  value: _vm.collectedData.cookieUid,
                  callback: function ($$v) {
                    _vm.$set(_vm.collectedData, "cookieUid", $$v)
                  },
                  expression: "collectedData.cookieUid",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: true } },
        [
          _c("v-text-field", {
            attrs: { name: "ip", label: "IP Address", dense: true },
            model: {
              value: _vm.collectedData.ip,
              callback: function ($$v) {
                _vm.$set(_vm.collectedData, "ip", $$v)
              },
              expression: "collectedData.ip",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: true } },
        [
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: { name: "firstname", label: "Firstname", dense: true },
                model: {
                  value: _vm.collectedData.firstname,
                  callback: function ($$v) {
                    _vm.$set(_vm.collectedData, "firstname", $$v)
                  },
                  expression: "collectedData.firstname",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: { name: "lastname", label: "Lastname", dense: true },
                model: {
                  value: _vm.collectedData.lastname,
                  callback: function ($$v) {
                    _vm.$set(_vm.collectedData, "lastname", $$v)
                  },
                  expression: "collectedData.lastname",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: true } },
        [
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: { name: "zipcode", label: "Zipcode", dense: true },
                model: {
                  value: _vm.collectedData.zipcode,
                  callback: function ($$v) {
                    _vm.$set(_vm.collectedData, "zipcode", $$v)
                  },
                  expression: "collectedData.zipcode",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: true } },
        [
          _c(
            "v-col",
            { attrs: { "align-self": "end" } },
            [
              _c("v-text-field", {
                attrs: { name: "phone", label: "Phone", dense: true },
                model: {
                  value: _vm.collectedData.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.collectedData, "phone", $$v)
                  },
                  expression: "collectedData.phone",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { "align-self": "end" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.countries,
                  name: "country",
                  label: "Country",
                  dense: true,
                },
                model: {
                  value: _vm.collectedData.country,
                  callback: function ($$v) {
                    _vm.$set(_vm.collectedData, "country", $$v)
                  },
                  expression: "collectedData.country",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }