var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "ma-0 pa-0", attrs: { justify: "end" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", "max-width": "800px" },
          on: {
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              _vm.dialog = false
            },
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function (ref) {
                var on = ref.on
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass: "mr-0",
                        attrs: { color: "action text--white", dark: "" },
                      },
                      on
                    ),
                    [_vm._v(_vm._s(_vm.$t("oppositions.setup.title")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _vm._v(" "),
          _c(
            "v-card",
            [
              _c(
                "v-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("v-card-title", { staticClass: "primary white--text" }, [
                    _c("span", { staticClass: "text-h5" }, [
                      _vm._v(_vm._s(_vm.$t("oppositions.setup.title"))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-alert",
                        {
                          attrs: {
                            color: "error",
                            value: _vm.globalError ? true : false,
                            icon: "warning",
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.globalError) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("p", { staticClass: "mt-4 font-weight-bold" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("oppositions.setup.headline")) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.emails === undefined
                        ? _c("v-progress-circular", {
                            attrs: { size: "20", indeterminate: "" },
                          })
                        : _c("v-combobox", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "email",
                                expression: "'email'",
                              },
                            ],
                            staticClass: "pt-0",
                            attrs: {
                              "error-messages": _vm.errors.has("email")
                                ? [_vm.errors.first("email")]
                                : [],
                              name: "email",
                              "append-icon": null,
                              "hide-selected": "",
                              multiple: "",
                              "persistent-hint": "",
                              "small-chips": "",
                              "deletable-chips": "",
                              placeholder:
                                "example@domain1.com example@domain2.com",
                              delimiters: _vm.delimiters,
                            },
                            model: {
                              value: _vm.emails,
                              callback: function ($$v) {
                                _vm.emails = $$v
                              },
                              expression: "emails",
                            },
                          }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          nativeOn: {
                            click: function ($event) {
                              _vm.dialog = false
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("actions.cancel")))]
                      ),
                      _vm._v(" "),
                      _vm.inProgress || _vm.emails === undefined
                        ? _c(
                            "v-btn",
                            { staticClass: "secondary" },
                            [
                              _c("v-progress-circular", {
                                attrs: { size: "20", indeterminate: "" },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "v-btn",
                            {
                              staticClass: "secondary",
                              attrs: { type: "submit" },
                            },
                            [_vm._v(_vm._s(_vm.$t("actions.save")))]
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { color: "success", timeout: 5000, top: true },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v("\n    " + _vm._s(_vm.$t("confirm.edit")) + "\n\n    "),
          _c(
            "v-btn",
            {
              attrs: { color: "white" },
              on: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v(" Close ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }