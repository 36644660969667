"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OppositionType = exports.Opposition = void 0;
var Opposition = (function () {
    function Opposition(data) {
        this.email = data.email;
        this.type = data.type;
        this.phoneNumber = data.phoneNumber;
        this.opposedAt = new Date(data.opposedAt);
        this.sendingDate =
            data.sendingDate !== null ? new Date(data.sendingDate) : null;
        this.emailsSentTo = data.emailsSentTo;
    }
    Opposition.prototype.isSent = function () {
        return this.sendingDate !== null;
    };
    return Opposition;
}());
exports.Opposition = Opposition;
var OppositionType;
(function (OppositionType) {
    OppositionType["DELETE"] = "delete";
})(OppositionType = exports.OppositionType || (exports.OppositionType = {}));
