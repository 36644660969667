"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_property_decorator_1 = require("vue-property-decorator");
var user_repository_1 = require("../../repositories/user.repository");
var user_model_1 = require("../../model/user.model");
var decorators_1 = require("../../decorator/decorators");
var config_1 = require("../../dic/config");
var vuex_class_1 = require("vuex-class");
var layoutModule = vuex_class_1.namespace('layout');
var DeleteUserComponent = (function (_super) {
    __extends(DeleteUserComponent, _super);
    function DeleteUserComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.inProgress = false;
        _this.state = { show: false };
        return _this;
    }
    DeleteUserComponent.prototype.data = function () {
        return {
            globalError: this.globalError
        };
    };
    DeleteUserComponent.prototype.confirm = function () {
        var _this = this;
        this.globalError = null;
        var callbackSuccess = function () {
            _this.$emit('success');
            _this.state.show = false;
            _this.hideLoadingItems();
        };
        var callbackFail = function () {
            _this.globalError = _this.$tc('errors.generic');
            _this.hideLoadingItems();
        };
        this.showLoadingItems();
        this.userRepository
            .delete(this.user.id)
            .then(callbackSuccess)
            .catch(callbackFail);
    };
    DeleteUserComponent.prototype.cancel = function () {
        this.state.show = false;
        this.$validator.errors.clear();
    };
    DeleteUserComponent.prototype.showLoadingItems = function () {
        this.showProgressBar();
        this.inProgress = true;
    };
    DeleteUserComponent.prototype.hideLoadingItems = function () {
        this.hideProgressBar();
        this.inProgress = false;
    };
    Object.defineProperty(DeleteUserComponent.prototype, "roles", {
        get: function () {
            return [
                { value: user_model_1.RoleEnum.ADMIN, text: this.$tc('users.roles.admin') },
                { value: user_model_1.RoleEnum.DPO, text: this.$tc('users.roles.dpo') },
                { value: user_model_1.RoleEnum.LIMITED, text: this.$tc('users.roles.limited') }
            ];
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        decorators_1.Container(config_1.default.REPOSITORY_USER),
        __metadata("design:type", user_repository_1.UserRepository)
    ], DeleteUserComponent.prototype, "userRepository", void 0);
    __decorate([
        layoutModule.Action('showProgressBar'),
        __metadata("design:type", Function)
    ], DeleteUserComponent.prototype, "showProgressBar", void 0);
    __decorate([
        layoutModule.Action('hideProgressBar'),
        __metadata("design:type", Function)
    ], DeleteUserComponent.prototype, "hideProgressBar", void 0);
    __decorate([
        vue_property_decorator_1.Prop(),
        __metadata("design:type", user_model_1.ListUser)
    ], DeleteUserComponent.prototype, "user", void 0);
    DeleteUserComponent = __decorate([
        vue_class_component_1.default
    ], DeleteUserComponent);
    return DeleteUserComponent;
}(vue_1.default));
exports.default = DeleteUserComponent;
