"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var config_1 = require("../../dic/config");
var decorators_1 = require("../../decorator/decorators");
var campaign_repository_1 = require("../../repositories/campaign.repository");
var vue_property_decorator_1 = require("vue-property-decorator");
var CampaignFactoryTitleComponent = (function (_super) {
    __extends(CampaignFactoryTitleComponent, _super);
    function CampaignFactoryTitleComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.error = false;
        return _this;
    }
    Object.defineProperty(CampaignFactoryTitleComponent.prototype, "title", {
        get: function () {
            if (this.error)
                return this.$t('errors.no_campaign');
            if (this.update && this.campaign) {
                return (this.campaign.name +
                    this.$t("campaign_factory.title." + this.type + "_form_suffix.update"));
            }
            if (this.campaign) {
                return (this.campaign.name +
                    this.$t("campaign_factory.title." + this.type + "_form_suffix.create"));
            }
            return '';
        },
        enumerable: false,
        configurable: true
    });
    CampaignFactoryTitleComponent.prototype.mounted = function () {
        var _this = this;
        var campaignUuid = this.$route.params['campaignUuid'];
        this.campaignRepository
            .getCampaign(campaignUuid)
            .then(function (campaign) {
            _this.campaign = campaign;
        })
            .catch(function (response) {
            if (response.status === 404) {
                _this.error = true;
            }
        });
    };
    CampaignFactoryTitleComponent.prototype.data = function () {
        return {
            campaign: this.campaign
        };
    };
    __decorate([
        decorators_1.Container(config_1.default.REPOSITORY_CAMPAIGN),
        __metadata("design:type", campaign_repository_1.CampaignRepository)
    ], CampaignFactoryTitleComponent.prototype, "campaignRepository", void 0);
    __decorate([
        vue_property_decorator_1.Prop(),
        __metadata("design:type", Boolean)
    ], CampaignFactoryTitleComponent.prototype, "update", void 0);
    __decorate([
        vue_property_decorator_1.Prop(),
        __metadata("design:type", String)
    ], CampaignFactoryTitleComponent.prototype, "type", void 0);
    CampaignFactoryTitleComponent = __decorate([
        vue_class_component_1.default
    ], CampaignFactoryTitleComponent);
    return CampaignFactoryTitleComponent;
}(vue_1.default));
exports.default = CampaignFactoryTitleComponent;
