var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-chip",
    {
      attrs: {
        small: _vm.small,
        label: "",
        color: _vm.chipColor,
        "text-color": "white",
      },
    },
    [
      _vm._v(
        _vm._s(
          _vm._f("capitalize")(_vm.$t("campaigns.type." + _vm.campaign.type))
        )
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }