var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticStyle: { "min-height": "0" }, attrs: { fluid: "" } },
    [
      _vm.collects
        ? [
            _vm.collects.length <= 0
              ? _c(
                  "v-row",
                  [
                    _c("v-col", { attrs: { cols: "12" } }, [
                      _vm._v(_vm._s(_vm.$t("consents.empty_collect"))),
                    ]),
                  ],
                  1
                )
              : _c(
                  "v-row",
                  _vm._l(_vm.collects, function (collect) {
                    return _c(
                      "v-col",
                      { key: collect.date, attrs: { cols: "12" } },
                      [
                        _c(
                          "v-card",
                          [
                            _c(
                              "v-container",
                              { attrs: { fluid: "" } },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "12", md: "8" } },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "2" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-caption",
                                                  },
                                                  [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "consents.collect.email"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "4" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-caption",
                                                  },
                                                  [_vm._v(_vm._s(_vm.email))]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "2" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-caption",
                                                  },
                                                  [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "consents.collect.campaign_type"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "4" },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "text-left" },
                                                  [
                                                    _c(
                                                      "campaign-type-component",
                                                      {
                                                        attrs: {
                                                          campaign:
                                                            collect.campaign,
                                                          small: "",
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "2" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-caption",
                                                  },
                                                  [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "consents.collect.date"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "4" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-caption",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("gmtdate")(
                                                          collect.createdAt
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "2" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-caption",
                                                  },
                                                  [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "consents.collect.campaign"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "4" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-caption text-left",
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "mr-1",
                                                        attrs: { left: "" },
                                                      },
                                                      [
                                                        _c(
                                                          "campaign-flag-component",
                                                          {
                                                            attrs: {
                                                              campaign:
                                                                collect.campaign,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      { staticClass: "mr-1" },
                                                      [
                                                        _c(
                                                          "campaign-state-component",
                                                          {
                                                            attrs: {
                                                              state:
                                                                collect.campaign
                                                                  .state,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          collect.campaign.name
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "2" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-caption",
                                                  },
                                                  [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "consents.collect.ip_address"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "4" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-caption",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(collect.ipAddress)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "2" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-caption",
                                                  },
                                                  [
                                                    _c("b", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "consents.collect.support_url"
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "12", sm: "4" },
                                              },
                                              [
                                                _vm.getSupportUrl(collect)
                                                  .length > 0
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-caption",
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href: _vm.getSupportUrl(
                                                                collect
                                                              ),
                                                              target: "_blank",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.getSupportUrl(
                                                                  collect
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    collect.consents.length > 0
                                      ? _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "text-left fill-height",
                                            attrs: { cols: "12", md: "4" },
                                          },
                                          _vm._l(
                                            collect.consents,
                                            function (consent) {
                                              return _c(
                                                "v-dialog",
                                                {
                                                  key: consent.url,
                                                  attrs: { "max-width": "60%" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      consent.screenshotUrl
                                                        ? {
                                                            key: "activator",
                                                            fn: function (ref) {
                                                              var on = ref.on
                                                              return [
                                                                _c(
                                                                  "img",
                                                                  _vm._g(
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "100%",
                                                                          height:
                                                                            "100%",
                                                                        },
                                                                      attrs: {
                                                                        src: consent.screenshotUrl,
                                                                      },
                                                                    },
                                                                    on
                                                                  )
                                                                ),
                                                              ]
                                                            },
                                                          }
                                                        : null,
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c("img", {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      src: consent.screenshotUrl,
                                                    },
                                                  }),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
            _vm._v(" "),
            _vm.collects.length > 0
              ? _c(
                  "v-row",
                  [
                    _c("v-spacer"),
                    _vm._v(" "),
                    _vm.totalCount
                      ? _c("v-pagination", {
                          attrs: {
                            length: _vm.countPages(),
                            "total-visible": "6",
                          },
                          model: {
                            value: _vm.page,
                            callback: function ($$v) {
                              _vm.page = $$v
                            },
                            expression: "page",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("v-spacer"),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }