"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_property_decorator_1 = require("vue-property-decorator");
var user_repository_1 = require("../../repositories/user.repository");
var user_model_1 = require("../../model/user.model");
var decorators_1 = require("../../decorator/decorators");
var config_1 = require("../../dic/config");
var vuex_class_1 = require("vuex-class");
var layoutModule = vuex_class_1.namespace('layout');
var CreateEditUserComponent = (function (_super) {
    __extends(CreateEditUserComponent, _super);
    function CreateEditUserComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.inProgress = false;
        _this.state = { show: false };
        return _this;
    }
    CreateEditUserComponent.prototype.onUserChange = function (user) {
        if (user) {
            this.recreateFormModel();
        }
    };
    CreateEditUserComponent.prototype.data = function () {
        return {
            globalError: this.globalError
        };
    };
    CreateEditUserComponent.prototype.created = function () {
        this.recreateFormModel();
    };
    CreateEditUserComponent.prototype.save = function () {
        var _this = this;
        this.globalError = null;
        this.$validator.validateAll().then(function (result) {
            if (result === true) {
                _this.showLoadingItems();
                var callbackSuccess = function () {
                    _this.hideLoadingItems();
                    _this.$emit('success');
                    _this.state.show = false;
                    _this.model = {};
                };
                var callbackFail = function (response) {
                    _this.hideLoadingItems();
                    switch (response.status) {
                        case 400:
                            (response.data || { errors: [] }).errors.forEach(function (error) {
                                _this.$validator.errors.add({
                                    field: error.property,
                                    msg: _this.$tc('errors.form.' + error.code)
                                });
                            });
                            break;
                        case 409:
                            _this.$validator.errors.add({
                                field: 'email',
                                msg: _this.$tc('errors.form.email_unique')
                            });
                            break;
                        default:
                            _this.globalError = _this.$tc('errors.generic');
                            break;
                    }
                };
                if (_this.user) {
                    _this.userRepository
                        .update(_this.user.id, _this.model)
                        .then(callbackSuccess)
                        .catch(callbackFail);
                }
                else {
                    _this.userRepository
                        .create(_this.model)
                        .then(callbackSuccess)
                        .catch(callbackFail);
                }
            }
        });
    };
    CreateEditUserComponent.prototype.showLoadingItems = function () {
        this.showProgressBar();
        this.inProgress = true;
    };
    CreateEditUserComponent.prototype.hideLoadingItems = function () {
        this.hideProgressBar();
        this.inProgress = false;
    };
    CreateEditUserComponent.prototype.cancel = function () {
        this.state.show = false;
        this.recreateFormModel();
        this.$validator.errors.clear();
    };
    Object.defineProperty(CreateEditUserComponent.prototype, "roles", {
        get: function () {
            return [
                { value: user_model_1.RoleEnum.ADMIN, text: this.$tc('users.roles.admin') },
                { value: user_model_1.RoleEnum.DPO, text: this.$tc('users.roles.dpo') },
                { value: user_model_1.RoleEnum.LIMITED, text: this.$tc('users.roles.limited') },
                {
                    value: user_model_1.RoleEnum.LIMITED_OPPOSITION,
                    text: this.$tc('users.roles.limited_opposition')
                }
            ];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CreateEditUserComponent.prototype, "states", {
        get: function () {
            return [
                { value: true, text: this.$tc('users.state.true') },
                { value: false, text: this.$tc('users.state.false') }
            ];
        },
        enumerable: false,
        configurable: true
    });
    CreateEditUserComponent.prototype.recreateFormModel = function () {
        if (this.user) {
            this.model = {
                email: this.user.email,
                firstname: this.user.firstname,
                lastname: this.user.lastname,
                role: this.user.getMainRole(),
                enabled: this.user.enabled
            };
        }
        else {
            this.model = {};
        }
    };
    __decorate([
        decorators_1.Container(config_1.default.REPOSITORY_USER),
        __metadata("design:type", user_repository_1.UserRepository)
    ], CreateEditUserComponent.prototype, "userRepository", void 0);
    __decorate([
        layoutModule.Action('showProgressBar'),
        __metadata("design:type", Function)
    ], CreateEditUserComponent.prototype, "showProgressBar", void 0);
    __decorate([
        layoutModule.Action('hideProgressBar'),
        __metadata("design:type", Function)
    ], CreateEditUserComponent.prototype, "hideProgressBar", void 0);
    __decorate([
        vue_property_decorator_1.Prop(),
        __metadata("design:type", user_model_1.ListUser)
    ], CreateEditUserComponent.prototype, "user", void 0);
    __decorate([
        vue_property_decorator_1.Watch('user'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [user_model_1.User]),
        __metadata("design:returntype", void 0)
    ], CreateEditUserComponent.prototype, "onUserChange", null);
    CreateEditUserComponent = __decorate([
        vue_class_component_1.default
    ], CreateEditUserComponent);
    return CreateEditUserComponent;
}(vue_1.default));
exports.default = CreateEditUserComponent;
