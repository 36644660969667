var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c("v-spacer"),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: "8" } },
        [
          _c(
            "v-tabs",
            {
              model: {
                value: _vm.currentTheme,
                callback: function ($$v) {
                  _vm.currentTheme = $$v
                },
                expression: "currentTheme",
              },
            },
            _vm._l(_vm.themes, function (theme) {
              return _c("v-tab", { key: theme.name }, [
                _vm._v(_vm._s(theme.name)),
              ])
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.currentTheme,
                callback: function ($$v) {
                  _vm.currentTheme = $$v
                },
                expression: "currentTheme",
              },
            },
            _vm._l(_vm.themes, function (theme) {
              return _c(
                "v-tab-item",
                { key: theme.name },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "bat",
                      class: theme.name,
                      attrs: { id: "bat-" + theme.name, outlined: "" },
                    },
                    [_c("div", { attrs: { id: theme.name } })]
                  ),
                  _vm._v(" "),
                  theme.hasError
                    ? _c("v-alert", { attrs: { dense: "", type: "error" } }, [
                        _vm._v(
                          "\n          Unable to display preview (missing assets ?)\n        "
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-spacer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }