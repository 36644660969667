var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12", md: "10", "offset-md": "1" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-text",
                    [
                      _vm.error
                        ? _c("p", [
                            _vm._v(_vm._s(_vm.$t("errors.no_campaign"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.forbidden
                        ? _c("p", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("errors.conversions_list_forbidden")
                                ) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.campaign && _vm.conversions
                        ? [
                            _c(
                              "v-row",
                              {
                                staticClass: "mt-2 ml-2",
                                attrs: { align: "center" },
                              },
                              [
                                _c("h4", [
                                  _vm._v(
                                    _vm._s(_vm.$t("conversions.campaign_title"))
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-row",
                              {
                                staticClass: "mt-2 ml-2",
                                attrs: { align: "center" },
                              },
                              [
                                _c("campaign-flag-component", {
                                  staticClass: "mr-2",
                                  attrs: { campaign: _vm.campaign },
                                }),
                                _vm._v(" "),
                                _c("campaign-state-component", {
                                  staticClass: "mr-2",
                                  attrs: {
                                    state: _vm.campaign.state,
                                    small: "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("h4", { staticClass: "text-body-2 mr-2" }, [
                                  _vm._v(_vm._s(_vm.campaign.name)),
                                ]),
                                _vm._v(" "),
                                _vm.campaign.testLink
                                  ? _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          href: _vm.campaign.testLink,
                                          target: "_blank",
                                          color: "action white--text ml-0 mr-2",
                                          small: "",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t(
                                                "conversions.open_test_link"
                                              )
                                            ) +
                                            "\n                "
                                        ),
                                        _c(
                                          "v-icon",
                                          { attrs: { right: "", small: "" } },
                                          [_vm._v("open_in_new")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("v-row", { staticClass: "ml-2" }, [
                              _c("h4", { staticClass: "mt-4" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("conversions.test_title"))
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("v-data-table", {
                              attrs: {
                                headers: _vm.headers,
                                items: _vm.conversions,
                                "no-data-text": _vm.$t(
                                  "conversions.no_data_text"
                                ),
                                "items-per-page": "100",
                                "hide-default-footer": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item",
                                    fn: function (ref) {
                                      var item = ref.item
                                      return [
                                        _c("tr", [
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("gmtdate")(item.date)
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [_vm._v(_vm._s(item.uid))]),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            [
                                              item.hasCookieUid
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { color: "green" },
                                                    },
                                                    [_vm._v("check_circle")]
                                                  )
                                                : _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "grey darken-3",
                                                      },
                                                    },
                                                    [_vm._v("error")]
                                                  ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            [
                                              item.isSupportLocationTest
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { color: "green" },
                                                    },
                                                    [_vm._v("check_circle")]
                                                  )
                                                : _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        color: "grey darken-3",
                                                      },
                                                    },
                                                    [_vm._v("error")]
                                                  ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            [
                                              false === item.hasCookieUid
                                                ? [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "conversions.no_cookie"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                          _vm._v(" "),
                                          _vm.accessTokenStorage.hasImpersonatedUserId()
                                            ? _c("td", [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(item.adId) +
                                                    "\n                  "
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.accessTokenStorage.hasImpersonatedUserId()
                                            ? _c("td", [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(item.paybackMode) +
                                                    "\n                  "
                                                ),
                                              ])
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.accessTokenStorage.hasImpersonatedUserId()
                                            ? _c("td", [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      item.supportLocationId
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                647958508
                              ),
                            }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }