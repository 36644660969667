"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
require("core-js/stable");
require("whatwg-fetch");
var vue_1 = require("vue");
var index_1 = require("./router/index");
var App_vue_1 = require("./App.vue");
var vee_validate_1 = require("vee-validate");
var store_1 = require("./store/store");
var config_1 = require("./dic/config");
var vue_flag_icon_1 = require("vue-flag-icon");
var container_1 = require("./dic/container");
var vee_rules_1 = require("vee-rules");
var vuetify_1 = require("./plugins/vuetify");
require("./filters/index.ts");
var factory_1 = require("./i18n/factory");
vee_validate_1.Validator.extend('required', vee_rules_1.required);
vee_validate_1.Validator.extend('min', vee_rules_1.min);
vee_validate_1.Validator.extend('max', vee_rules_1.max);
vee_validate_1.Validator.extend('email', vee_rules_1.email);
vee_validate_1.Validator.extend('is', vee_rules_1.is);
vee_validate_1.Validator.extend('url', vee_rules_1.url);
vee_validate_1.Validator.extend('min_value', vee_rules_1.min_value);
vee_validate_1.Validator.extend('max_value', vee_rules_1.max_value);
vue_1.default.use(vue_flag_icon_1.default);
vue_1.default.use(vee_validate_1.install);
new vue_1.default({
    el: '#app',
    router: index_1.default,
    i18n: factory_1.i18n,
    store: store_1.store,
    vuetify: vuetify_1.default,
    provide: (_a = {},
        _a[config_1.default.CONTAINER] = container_1.default,
        _a),
    components: { AppComponent: App_vue_1.default },
    template: '<AppComponent/>'
});
