var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g({ attrs: { text: "", icon: "" } }, on),
                [_c("v-icon", [_vm._v("settings")])],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "v-list",
        [
          _c(
            "v-list-item",
            { on: { click: _vm.logout } },
            [
              _c("v-list-item-title", [
                _vm._v(_vm._s(_vm.$tc("settings.logout"))),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }