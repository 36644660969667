var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.users,
          "no-data-text": _vm.noDataText,
          "hide-default-footer": "",
          loading: _vm.progressBar,
          "loading-text": _vm.$t("loading"),
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("tr", [
                  _c("td", [_vm._v(_vm._s(item.email))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(item.getFullName()))]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      item.hasRoleAdmin()
                        ? [_vm._v("Admin")]
                        : item.hasRoleDPO()
                        ? [_vm._v("DPO")]
                        : item.hasRoleLimitedOpposition()
                        ? [
                            _vm._v(
                              _vm._s(_vm.$t("users.roles.limited_opposition"))
                            ),
                          ]
                        : [_vm._v(_vm._s(_vm.$t("users.roles.limited")))],
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      item.lastConnectedAt
                        ? [
                            _vm._v(
                              _vm._s(_vm._f("gmtdate")(item.lastConnectedAt))
                            ),
                          ]
                        : [
                            _vm._v(
                              _vm._s(_vm.$t("errors.no_last_connected_at"))
                            ),
                          ],
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      item.enabled
                        ? [_vm._v(_vm._s(_vm.$t("users.state.true")))]
                        : [_vm._v(_vm._s(_vm.$t("users.state.false")))],
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.user
                    ? _c(
                        "td",
                        [
                          _c("create-edit-user-component", {
                            attrs: { user: item },
                            on: { success: _vm.refreshUsers },
                          }),
                          _vm._v(" "),
                          !item.hasRoleDPO() && item.id !== _vm.user.id
                            ? _c("delete-user-component", {
                                attrs: { user: item },
                                on: { success: _vm.refreshUsers },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("create-edit-user-component", { on: { success: _vm.refreshUsers } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }