var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c("v-spacer"),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: "8" } },
        [
          _c(
            "v-card",
            { staticClass: "bat", attrs: { outlined: "" } },
            [
              _c("div", { attrs: { id: "sponsors" } }),
              _vm._v(" "),
              _c("div", { attrs: { id: "modals" } }),
              _vm._v(" "),
              _vm.hasAd
                ? _c(
                    "v-btn",
                    {
                      staticClass: "bat-display-sponsoring-button",
                      on: { click: _vm.displaySponsoring },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("campaigns.sponsoring.bat.button")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-spacer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }