"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var Flag_vue_1 = require("./Flag.vue");
var State_vue_1 = require("./State.vue");
var decorators_1 = require("../../decorator/decorators");
var config_1 = require("../../dic/config");
var campaign_repository_1 = require("../../repositories/campaign.repository");
var SponsoringBat_vue_1 = require("./SponsoringBat.vue");
var CoregistrationBat_vue_1 = require("./CoregistrationBat.vue");
var SponsoringCheckboxBat_vue_1 = require("./SponsoringCheckboxBat.vue");
var BatComponent = (function (_super) {
    __extends(BatComponent, _super);
    function BatComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.error = false;
        _this.forbidden = false;
        return _this;
    }
    BatComponent.prototype.data = function () {
        return {
            campaign: this.campaign,
            error: this.error
        };
    };
    BatComponent.prototype.created = function () {
        var _this = this;
        var campaignUuid = this.$route.params['campaignUuid'];
        var token = this.$route.params.token;
        this.campaignRepository
            .getCampaign(campaignUuid, token)
            .then(function (campaign) {
            _this.campaign = campaign;
            _this.token = token;
        })
            .catch(function (response) {
            if (response.status === 404) {
                _this.error = true;
            }
            else if (response.status === 403) {
                _this.forbidden = true;
            }
        });
    };
    __decorate([
        decorators_1.Container(config_1.default.REPOSITORY_CAMPAIGN),
        __metadata("design:type", campaign_repository_1.CampaignRepository)
    ], BatComponent.prototype, "campaignRepository", void 0);
    BatComponent = __decorate([
        vue_class_component_1.default({
            components: {
                CampaignFlagComponent: Flag_vue_1.default,
                CampaignStateComponent: State_vue_1.default,
                SponsoringBatComponent: SponsoringBat_vue_1.default,
                SponsoringCheckboxBatComponent: SponsoringCheckboxBat_vue_1.default,
                CoregistrationBatComponent: CoregistrationBat_vue_1.default
            }
        })
    ], BatComponent);
    return BatComponent;
}(vue_1.default));
exports.default = BatComponent;
