var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("canvas", {
    ref: "canvas",
    staticStyle: { position: "relative", height: "60vh", width: "80vw" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }