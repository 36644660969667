"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_property_decorator_1 = require("vue-property-decorator");
var vuex_class_1 = require("vuex-class");
var decorators_1 = require("../../decorator/decorators");
var config_1 = require("../../dic/config");
var campaignFactory_repository_1 = require("../../repositories/campaignFactory.repository");
var LiveRequestForm_vue_1 = require("./Test/LiveRequestForm.vue");
var OppositionForm_vue_1 = require("./Test/OppositionForm.vue");
var PostCollectForm_vue_1 = require("./Test/PostCollectForm.vue");
var CollectForm_vue_1 = require("./Test/CollectForm.vue");
var layoutModule = vuex_class_1.namespace('layout');
var CampaignFactoryTestComponent = (function (_super) {
    __extends(CampaignFactoryTestComponent, _super);
    function CampaignFactoryTestComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.collectedData = {};
        return _this;
    }
    CampaignFactoryTestComponent.prototype.mounted = function () {
        this.campaignUuid = this.$route.params['campaignUuid'];
        this.getDefaultCollectedData(this.campaignUuid);
    };
    Object.defineProperty(CampaignFactoryTestComponent.prototype, "hasError", {
        get: function () {
            return this.testResponse && this.testResponse.state === 'error';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CampaignFactoryTestComponent.prototype, "isCollectable", {
        get: function () {
            return this.testResponse && this.testResponse.state === 'collect';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CampaignFactoryTestComponent.prototype, "isNotCollectable", {
        get: function () {
            return this.testResponse && this.testResponse.state === 'nocollect';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CampaignFactoryTestComponent.prototype, "didTimeout", {
        get: function () {
            return this.testResponse && this.testResponse.state === 'timeout';
        },
        enumerable: false,
        configurable: true
    });
    CampaignFactoryTestComponent.prototype.data = function () {
        return {};
    };
    CampaignFactoryTestComponent.prototype.sendTest = function () {
        var _this = this;
        this.showProgressBar();
        this.$emit('start-send-test');
        var callbackSuccess = function (testResponse) {
            _this.hideProgressBar();
            _this.$emit('success', testResponse);
        };
        var callbackFail = function (response) {
            _this.hideProgressBar();
            switch (response.status) {
                case 400:
                    if (undefined !== response.data &&
                        response.data.errors.length > 0 &&
                        response.data.errors[0].property.startsWith('parameters')) {
                        _this.$emit('parameters-error', response);
                    }
                    else {
                        _this.globalError = _this.$tc('errors.generic');
                    }
                    break;
                default:
                    _this.globalError = _this.$tc('errors.generic');
                    break;
            }
            _this.$emit('error', _this.globalError);
        };
        var key;
        var emptyCollectedData = true;
        for (key in this.collectedData) {
            if (['ip', 'cookieUid'].indexOf(key) == -1 &&
                this.collectedData[key] !== null) {
                emptyCollectedData = false;
                break;
            }
        }
        if (emptyCollectedData) {
            this.globalError = this.$tc('campaign_factory.test.error.collected_data_empty');
            this.$emit('error', this.globalError);
            return false;
        }
        if (this.campaignFactory.type === 'export') {
            this.campaignFactoryRepository
                .testExportCampaignFactory(this.campaignFactory, this.collectedData, this.campaignUuid)
                .then(function (file) {
                var date = new Date();
                var formattedDate = "" + date.getFullYear() + date.getMonth() + date.getDay();
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(file);
                link.setAttribute('download', "test_export_" + _this.campaignUuid + "_" + formattedDate + ".csv");
                link.click();
                _this.hideProgressBar();
            });
            return true;
        }
        this.campaignFactoryRepository
            .testCampaignFactory(this.campaignFactory, this.collectedData, this.campaignUuid)
            .then(callbackSuccess)
            .catch(callbackFail);
        return true;
    };
    CampaignFactoryTestComponent.prototype.reset = function () {
        this.getDefaultCollectedData(this.campaignUuid);
    };
    CampaignFactoryTestComponent.prototype.getDefaultCollectedData = function (campaignUuid) {
        var _this = this;
        this.campaignFactoryRepository
            .getDefaultData(campaignUuid, this.campaignFactory.type)
            .then(function (collectedData) {
            _this.collectedData = collectedData;
        })
            .catch(function () {
            _this.collectedData = {};
        });
    };
    CampaignFactoryTestComponent.prototype.updateCollectedData = function (key, value) {
        this.collectedData = __assign({}, this.collectedData);
        this.collectedData[key] = value;
    };
    __decorate([
        decorators_1.Container(config_1.default.REPOSITORY_CAMPAIGN_FACTORY),
        __metadata("design:type", campaignFactory_repository_1.CampaignFactoryRepository)
    ], CampaignFactoryTestComponent.prototype, "campaignFactoryRepository", void 0);
    __decorate([
        vue_property_decorator_1.Prop(),
        __metadata("design:type", Object)
    ], CampaignFactoryTestComponent.prototype, "campaignFactory", void 0);
    __decorate([
        vue_property_decorator_1.Prop(),
        __metadata("design:type", Boolean)
    ], CampaignFactoryTestComponent.prototype, "sendButtonEnabled", void 0);
    __decorate([
        vue_property_decorator_1.Prop(),
        __metadata("design:type", Object)
    ], CampaignFactoryTestComponent.prototype, "testResponse", void 0);
    __decorate([
        layoutModule.Action('showProgressBar'),
        __metadata("design:type", Function)
    ], CampaignFactoryTestComponent.prototype, "showProgressBar", void 0);
    __decorate([
        layoutModule.Action('hideProgressBar'),
        __metadata("design:type", Function)
    ], CampaignFactoryTestComponent.prototype, "hideProgressBar", void 0);
    CampaignFactoryTestComponent = __decorate([
        vue_class_component_1.default({
            components: {
                LiveRequestFormComponent: LiveRequestForm_vue_1.default,
                OppositionFormComponent: OppositionForm_vue_1.default,
                PostCollectFormComponent: PostCollectForm_vue_1.default,
                CollectFormComponent: CollectForm_vue_1.default
            }
        })
    ], CampaignFactoryTestComponent);
    return CampaignFactoryTestComponent;
}(vue_1.default));
exports.default = CampaignFactoryTestComponent;
