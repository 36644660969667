"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MarketEnum = void 0;
var MarketEnum;
(function (MarketEnum) {
    MarketEnum["FR"] = "fr";
    MarketEnum["IT"] = "it";
    MarketEnum["ES"] = "es";
    MarketEnum["PL"] = "pl";
    MarketEnum["UK"] = "uk";
    MarketEnum["AU"] = "au";
})(MarketEnum = exports.MarketEnum || (exports.MarketEnum = {}));
