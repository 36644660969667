var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c("v-spacer"),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: "8" } },
        [
          _c(
            "v-card",
            { staticClass: "bat sponsoring-checkbox", attrs: { outlined: "" } },
            [
              _c("div", { staticClass: "container-popin" }, [
                _c(
                  "div",
                  {
                    staticClass: "container-sponso_error",
                    attrs: { "data-state": "idle" },
                  },
                  [
                    _c("h3", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(
                              "campaigns.sponsoring_checkbox.bat.error.header"
                            )
                          ) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(
                              "campaigns.sponsoring_checkbox.bat.error.content"
                            )
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "container-consent" }, [
                  _c(
                    "div",
                    { staticClass: "container-consent__container-title" },
                    [
                      _c("i", { staticClass: "bi bi-journal" }),
                      _vm._v(" "),
                      _c("i", { staticClass: "bi bi-check" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "container-title__title" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.$t("campaigns.sponsoring_checkbox.bat.title")
                            )
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { attrs: { id: "sponsors_checkbox" } }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "container-consent__container-submit" },
                    [
                      _c("p", {
                        staticClass:
                          "container-consent__container-submit__privacy",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t(
                              "campaigns.sponsoring_checkbox.bat.label.privacy"
                            )
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "container-consent__container-submit__privacy",
                        },
                        [
                          _vm._v(
                            "\n              Vous pouvez revenir sur vos consentements, à tout moment via\n              "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://umc.webrivage.com",
                                target: "_blank",
                              },
                            },
                            [
                              _vm._v(
                                "\n                l'interface utilisateur disponible ici à cet usage."
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "container-consent__container-submit__button",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "\n                  container-consent__container-submit__button__information\n                ",
                              attrs: { "data-state": "hidden" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "\n                    container-consent__container-submit__button__information__text\n                  ",
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.$t(
                                          "campaigns.sponsoring_checkbox.bat.label.accept_choices"
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "\n                    container-consent__container-submit__button__information__close\n                  ",
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.$t(
                                          "campaigns.sponsoring_checkbox.bat.button.close"
                                        )
                                      )
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "container-submit__accept-selected",
                              attrs: {
                                "data-optinclient-submit": "true",
                                href: "#",
                              },
                              on: { click: _vm.acceptChoices },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t(
                                      "campaigns.sponsoring_checkbox.bat.button.accept_choices"
                                    )
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("i", {
                            staticClass:
                              "bi-info-circle-fill toggle-information",
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "container-consent__container-submit__button",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "\n                  container-consent__container-submit__button__information\n                ",
                              attrs: { "data-state": "hidden" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "\n                    container-consent__container-submit__button__information__text\n                  ",
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.$t(
                                          "campaigns.sponsoring_checkbox.bat.label.accept_all"
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "\n                    container-consent__container-submit__button__information__close\n                  ",
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.$t(
                                          "campaigns.sponsoring_checkbox.bat.button.close"
                                        )
                                      )
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "container-submit__accept-selected",
                              attrs: {
                                "data-optinclient-submit": "true",
                                href: "#",
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t(
                                      "campaigns.sponsoring_checkbox.bat.button.accept_all"
                                    )
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("i", {
                            staticClass:
                              "bi-info-circle-fill toggle-information",
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "container-consent__container-submit__button",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "\n                  container-consent__container-submit__button__information\n                ",
                              attrs: { "data-state": "hidden" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "\n                    container-consent__container-submit__button__information__text\n                  ",
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.$t(
                                          "campaigns.sponsoring_checkbox.bat.label.skip"
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "\n                    container-consent__container-submit__button__information__close\n                  ",
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.$t(
                                          "campaigns.sponsoring_checkbox.bat.button.close"
                                        )
                                      )
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "container-submit__accept-selected",
                              attrs: {
                                "data-optinclient-submit": "true",
                                href: "#",
                              },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.$t(
                                      "campaigns.sponsoring_checkbox.bat.button.skip"
                                    )
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("i", {
                            staticClass:
                              "bi-info-circle-fill toggle-information",
                          }),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-spacer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }