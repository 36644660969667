var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-select", {
    attrs: {
      "hide-details": "",
      multiple: "",
      items: _vm.campaigns,
      "item-value": "uuid",
      "item-text": "name",
      "cache-items": "",
      label: _vm.$t("campaigns.form.filters.placeholders.campaigns"),
    },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function (ref) {
          var parent = ref.parent
          var item = ref.item
          var attrs = ref.attrs
          return [
            _c(
              "v-list-item-action",
              [
                _c("v-checkbox", {
                  attrs: { left: "", label: "", "hide-details": "" },
                  model: {
                    value: attrs.inputValue,
                    callback: function ($$v) {
                      _vm.$set(attrs, "inputValue", $$v)
                    },
                    expression: "attrs.inputValue",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-list-item-avatar",
              { attrs: { size: "56px" } },
              [_c("flag", { attrs: { iso: _vm._f("country")(item.market) } })],
              1
            ),
            _vm._v(" "),
            _c(
              "v-list-item-avatar",
              { attrs: { size: "56px" } },
              [
                item.state
                  ? _c("campaign-state-component", {
                      attrs: { state: item.state, small: "" },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("v-list-item-content", [
              _vm._v("\n      " + _vm._s(item.name) + "\n    "),
            ]),
          ]
        },
      },
    ]),
    model: {
      value: _vm.filter.campaigns,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "campaigns", $$v)
      },
      expression: "filter.campaigns",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }