"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.campaignModule = void 0;
var container_1 = require("../dic/container");
var config_1 = require("../dic/config");
var paginate_model_1 = require("../model/paginate.model");
var campaignRepository = container_1.default.get(config_1.default.REPOSITORY_CAMPAIGN);
exports.campaignModule = {
    namespaced: true,
    state: {
        campaigns: undefined
    },
    getters: {
        getCampaigns: function (state) {
            return state.campaigns;
        }
    },
    mutations: {
        setCampaigns: function (state, campaigns) {
            state.campaigns = campaigns;
        }
    },
    actions: {
        loadCampaigns: function (_a) {
            var commit = _a.commit, state = _a.state;
            return __awaiter(this, void 0, void 0, function () {
                var result;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            if (!!state.campaigns) return [3, 2];
                            return [4, campaignRepository.getCampaigns(undefined, undefined, { sort: 'name', order: paginate_model_1.OrderEnum.ASC })];
                        case 1:
                            result = _b.sent();
                            commit('setCampaigns', result.campaigns);
                            _b.label = 2;
                        case 2: return [2];
                    }
                });
            });
        },
        getCampaignMarkets: function (_a) {
            var dispatch = _a.dispatch, state = _a.state;
            return __awaiter(this, void 0, void 0, function () {
                var markets, uniqueMarkets;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4, dispatch('loadCampaigns')];
                        case 1:
                            _b.sent();
                            markets = new Set((state.campaigns || []).map(function (campaign) {
                                return campaign.market;
                            }));
                            uniqueMarkets = [];
                            markets.forEach(function (market) {
                                uniqueMarkets.push(market);
                            });
                            return [2, uniqueMarkets];
                    }
                });
            });
        },
        getCampaigns: function (_a) {
            var dispatch = _a.dispatch, state = _a.state;
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4, dispatch('loadCampaigns')];
                        case 1:
                            _b.sent();
                            return [2, state.campaigns];
                    }
                });
            });
        }
    }
};
