var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "800px" },
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          _vm.state.show = false
        },
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g(
                  { attrs: { icon: "", small: "", color: "secondary" } },
                  on
                ),
                [_c("v-icon", [_vm._v("delete")])],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.state.show,
        callback: function ($$v) {
          _vm.$set(_vm.state, "show", $$v)
        },
        expression: "state.show",
      },
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "primary white--text" }, [
            _vm.user
              ? _c("span", { staticClass: "text-h5" }, [
                  _vm._v(_vm._s(_vm.$t("users.modal.title_delete"))),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-alert",
                {
                  attrs: {
                    color: "error",
                    value: _vm.globalError ? true : false,
                    icon: "warning",
                  },
                },
                [_vm._v("\n        " + _vm._s(_vm.globalError) + "\n      ")]
              ),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.$t("users.modal.confirm_delete", {
                      email: _vm.user.email,
                    })
                  )
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  nativeOn: {
                    click: function ($event) {
                      return _vm.cancel.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("actions.cancel")) + "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.inProgress
                ? _c(
                    "v-btn",
                    { staticClass: "error" },
                    [
                      _c("v-progress-circular", {
                        attrs: { size: "20", indeterminate: "" },
                      }),
                    ],
                    1
                  )
                : _c(
                    "v-btn",
                    {
                      staticClass: "error",
                      attrs: { type: "submit" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.confirm.apply(null, arguments)
                        },
                      },
                    },
                    [[_vm._v(_vm._s(_vm.$t("actions.delete")))]],
                    2
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }