var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "v-row",
        { attrs: { dense: true } },
        [
          _c(
            "v-col",
            [
              _c("v-text-field", {
                attrs: { name: "email", label: "Email", dense: true },
                model: {
                  value: _vm.collectedData.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.collectedData, "email", $$v)
                  },
                  expression: "collectedData.email",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { "align-self": "end" } },
            [
              _c("v-text-field", {
                attrs: { name: "phone", label: "Phone", dense: true },
                model: {
                  value: _vm.collectedData.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.collectedData, "phone", $$v)
                  },
                  expression: "collectedData.phone",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }