var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-snackbar",
        {
          attrs: { bottom: true, color: "error" },
          model: {
            value: _vm.errorSnackbar,
            callback: function ($$v) {
              _vm.errorSnackbar = $$v
            },
            expression: "errorSnackbar",
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.errorMessage) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "v-snackbar",
        {
          attrs: { bottom: true, color: "success" },
          model: {
            value: _vm.successSnackbar,
            callback: function ($$v) {
              _vm.successSnackbar = $$v
            },
            expression: "successSnackbar",
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.successMessage) + "\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }