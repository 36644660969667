"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var decorators_1 = require("../../decorator/decorators");
var config_1 = require("../../dic/config");
var opposition_repository_1 = require("../../repositories/opposition.repository");
var opposition_model_1 = require("../../model/opposition.model");
var vue_property_decorator_1 = require("vue-property-decorator");
var Filter_vue_1 = require("../filter/Filter.vue");
var Setup_vue_1 = require("./Setup.vue");
var vuex_class_1 = require("vuex-class");
var user_model_1 = require("../../model/user.model");
var layoutModule = vuex_class_1.namespace('layout');
var userModule = vuex_class_1.namespace('user');
var OppositionListComponent = (function (_super) {
    __extends(OppositionListComponent, _super);
    function OppositionListComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.limit = 20;
        return _this;
    }
    OppositionListComponent.prototype.created = function () {
        this.noDataText = this.$tc('oppositions.no_oppositions');
        this.filter = new opposition_repository_1.OppositionFilter();
        this.page = new Number(this.$route.query['page'] || 1).valueOf();
    };
    OppositionListComponent.prototype.mounted = function () {
        this.refreshOppositions();
    };
    OppositionListComponent.prototype.resetFilter = function () {
        this.page = 1;
        this.refreshOppositions();
    };
    OppositionListComponent.prototype.searchFilter = function () {
        this.page = 1;
        this.refreshOppositions();
    };
    OppositionListComponent.prototype.data = function () {
        return {
            oppositions: this.oppositions,
            noDataText: this.noDataText,
            totalCount: this.totalCount,
            page: this.page
        };
    };
    OppositionListComponent.prototype.onPageChanged = function (page, oldPage) {
        if (oldPage) {
            this.refreshOppositions();
            this.$router.push({
                path: this.$route.path,
                query: { page: page.toString() }
            });
        }
    };
    OppositionListComponent.prototype.countPages = function () {
        return Math.ceil(this.totalCount / this.limit);
    };
    OppositionListComponent.prototype.getSentenceAction = function (type) {
        var sentence = '';
        switch (type) {
            case opposition_model_1.OppositionType.DELETE:
                sentence = this.$tc('oppositions.action.delete');
                break;
            default:
                sentence = '';
                break;
        }
        return sentence;
    };
    Object.defineProperty(OppositionListComponent.prototype, "headers", {
        get: function () {
            if (!this.oppositions) {
                return [];
            }
            var head = [
                {
                    text: this.$tc('oppositions.headers.date'),
                    value: 'date',
                    sortable: false
                },
                {
                    text: this.$tc('oppositions.headers.email'),
                    value: 'email',
                    sortable: false
                },
                {
                    text: this.$tc('oppositions.headers.phone_number'),
                    value: 'phoneNumber',
                    sortable: false
                },
                {
                    text: this.$tc('oppositions.headers.action'),
                    value: 'action',
                    sortable: false
                },
                {
                    text: this.$tc('oppositions.headers.sent_date'),
                    value: 'sendingDate',
                    sortable: false
                },
                {
                    text: this.$tc('oppositions.headers.is_sent'),
                    value: 'isSent',
                    sortable: false
                }
            ];
            var hasAtLeastOneContactEmail = this.oppositions.filter(function (opposition) {
                return typeof opposition.emailsSentTo !== 'undefined';
            }).length !== 0;
            if (hasAtLeastOneContactEmail) {
                head = [
                    {
                        text: this.$tc('oppositions.headers.date'),
                        value: 'date',
                        sortable: false
                    },
                    {
                        text: this.$tc('oppositions.headers.email'),
                        value: 'email',
                        sortable: false
                    },
                    {
                        text: this.$tc('oppositions.headers.phone_number'),
                        value: 'phoneNumber',
                        sortable: false
                    },
                    {
                        text: this.$tc('oppositions.headers.action'),
                        value: 'action',
                        sortable: false
                    },
                    {
                        text: this.$tc('oppositions.headers.sent_date'),
                        value: 'sendingDate',
                        sortable: false
                    },
                    {
                        text: this.$tc('oppositions.headers.sent_to'),
                        value: 'emailsSentTo',
                        sortable: false
                    },
                    {
                        text: this.$tc('oppositions.headers.is_sent'),
                        value: 'isSent',
                        sortable: false
                    }
                ];
            }
            return head;
        },
        enumerable: false,
        configurable: true
    });
    OppositionListComponent.prototype.refreshOppositions = function () {
        var _this = this;
        this.showProgressBar();
        var paginate = {
            offset: (this.page - 1) * this.limit,
            limit: this.limit
        };
        this.oppositionRepository
            .getOppositions(this.filter, paginate)
            .then(function (result) {
            _this.hideProgressBar();
            _this.oppositions = result.oppositions;
            _this.totalCount = result.totalCount;
        })
            .catch(function () {
            _this.hideProgressBar();
            _this.noDataText = _this.$tc('errors.generic');
        });
    };
    __decorate([
        userModule.State('user'),
        __metadata("design:type", user_model_1.CurrentUser)
    ], OppositionListComponent.prototype, "user", void 0);
    __decorate([
        decorators_1.Container(config_1.default.REPOSITORY_OPPOSITION),
        __metadata("design:type", opposition_repository_1.OppositionRepository)
    ], OppositionListComponent.prototype, "oppositionRepository", void 0);
    __decorate([
        layoutModule.Action('showProgressBar'),
        __metadata("design:type", Function)
    ], OppositionListComponent.prototype, "showProgressBar", void 0);
    __decorate([
        layoutModule.Action('hideProgressBar'),
        __metadata("design:type", Function)
    ], OppositionListComponent.prototype, "hideProgressBar", void 0);
    __decorate([
        layoutModule.Getter('isProgressBarDisplay'),
        __metadata("design:type", Boolean)
    ], OppositionListComponent.prototype, "progressBar", void 0);
    __decorate([
        vue_property_decorator_1.Watch('page'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number, Number]),
        __metadata("design:returntype", void 0)
    ], OppositionListComponent.prototype, "onPageChanged", null);
    OppositionListComponent = __decorate([
        vue_class_component_1.default({ components: { FilterComponent: Filter_vue_1.default, OppositionSetupComponent: Setup_vue_1.default } })
    ], OppositionListComponent);
    return OppositionListComponent;
}(vue_1.default));
exports.default = OppositionListComponent;
