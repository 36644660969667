var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "8", md: "6" } },
            [
              _c(
                "filter-component",
                {
                  attrs: { filter: _vm.filter },
                  on: { submit: _vm.searchFilter, reset: _vm.resetFilter },
                },
                [
                  _c("campaign-filter-type", { attrs: { filter: _vm.filter } }),
                  _vm._v(" "),
                  _c("campaign-filter-state", {
                    attrs: { filter: _vm.filter },
                  }),
                  _vm._v(" "),
                  _c("campaign-filter-market", {
                    attrs: { filter: _vm.filter },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.campaigns
        ? [
            _c(
              "v-row",
              _vm._l(_vm.campaigns, function (campaign) {
                return _c("campaign-card-component", {
                  key: campaign.uuid,
                  attrs: { campaign: campaign },
                })
              }),
              1
            ),
            _vm._v(" "),
            _c(
              "v-row",
              [
                _c("v-spacer"),
                _vm._v(" "),
                _vm.totalCount
                  ? _c("v-pagination", {
                      attrs: { length: _vm.countPages(), "total-visible": 6 },
                      model: {
                        value: _vm.page,
                        callback: function ($$v) {
                          _vm.page = $$v
                        },
                        expression: "page",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("v-spacer"),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }