var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "v-btn",
        {
          staticClass: "ml-0",
          attrs: { color: "action text--white", dark: "" },
          on: {
            click: function ($event) {
              _vm.state.show = !_vm.state.show
            },
          },
        },
        [
          _c("v-icon", { staticClass: "mx-2" }, [_vm._v("filter_list")]),
          _vm._v(" "),
          _c("span", { staticClass: "mx-2" }, [
            _vm._v(_vm._s(_vm.$t("filters.title"))),
          ]),
          _vm._v(" "),
          _vm.state.show
            ? _c("v-icon", { staticClass: "mx-2" }, [
                _vm._v("keyboard_arrow_down"),
              ])
            : _c("v-icon", { staticClass: "mx-2" }, [
                _vm._v("keyboard_arrow_up"),
              ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.filtered
        ? _c(
            "v-btn",
            {
              attrs: { color: "red", dark: "" },
              on: {
                click: function ($event) {
                  return _vm.resetFilter()
                },
              },
            },
            [
              _vm._v(
                "\n    " + _vm._s(_vm.$t("actions.clear_filters")) + "\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.state.show
        ? _c(
            "v-form",
            {
              staticClass: "mb-2 mx-3",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.searchFilter.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "12" } },
                    [
                      _vm._t("default"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "my-3 mb-3 ml-0",
                          attrs: { type: "submit", color: "primary" },
                        },
                        [_vm._v(_vm._s(_vm.$t("actions.save")))]
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }