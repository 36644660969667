"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var moment = require("moment");
vue_1.default.filter('date', function (date, format) {
    if (!date) {
        return undefined;
    }
    return moment(date).format(format);
});
vue_1.default.filter('gmtdate', function (date) {
    if (!date) {
        return undefined;
    }
    return (moment(date).format('L - LT') + ' (GMT ' + moment(date).format('Z') + ')');
});
vue_1.default.filter('country', function (code) {
    switch (code) {
        case 'en':
        case 'uk':
            return 'gb';
        default:
            return code;
    }
});
vue_1.default.filter('truncate', function (text, length, suffix) {
    if (text && text.length > length) {
        return text.substring(0, length) + suffix;
    }
    else {
        return text;
    }
});
