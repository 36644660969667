"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.I18n = void 0;
var vue_i18n_1 = require("vue-i18n");
var locale_service_1 = require("./locale.service");
var inversify_1 = require("inversify");
var vee_validate_1 = require("vee-validate");
var moment = require("moment");
var fr = require("vee-validate/dist/locale/fr");
var en = require("vee-validate/dist/locale/en");
var es = require("vee-validate/dist/locale/es");
var it = require("vee-validate/dist/locale/it");
var I18n = (function (_super) {
    __extends(I18n, _super);
    function I18n(localeService, options) {
        var _this = _super.call(this, options) || this;
        _this.localeService = localeService;
        _this.locale = localeService.getLocale() || 'en';
        _this.loadValidatorCatalog();
        _this.setMomentLocale(_this.locale);
        return _this;
    }
    I18n.prototype.setLocale = function (locale) {
        this.locale = locale;
        this.localeService.setLocale(locale);
        this.loadValidatorCatalog();
        this.setMomentLocale(locale);
    };
    I18n.prototype.setMomentLocale = function (locale) {
        moment.locale(locale === 'en' ? 'en_GB' : locale);
    };
    I18n.prototype.loadValidatorCatalog = function () {
        switch (this.locale) {
            case 'fr':
                vee_validate_1.Validator.localize(this.locale, fr);
                break;
            case 'en':
                vee_validate_1.Validator.localize(this.locale, en);
                break;
            case 'es':
                vee_validate_1.Validator.localize(this.locale, es);
                break;
            case 'it':
                vee_validate_1.Validator.localize(this.locale, it);
                break;
            default:
                throw new Error('Locale not handled : ' + this.locale);
        }
    };
    I18n = __decorate([
        inversify_1.injectable(),
        __metadata("design:paramtypes", [locale_service_1.LocaleService, Object])
    ], I18n);
    return I18n;
}(vue_i18n_1.default));
exports.I18n = I18n;
