var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "8", md: "6" } },
                [
                  _c(
                    "filter-component",
                    {
                      attrs: {
                        opened: _vm.isFilterOpened(),
                        filter: _vm.filter,
                      },
                      on: { submit: _vm.searchFilter, reset: _vm.resetFilter },
                    },
                    [
                      _c(
                        "v-row",
                        { attrs: { align: "baseline" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "5", md: "5" } },
                            [
                              _c(
                                "v-dialog",
                                {
                                  attrs: {
                                    transition: "scale-transition",
                                    width: "290px",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  value: _vm._f("date")(
                                                    _vm.filter.periodStart,
                                                    "L"
                                                  ),
                                                  required: "",
                                                  readonly: "",
                                                  "hide-details": "",
                                                },
                                              },
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.periodStartDialog,
                                    callback: function ($$v) {
                                      _vm.periodStartDialog = $$v
                                    },
                                    expression: "periodStartDialog",
                                  },
                                },
                                [
                                  _vm._v(" "),
                                  _c("v-date-picker", {
                                    attrs: {
                                      value: _vm._f("date")(
                                        _vm.filter.periodStart,
                                        "YYYY-MM-DD"
                                      ),
                                      locale: _vm.$i18n.locale,
                                    },
                                    on: { input: _vm.setPeriodStart },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-sm-center",
                              attrs: { cols: "12", sm: "2", md: "2" },
                            },
                            [
                              _c("span", { staticClass: "mt-4" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("statistics.period_between"))
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "5", md: "5" } },
                            [
                              _c(
                                "v-dialog",
                                {
                                  attrs: {
                                    transition: "scale-transition",
                                    width: "290px",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-text-field",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  value: _vm._f("date")(
                                                    _vm.filter.periodEnd,
                                                    "L"
                                                  ),
                                                  required: "",
                                                  readonly: "",
                                                  "hide-details": "",
                                                },
                                              },
                                              on
                                            )
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                  model: {
                                    value: _vm.periodEndDialog,
                                    callback: function ($$v) {
                                      _vm.periodEndDialog = $$v
                                    },
                                    expression: "periodEndDialog",
                                  },
                                },
                                [
                                  _vm._v(" "),
                                  _c("v-date-picker", {
                                    attrs: {
                                      value: _vm._f("date")(
                                        _vm.filter.periodEnd,
                                        "YYYY-MM-DD"
                                      ),
                                      locale: _vm.$i18n.locale,
                                      min: _vm.minDateEnd,
                                      max: _vm.maxDateEnd,
                                    },
                                    on: { input: _vm.setPeriodEnd },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("campaign-filter-list", {
                        attrs: { filter: _vm.filter },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.chartData
            ? _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    {
                      staticClass: "font-weight-bold mt-3",
                      staticStyle: { "font-size": "28px" },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("statistics.title")) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    [_c("chart-component", { attrs: { data: _vm.chartData } })],
                    1
                  ),
                ],
                1
              )
            : _vm.isError
            ? _c("p", [_vm._v(_vm._s(_vm.$t("statistics.error_message")))])
            : _c("p", [_vm._v(_vm._s(_vm.$t("statistics.no_campaigns")))]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.campaignStatistics
        ? _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.headers,
                  items: _vm.campaignStatistics,
                  loading: false,
                  "hide-default-footer": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c("tr", [
                            _c(
                              "td",
                              [
                                _c(
                                  "v-row",
                                  { attrs: { align: "center" } },
                                  [
                                    _c("campaign-flag-component", {
                                      staticClass: "mr-2 ml-3",
                                      attrs: { campaign: item.campaign },
                                    }),
                                    _vm._v(" "),
                                    _c("campaign-state-component", {
                                      staticClass: "mr-2",
                                      attrs: { state: item.campaign.state },
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.campaign.name) +
                                        "\n            "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              [
                                _c("campaign-type-component", {
                                  attrs: { campaign: item.campaign, small: "" },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.aggregatedStatistics.countTotal) +
                                  "\n          "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    item.aggregatedStatistics.countValidated
                                  ) +
                                  "\n          "
                              ),
                            ]),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3502845316
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }