"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var Form_vue_1 = require("./Form.vue");
var Title_vue_1 = require("./Title.vue");
var Buttons_vue_1 = require("./Buttons.vue");
var Test_vue_1 = require("./Test.vue");
var Alert_vue_1 = require("./Alert.vue");
var vue_property_decorator_1 = require("vue-property-decorator");
var decorators_1 = require("../../decorator/decorators");
var config_1 = require("../../dic/config");
var campaignFactory_repository_1 = require("../../repositories/campaignFactory.repository");
var CampaignFactoryComponent = (function (_super) {
    __extends(CampaignFactoryComponent, _super);
    function CampaignFactoryComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.sendButtonEnabled = false;
        return _this;
    }
    Object.defineProperty(CampaignFactoryComponent.prototype, "debug", {
        get: function () {
            return this.testResponse ? this.testResponse.debug : null;
        },
        enumerable: false,
        configurable: true
    });
    CampaignFactoryComponent.prototype.data = function () {
        return {
            campaignFactory: this.campaignFactory,
            sendButtonEnabled: this.sendButtonEnabled,
            testResponse: this.testResponse
        };
    };
    CampaignFactoryComponent.prototype.mounted = function () {
        var _this = this;
        this.campaignFactoryType = this.$route.params['type'];
        this.campaignFactoryId = this.$route.params['factoryId'];
        this.campaignUuid = this.$route.params['campaignUuid'];
        if (undefined !== this.campaignFactoryId) {
            this.campaignFactoryRepository
                .get(this.campaignUuid, this.campaignFactoryType, this.campaignFactoryId)
                .then(function (campaignFactory) {
                _this.campaignFactory = campaignFactory;
            })
                .catch(function (response) {
                var errorMessage;
                switch (response.status) {
                    case 400:
                        errorMessage = _this.$tc('errors.bad_request');
                        break;
                    case 401:
                        errorMessage = _this.$tc('errors.login_failed');
                        break;
                    case 404:
                        errorMessage = _this.$tc('errors.factory_or_campaign_not_found');
                        break;
                    default:
                        errorMessage = _this.$tc('errors.generic');
                        break;
                }
                _this.displayError(errorMessage);
            });
        }
        else {
            this.campaignFactory = {
                type: this.campaignFactoryType,
                className: '',
                parameters: {}
            };
        }
    };
    CampaignFactoryComponent.prototype.initializeForm = function () {
        this.enableButton(true);
    };
    CampaignFactoryComponent.prototype.enableButton = function (enable) {
        this.sendButtonEnabled = enable;
    };
    CampaignFactoryComponent.prototype.hideSnacks = function () {
        this.campaignFactoryAlert.hideSnacks();
    };
    CampaignFactoryComponent.prototype.displayError = function (errorMessage) {
        this.campaignFactoryAlert.displayError(errorMessage);
    };
    CampaignFactoryComponent.prototype.displaySuccess = function (successMessage) {
        this.campaignFactoryAlert.displaySuccess(successMessage);
    };
    CampaignFactoryComponent.prototype.displayTestResponse = function (testResponse) {
        this.testResponse = testResponse;
    };
    CampaignFactoryComponent.prototype.submitForm = function () {
        this.hideSnacks();
        this.campaignFactoryForm.send();
    };
    CampaignFactoryComponent.prototype.sendTest = function () {
        var _this = this;
        this.hideSnacks();
        this.campaignFactoryForm.validateAll().then(function (valid) {
            if (!valid) {
                _this.campaignFactoryForm.hideProgressBar();
                _this.displayError(_this.$tc('errors.form_validation_generic'));
            }
            else {
                _this.campaignFactoryTest.sendTest();
            }
        });
    };
    CampaignFactoryComponent.prototype.handleErrorResponse = function (response) {
        this.campaignFactoryForm.handleErrorResponse(response);
    };
    CampaignFactoryComponent.prototype.startSendTest = function () {
        this.testResponse = null;
    };
    CampaignFactoryComponent.prototype.createSuccess = function (campaignFactory) {
        this.displaySuccess(this.$tc('campaign_factory.information.success.create'));
        this.campaignFactory = campaignFactory;
        if (undefined !== this.campaignFactory.id) {
            this.$router.push({
                name: 'CampaignLiveRequestEdit',
                params: {
                    campaignUuid: this.campaignUuid,
                    factoryId: this.campaignFactory.id.toString(),
                    type: this.campaignFactory.type
                }
            });
        }
    };
    var _a, _b, _c;
    __decorate([
        decorators_1.Container(config_1.default.REPOSITORY_CAMPAIGN_FACTORY),
        __metadata("design:type", campaignFactory_repository_1.CampaignFactoryRepository)
    ], CampaignFactoryComponent.prototype, "campaignFactoryRepository", void 0);
    __decorate([
        vue_property_decorator_1.Ref(),
        __metadata("design:type", typeof (_a = typeof Form_vue_1.default !== "undefined" && Form_vue_1.default) === "function" ? _a : Object)
    ], CampaignFactoryComponent.prototype, "campaignFactoryForm", void 0);
    __decorate([
        vue_property_decorator_1.Ref(),
        __metadata("design:type", typeof (_b = typeof Test_vue_1.default !== "undefined" && Test_vue_1.default) === "function" ? _b : Object)
    ], CampaignFactoryComponent.prototype, "campaignFactoryTest", void 0);
    __decorate([
        vue_property_decorator_1.Ref(),
        __metadata("design:type", typeof (_c = typeof Alert_vue_1.default !== "undefined" && Alert_vue_1.default) === "function" ? _c : Object)
    ], CampaignFactoryComponent.prototype, "campaignFactoryAlert", void 0);
    CampaignFactoryComponent = __decorate([
        vue_class_component_1.default({
            components: {
                CampaignFactoryFormComponent: Form_vue_1.default,
                CampaignFactoryTitleComponent: Title_vue_1.default,
                CampaignFactoryButtonsComponent: Buttons_vue_1.default,
                CampaignFactoryTestComponent: Test_vue_1.default,
                CampaignFactoryAlertComponent: Alert_vue_1.default
            }
        })
    ], CampaignFactoryComponent);
    return CampaignFactoryComponent;
}(vue_1.default));
exports.default = CampaignFactoryComponent;
