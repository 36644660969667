"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_property_decorator_1 = require("vue-property-decorator");
var campaign_model_1 = require("../../model/campaign.model");
var StateComponent = (function (_super) {
    __extends(StateComponent, _super);
    function StateComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StateComponent.prototype.isRunning = function () {
        return this.state === campaign_model_1.CampaignStatusEnum.RUNNING;
    };
    Object.defineProperty(StateComponent.prototype, "stateIcon", {
        get: function () {
            var icon = '';
            switch (this.state) {
                case campaign_model_1.CampaignStatusEnum.RUNNING:
                    icon = 'play_circle_filled';
                    break;
                case campaign_model_1.CampaignStatusEnum.PAUSED:
                    icon = 'pause_circle_filled';
                    break;
                case campaign_model_1.CampaignStatusEnum.OBJECTIVE_REACHED:
                    icon = 'offline_pin';
                    break;
                case campaign_model_1.CampaignStatusEnum.FINISHED:
                    icon = 'stop';
                    break;
                case campaign_model_1.CampaignStatusEnum.CREATING:
                    icon = 'settings';
                    break;
            }
            return icon;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        vue_property_decorator_1.Prop(),
        __metadata("design:type", String)
    ], StateComponent.prototype, "state", void 0);
    __decorate([
        vue_property_decorator_1.Prop(),
        __metadata("design:type", Boolean)
    ], StateComponent.prototype, "large", void 0);
    StateComponent = __decorate([
        vue_class_component_1.default
    ], StateComponent);
    return StateComponent;
}(vue_1.default));
exports.default = StateComponent;
