var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "800px" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "primary white--text" }, [
            _c("span", { staticClass: "text-h5" }, [
              _vm._v(_vm._s(_vm.$t("logout.title"))),
            ]),
          ]),
          _vm._v(" "),
          _c("v-card-text", [
            _vm.hasExpired
              ? _c("p", { staticClass: "font-weight-bold" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("logout.expired")) + "\n      "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("p", { staticClass: "font-weight-bold" }, [
              _vm._v(_vm._s(_vm.$t("logout.text"))),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }