"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var decorators_1 = require("../../decorator/decorators");
var LogoutComponent = (function (_super) {
    __extends(LogoutComponent, _super);
    function LogoutComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.dialog = true;
        _this.hasExpired = false;
        return _this;
    }
    LogoutComponent.prototype.mounted = function () {
        if (this.$route.query.expired) {
            this.hasExpired = true;
        }
        if (this.environment === 'test') {
            return;
        }
        window.setTimeout(function () {
            window.location.assign('https://www.optincollect.com/fr?connect=');
        }, 1000);
    };
    __decorate([
        decorators_1.Container('environment'),
        __metadata("design:type", String)
    ], LogoutComponent.prototype, "environment", void 0);
    LogoutComponent = __decorate([
        vue_class_component_1.default
    ], LogoutComponent);
    return LogoutComponent;
}(vue_1.default));
exports.default = LogoutComponent;
