"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.layoutModule = void 0;
exports.layoutModule = {
    namespaced: true,
    state: {
        progressBar: false
    },
    getters: {
        isProgressBarDisplay: function (state) {
            return state.progressBar;
        }
    },
    mutations: {
        setProgress: function (state, value) {
            state.progressBar = value;
        }
    },
    actions: {
        showProgressBar: function (_a) {
            var commit = _a.commit;
            commit('setProgress', true);
        },
        hideProgressBar: function (_a) {
            var commit = _a.commit;
            commit('setProgress', false);
        }
    }
};
