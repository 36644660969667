"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.collectedDataCodesUrl = exports.CollectedDataEnum = void 0;
var CollectedDataEnum;
(function (CollectedDataEnum) {
    CollectedDataEnum["EMAIL"] = "email";
    CollectedDataEnum["COOKIUID"] = "cookieUid";
    CollectedDataEnum["IP"] = "ip";
    CollectedDataEnum["FIRSTNAME"] = "firstname";
    CollectedDataEnum["LASTNAME"] = "lastname";
    CollectedDataEnum["ZIPCODE"] = "zipcode";
    CollectedDataEnum["CITY"] = "city";
    CollectedDataEnum["ADDRESS"] = "address";
    CollectedDataEnum["PHONE"] = "phone";
    CollectedDataEnum["COUNTRY"] = "country";
    CollectedDataEnum["CIVILITY"] = "civility";
    CollectedDataEnum["BIRTHDAY"] = "birthday";
    CollectedDataEnum["COMPLEX"] = "complex";
    CollectedDataEnum["REFERER"] = "referer";
    CollectedDataEnum["PROFILING"] = "profiling";
})(CollectedDataEnum = exports.CollectedDataEnum || (exports.CollectedDataEnum = {}));
exports.collectedDataCodesUrl = [
    'email_md5',
    'email_user',
    'email_domain',
    'email',
    'civility',
    'firstname',
    'lastname',
    'address',
    'zipcode',
    'city',
    'phone',
    'phone_md5',
    'mobile',
    'birthday',
    'gender',
    'user_agent',
    'country',
    'complex',
    'custom1',
    'custom2',
    'referer',
    'lang',
    'custom_spl',
    'ip_address',
    'cookie_uid',
    'first_phones_unchecked'
];
