var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      staticClass: "mr-2",
      attrs: { "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g({ attrs: { text: "" } }, on),
                [
                  _c("flag", {
                    staticClass: "mr-2",
                    attrs: { iso: _vm._f("country")(_vm.$i18n.locale) },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "hidden-xs-only" }, [
                    _vm._v(_vm._s(_vm.currentLocaleText)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-icon",
                    { staticClass: "hidden-xs-only", attrs: { right: "" } },
                    [_vm._v("keyboard_arrow_down")]
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "v-list",
        _vm._l(_vm.locales, function (locale) {
          return _c(
            "v-list-item",
            {
              key: locale.value,
              on: {
                click: function ($event) {
                  return _vm.setLocale(locale.value)
                },
              },
            },
            [
              _c(
                "v-list-item-title",
                [
                  _c("flag", {
                    staticClass: "mr-2",
                    attrs: { iso: _vm._f("country")(locale.value) },
                  }),
                  _vm._v("\n        " + _vm._s(locale.text)),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }