"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("@mdi/font/css/materialdesignicons.css");
var vue_1 = require("vue");
var lib_1 = require("vuetify/lib");
vue_1.default.use(lib_1.default);
exports.default = new lib_1.default({
    icons: {
        iconfont: 'mdi'
    },
    theme: {
        themes: {
            light: {
                primary: '#3F51B5',
                secondary: '#880E4F',
                action: '#424242',
                accent: '#ffeb3b',
                error: '#ff5722',
                warning: '#ffc107',
                info: '#009688',
                success: '#4CAF50'
            }
        }
    }
});
