"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Conversion = void 0;
var Conversion = (function () {
    function Conversion(data) {
        this.date = new Date(data.date);
        this.uid = data.uid;
        this.hasCookieUid = data.hasCookieUid;
        this.isSupportLocationTest = data.isSupportLocationTest;
        this.adId = data.adId;
        this.supportLocationId = data.supportLocationId;
        this.paybackMode = data.paybackMode;
    }
    return Conversion;
}());
exports.Conversion = Conversion;
