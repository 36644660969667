"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var decorators_1 = require("../../decorator/decorators");
var config_1 = require("../../dic/config");
var tagtracking_repository_1 = require("../../repositories/tagtracking.repository");
var Flag_vue_1 = require("../campaign/Flag.vue");
var State_vue_1 = require("../campaign/State.vue");
var vuex_class_1 = require("vuex-class");
var campaign_repository_1 = require("../../repositories/campaign.repository");
var access_token_storage_1 = require("../../services/login/access_token.storage");
var layoutModule = vuex_class_1.namespace('layout');
var TagTrackingListComponent = (function (_super) {
    __extends(TagTrackingListComponent, _super);
    function TagTrackingListComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.error = false;
        _this.forbidden = false;
        return _this;
    }
    TagTrackingListComponent.prototype.data = function () {
        return {
            campaign: this.campaign,
            conversions: this.conversions
        };
    };
    TagTrackingListComponent.prototype.mounted = function () {
        var _this = this;
        this.showProgressBar();
        var campaignUuid = this.$route.params.campaignUuid;
        var token = this.$route.params.token;
        this.campaignRepository
            .getCampaign(campaignUuid, token)
            .then(function (campaign) {
            _this.campaign = campaign;
            _this.tagTrackingRepository
                .getConversions(campaignUuid, token)
                .then(function (conversions) {
                _this.conversions = conversions;
                _this.hideProgressBar();
            })
                .catch(function (response) {
                if (response.status === 404) {
                    _this.error = true;
                }
                else if (response.status === 403) {
                    _this.forbidden = true;
                }
                _this.hideProgressBar();
            });
        })
            .catch(function (response) {
            if (response.status === 404) {
                _this.error = true;
            }
            else if (response.status === 403) {
                _this.forbidden = true;
            }
            _this.hideProgressBar();
        });
    };
    Object.defineProperty(TagTrackingListComponent.prototype, "headers", {
        get: function () {
            var headers = [
                {
                    text: this.$tc('conversions.headers.date'),
                    value: 'date',
                    sortable: false
                },
                {
                    text: 'UID',
                    value: 'uid',
                    sortable: false
                },
                {
                    text: this.$tc('conversions.headers.cookie'),
                    value: 'cookie',
                    sortable: false
                },
                {
                    text: this.$tc('conversions.headers.link_test'),
                    value: 'link_test',
                    sortable: false
                },
                {
                    text: this.$tc('conversions.headers.errors'),
                    value: 'errors',
                    sortable: false
                }
            ];
            if (this.accessTokenStorage.hasImpersonatedUserId()) {
                headers = headers.concat([
                    {
                        text: this.$tc('conversions.headers.ad_id'),
                        value: 'adId',
                        sortable: false
                    },
                    {
                        text: this.$tc('conversions.headers.payback_mode'),
                        value: 'paybackMode',
                        sortable: false
                    },
                    {
                        text: this.$tc('conversions.headers.support_location_id'),
                        value: 'supportLocationId',
                        sortable: false
                    }
                ]);
            }
            return headers;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        layoutModule.Action('showProgressBar'),
        __metadata("design:type", Function)
    ], TagTrackingListComponent.prototype, "showProgressBar", void 0);
    __decorate([
        layoutModule.Action('hideProgressBar'),
        __metadata("design:type", Function)
    ], TagTrackingListComponent.prototype, "hideProgressBar", void 0);
    __decorate([
        decorators_1.Container(config_1.default.REPOSITORY_CONVERSION),
        __metadata("design:type", tagtracking_repository_1.TagTrackingRepository)
    ], TagTrackingListComponent.prototype, "tagTrackingRepository", void 0);
    __decorate([
        decorators_1.Container(config_1.default.REPOSITORY_CAMPAIGN),
        __metadata("design:type", campaign_repository_1.CampaignRepository)
    ], TagTrackingListComponent.prototype, "campaignRepository", void 0);
    __decorate([
        decorators_1.Container(config_1.default.ACCESS_TOKEN_STORAGE),
        __metadata("design:type", access_token_storage_1.AccessTokenStorage)
    ], TagTrackingListComponent.prototype, "accessTokenStorage", void 0);
    TagTrackingListComponent = __decorate([
        vue_class_component_1.default({
            components: { CampaignFlagComponent: Flag_vue_1.default, CampaignStateComponent: State_vue_1.default }
        })
    ], TagTrackingListComponent);
    return TagTrackingListComponent;
}(vue_1.default));
exports.default = TagTrackingListComponent;
