"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var campaign_model_1 = require("../../model/campaign.model");
var decorators_1 = require("../../decorator/decorators");
var config_1 = require("../../dic/config");
var campaign_repository_1 = require("../../repositories/campaign.repository");
var optin_client_1 = require("@riverline/optin-client");
var market_model_1 = require("../../model/market.model");
var vue_property_decorator_1 = require("vue-property-decorator");
var CoregistrationBatComponent = (function (_super) {
    __extends(CoregistrationBatComponent, _super);
    function CoregistrationBatComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CoregistrationBatComponent.prototype.onThemeChange = function (newTheme, oldThemeNumber) {
        var oldTheme = this.themes[oldThemeNumber];
        var theme = this.themes[newTheme];
        if (oldTheme) {
            this.cleanOldRender(this.themes[oldThemeNumber]);
        }
        this.getAdAndRender(theme);
    };
    CoregistrationBatComponent.prototype.data = function () {
        return {
            currentTheme: this.currentTheme,
            themes: this.themes,
            counter: 0
        };
    };
    CoregistrationBatComponent.prototype.mounted = function () {
        this.themes = [
            {
                isRendered: true,
                name: optin_client_1.OptinCollect.Coregistration.Themes.DEFAULT,
                hasError: false
            },
            {
                isRendered: false,
                name: optin_client_1.OptinCollect.Coregistration.Themes.MINI,
                hasError: false
            },
            {
                isRendered: false,
                name: optin_client_1.OptinCollect.Coregistration.Themes.FULL,
                hasError: false
            }
        ];
        this.getAdAndRender(this.themes[0]);
    };
    CoregistrationBatComponent.prototype.cleanOldRender = function (oldTheme) {
        var oldBatTheme = document.querySelector("#bat-" + oldTheme.name);
        if (!oldBatTheme) {
            return;
        }
        var oldRender = oldBatTheme.querySelector('.webrivage-optin-client');
        if (!oldRender) {
            return;
        }
        oldBatTheme.removeChild(oldRender);
        var div = document.createElement('div');
        div.id = oldTheme.name;
        oldBatTheme.append(div);
    };
    CoregistrationBatComponent.prototype.getAdAndRender = function (theme) {
        var locale;
        switch (this.campaign.market) {
            case market_model_1.MarketEnum.IT:
                locale = 'it_IT';
                break;
            case market_model_1.MarketEnum.ES:
                locale = 'es_ES';
                break;
            default:
                locale = 'fr_FR';
        }
        optin_client_1.OptinCollect.Configuration.setParameter(optin_client_1.OptinCollect.ParameterEnum.Locale, locale);
        optin_client_1.OptinCollect.Configuration.setParameter(optin_client_1.OptinCollect.ParameterEnum.DataSource, 'memory');
        var configuration = new optin_client_1.OptinCollect.Coregistration.Configuration(945, [theme.name], 'application-tracking');
        configuration.theme = theme.name;
        if (theme.name !== optin_client_1.OptinCollect.Coregistration.Themes.DEFAULT) {
            configuration.single = true;
        }
        var data = new optin_client_1.OptinCollect.CollectedData();
        this.campaignRepository.getAd(this.campaign.uuid, this.token).then(function (ad) {
            optin_client_1.OptinCollect.InMemoryRepository.setAds([ad]);
            var coregRenderer = new optin_client_1.OptinCollect.Coregistration.Renderer(configuration, data);
            coregRenderer.addEventListener('render', function (e) {
                theme.hasError = e.detail.ads.length === 0;
                theme.isRendered = true;
            });
            coregRenderer.render();
        });
    };
    __decorate([
        decorators_1.Container(config_1.default.REPOSITORY_CAMPAIGN),
        __metadata("design:type", campaign_repository_1.CampaignRepository)
    ], CoregistrationBatComponent.prototype, "campaignRepository", void 0);
    __decorate([
        vue_property_decorator_1.Prop(),
        __metadata("design:type", campaign_model_1.Campaign)
    ], CoregistrationBatComponent.prototype, "campaign", void 0);
    __decorate([
        vue_property_decorator_1.Prop(),
        __metadata("design:type", Object)
    ], CoregistrationBatComponent.prototype, "token", void 0);
    __decorate([
        vue_property_decorator_1.Watch('currentTheme'),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number, Number]),
        __metadata("design:returntype", void 0)
    ], CoregistrationBatComponent.prototype, "onThemeChange", null);
    CoregistrationBatComponent = __decorate([
        vue_class_component_1.default({})
    ], CoregistrationBatComponent);
    return CoregistrationBatComponent;
}(vue_1.default));
exports.default = CoregistrationBatComponent;
