var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c("div", [
        _c("h1", [_vm._v("Mes informations")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [_vm._v("Email : " + _vm._s(_vm.user.email))]),
          _vm._v(" "),
          _c(
            "li",
            [
              _vm._v("\n      Mot de passe :\n      "),
              _c("router-link", { attrs: { to: "/account/password" } }, [
                _vm._v("Changer le mot de passe"),
              ]),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("h2", [_vm._v("Société")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [
            _vm._v("Nom de la société : " + _vm._s(_vm.user.company.name)),
          ]),
          _vm._v(" "),
          _c("li", [_vm._v("RCS : " + _vm._s(_vm.user.company.rcs))]),
          _vm._v(" "),
          _c("li", [_vm._v("TVA Intra : " + _vm._s(_vm.user.company.tva))]),
          _vm._v(" "),
          _c("li", [_vm._v("Adresse : " + _vm._s(_vm.user.company.address))]),
        ]),
        _vm._v(" "),
        _c("h2", [_vm._v("Contact")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [_vm._v("Contact : " + _vm._s(_vm.user.contact.name))]),
          _vm._v(" "),
          _c("li", [_vm._v("Fonction : " + _vm._s(_vm.user.contact.job))]),
          _vm._v(" "),
          _c("li", [_vm._v("Email : " + _vm._s(_vm.user.contact.email))]),
          _vm._v(" "),
          _c("li", [_vm._v("Téléphone : " + _vm._s(_vm.user.contact.phone))]),
          _vm._v(" "),
          _c("li", [_vm._v("Langue : " + _vm._s(_vm.user.contact.language))]),
        ]),
        _vm._v(" "),
        _c("h2", [_vm._v("Représentant légal")]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [_vm._v("Contact : " + _vm._s(_vm.user.legalContact.name))]),
          _vm._v(" "),
          _c("li", [_vm._v("Fonction : " + _vm._s(_vm.user.legalContact.job))]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }