"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var user_model_1 = require("../../model/user.model");
var vuex_class_1 = require("vuex-class");
var decorators_1 = require("../../decorator/decorators");
var config_1 = require("../../dic/config");
var user_repository_1 = require("../../repositories/user.repository");
var CreateEdit_vue_1 = require("./CreateEdit.vue");
var Delete_vue_1 = require("./Delete.vue");
var userModule = vuex_class_1.namespace('user');
var layoutModule = vuex_class_1.namespace('layout');
var UserListComponent = (function (_super) {
    __extends(UserListComponent, _super);
    function UserListComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.createModalState = { show: false };
        return _this;
    }
    UserListComponent.prototype.created = function () {
        this.noDataText = this.$tc('loading');
    };
    UserListComponent.prototype.mounted = function () {
        this.refreshUsers();
    };
    UserListComponent.prototype.refreshUsers = function () {
        var _this = this;
        this.showProgressBar();
        this.userRepository
            .getUsers()
            .then(function (users) {
            _this.hideProgressBar();
            _this.users = users;
        })
            .catch(function () {
            _this.hideProgressBar();
            _this.noDataText = _this.$tc('errors.generic');
        });
    };
    UserListComponent.prototype.data = function () {
        return {
            users: this.users,
            noDataText: this.noDataText
        };
    };
    Object.defineProperty(UserListComponent.prototype, "headers", {
        get: function () {
            return [
                {
                    text: this.$tc('users.headers.email'),
                    value: 'email',
                    sortable: false
                },
                { text: this.$tc('users.headers.name'), value: 'name', sortable: false },
                {
                    text: this.$tc('users.headers.accesses'),
                    value: 'roles',
                    sortable: false
                },
                {
                    text: this.$tc('users.headers.last_logged'),
                    value: 'last_logged',
                    sortable: false
                },
                {
                    text: this.$tc('users.headers.state'),
                    value: 'state',
                    sortable: false
                },
                {
                    text: this.$tc('users.headers.actions'),
                    value: 'actions',
                    sortable: false,
                    width: 150
                }
            ];
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        userModule.State('user'),
        __metadata("design:type", user_model_1.CurrentUser)
    ], UserListComponent.prototype, "user", void 0);
    __decorate([
        layoutModule.Action('showProgressBar'),
        __metadata("design:type", Function)
    ], UserListComponent.prototype, "showProgressBar", void 0);
    __decorate([
        layoutModule.Action('hideProgressBar'),
        __metadata("design:type", Function)
    ], UserListComponent.prototype, "hideProgressBar", void 0);
    __decorate([
        layoutModule.Getter('isProgressBarDisplay'),
        __metadata("design:type", Boolean)
    ], UserListComponent.prototype, "progressBar", void 0);
    __decorate([
        decorators_1.Container(config_1.default.REPOSITORY_USER),
        __metadata("design:type", user_repository_1.UserRepository)
    ], UserListComponent.prototype, "userRepository", void 0);
    UserListComponent = __decorate([
        vue_class_component_1.default({
            components: { CreateEditUserComponent: CreateEdit_vue_1.default, DeleteUserComponent: Delete_vue_1.default }
        })
    ], UserListComponent);
    return UserListComponent;
}(vue_1.default));
exports.default = UserListComponent;
