"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var vue_property_decorator_1 = require("vue-property-decorator");
var campaign_model_1 = require("../../model/campaign.model");
var vuex_class_1 = require("vuex-class");
var user_model_1 = require("../../model/user.model");
var Export_vue_1 = require("./Export.vue");
var Flag_vue_1 = require("./Flag.vue");
var State_vue_1 = require("./State.vue");
var Type_vue_1 = require("./Type.vue");
var userModule = vuex_class_1.namespace('user');
var CardComponent = (function (_super) {
    __extends(CardComponent, _super);
    function CardComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(CardComponent.prototype, "collectedData", {
        get: function () {
            var _this = this;
            return this.campaign.collectedData.map(function (collectedDatum) {
                return _this.$i18n
                    .t('campaigns.collectedData.items.' + collectedDatum)
                    .toString();
            });
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        userModule.State('user'),
        __metadata("design:type", user_model_1.CurrentUser)
    ], CardComponent.prototype, "user", void 0);
    __decorate([
        vue_property_decorator_1.Prop(),
        __metadata("design:type", campaign_model_1.Campaign)
    ], CardComponent.prototype, "campaign", void 0);
    CardComponent = __decorate([
        vue_class_component_1.default({
            components: {
                ExportLeadsComponent: Export_vue_1.default,
                CampaignFlagComponent: Flag_vue_1.default,
                CampaignStateComponent: State_vue_1.default,
                CampaignTypeComponent: Type_vue_1.default
            }
        })
    ], CardComponent);
    return CardComponent;
}(vue_1.default));
exports.default = CardComponent;
