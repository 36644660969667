"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CampaignRepository = exports.CampaignFilter = void 0;
var inversify_1 = require("inversify");
var config_1 = require("../dic/config");
var client_service_1 = require("../services/client.service");
var campaign_model_1 = require("../model/campaign.model");
var CampaignFilter = (function () {
    function CampaignFilter() {
        this.types = [];
        this.states = [];
        this.markets = [];
    }
    CampaignFilter.prototype.isFiltered = function () {
        return !(this.states.length === 0 &&
            this.markets.length === 0 &&
            this.types.length === 0);
    };
    CampaignFilter.prototype.reset = function () {
        this.states = [];
        this.markets = [];
        this.types = [];
    };
    return CampaignFilter;
}());
exports.CampaignFilter = CampaignFilter;
var CampaignRepository = (function () {
    function CampaignRepository(client) {
        this.client = client;
    }
    CampaignRepository.prototype.getCampaigns = function (paginate, filter, sort) {
        return __awaiter(this, void 0, void 0, function () {
            var data, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        data = {
                            offset: paginate ? paginate.offset : undefined,
                            limit: paginate ? paginate.limit : undefined,
                            types: filter ? filter.types : undefined,
                            states: filter ? filter.states : undefined,
                            markets: filter ? filter.markets : undefined,
                            sort: sort ? sort.sort : undefined,
                            order: sort ? sort.order : undefined
                        };
                        return [4, this.client.get({
                                endpoint: '/campaigns',
                                data: data,
                                credentials: true
                            })];
                    case 1:
                        response = _a.sent();
                        return [2, {
                                campaigns: response.data.map(function (data) { return new campaign_model_1.ListCampaign(data); }),
                                totalCount: Number(response.headers.get('X-Total-Count'))
                            }];
                }
            });
        });
    };
    CampaignRepository.prototype.getCampaign = function (uuid, accessToken) {
        return __awaiter(this, void 0, void 0, function () {
            var headers, credentials, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (undefined !== accessToken) {
                            headers = new Headers({ 'X-Access-Token': accessToken });
                            credentials = false;
                        }
                        else {
                            credentials = true;
                        }
                        return [4, this.client.get({
                                endpoint: '/campaigns/' + uuid,
                                headers: headers,
                                credentials: credentials
                            })];
                    case 1:
                        response = _a.sent();
                        return [2, new campaign_model_1.Campaign(response.data)];
                }
            });
        });
    };
    CampaignRepository.prototype.getAd = function (campaignUuid, accessToken) {
        return __awaiter(this, void 0, void 0, function () {
            var headers, credentials, response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (undefined !== accessToken) {
                            headers = new Headers({ 'X-Access-Token': accessToken });
                            credentials = false;
                        }
                        else {
                            credentials = true;
                        }
                        return [4, this.client.get({
                                endpoint: '/campaigns/' + campaignUuid + '/ads',
                                headers: headers,
                                credentials: credentials
                            })];
                    case 1:
                        response = _a.sent();
                        return [2, response.data];
                }
            });
        });
    };
    CampaignRepository = __decorate([
        inversify_1.injectable(),
        __param(0, inversify_1.inject(config_1.default.SERVICE_CLIENT)),
        __metadata("design:paramtypes", [client_service_1.ClientService])
    ], CampaignRepository);
    return CampaignRepository;
}());
exports.CampaignRepository = CampaignRepository;
