"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var LocaleSwitcher_vue_1 = require("./components/locale/LocaleSwitcher.vue");
var Settings_vue_1 = require("./components/layout/Settings.vue");
var vuex_class_1 = require("vuex-class");
var user_model_1 = require("./model/user.model");
var Progress_vue_1 = require("./components/layout/Progress.vue");
var userModule = vuex_class_1.namespace('user');
var layoutModule = vuex_class_1.namespace('layout');
var App = (function (_super) {
    __extends(App, _super);
    function App() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.drawer = true;
        return _this;
    }
    Object.defineProperty(App.prototype, "displayMenu", {
        get: function () {
            return (this.user &&
                (this.user.hasRoleLimited() ||
                    this.user.hasRoleDPO() ||
                    this.user.hasRoleAdmin() ||
                    this.user.hasRoleLimitedOpposition()));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(App.prototype, "currentYear", {
        get: function () {
            return new Date().getFullYear();
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        userModule.Getter('getUser'),
        __metadata("design:type", user_model_1.CurrentUser)
    ], App.prototype, "user", void 0);
    __decorate([
        layoutModule.Getter('isProgressBarDisplay'),
        __metadata("design:type", Boolean)
    ], App.prototype, "progressBar", void 0);
    App = __decorate([
        vue_class_component_1.default({
            components: { LocaleSwitcherComponent: LocaleSwitcher_vue_1.default, SettingsComponent: Settings_vue_1.default, ProgressComponent: Progress_vue_1.default }
        })
    ], App);
    return App;
}(vue_1.default));
exports.default = App;
