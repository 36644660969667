import { render, staticRenderFns } from "./Forbidden.vue?vue&type=template&id=aaf82bb6&"
import script from "./Forbidden.vue?vue&type=script&lang=ts&"
export * from "./Forbidden.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/builds/riverline/optincollect-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('aaf82bb6')) {
      api.createRecord('aaf82bb6', component.options)
    } else {
      api.reload('aaf82bb6', component.options)
    }
    module.hot.accept("./Forbidden.vue?vue&type=template&id=aaf82bb6&", function () {
      api.rerender('aaf82bb6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/user/Forbidden.vue"
export default component.exports