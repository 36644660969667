"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var decorators_1 = require("../../decorator/decorators");
var config_1 = require("../../dic/config");
var campaign_repository_1 = require("../../repositories/campaign.repository");
var Export_vue_1 = require("./Export.vue");
var moment = require("moment");
var ExportWrapperComponent = (function (_super) {
    __extends(ExportWrapperComponent, _super);
    function ExportWrapperComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.error = false;
        return _this;
    }
    ExportWrapperComponent.prototype.mounted = function () {
        var _this = this;
        var campaignUuid = this.$route.params['campaignUuid'];
        this.campaignRepository
            .getCampaign(campaignUuid)
            .then(function (campaign) {
            _this.campaign = campaign;
            var startDate = moment(_this.$route.params['dateStart']);
            var endDate = moment(_this.$route.params['dateEnd']);
            var yesterday = moment().subtract(1, 'days');
            if (!startDate.isValid() || startDate.isAfter(yesterday)) {
                startDate = yesterday;
            }
            if (!endDate.isValid() || endDate.isAfter(yesterday)) {
                endDate = yesterday;
            }
            _this.model = {
                dateStart: startDate.toDate(),
                dateEnd: endDate.toDate(),
                state: _this.$route.params['state'] === '1' ? true : false
            };
        })
            .catch(function (response) {
            if (response.status === 404) {
                _this.error = true;
            }
        });
    };
    ExportWrapperComponent.prototype.data = function () {
        return {
            campaign: this.campaign,
            model: this.model
        };
    };
    __decorate([
        decorators_1.Container(config_1.default.REPOSITORY_CAMPAIGN),
        __metadata("design:type", campaign_repository_1.CampaignRepository)
    ], ExportWrapperComponent.prototype, "campaignRepository", void 0);
    ExportWrapperComponent = __decorate([
        vue_class_component_1.default({ components: { ExportLeadsComponent: Export_vue_1.default } })
    ], ExportWrapperComponent);
    return ExportWrapperComponent;
}(vue_1.default));
exports.default = ExportWrapperComponent;
