var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("flag", {
    attrs: { squared: false, iso: _vm._f("country")(_vm.campaign.market) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }