"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.RoleEnum = exports.CurrentUser = exports.ListUser = exports.User = void 0;
var User = (function () {
    function User(data) {
        this.id = data.id;
        this.email = data.email;
        this.firstname = data.firstname || '';
        this.lastname = data.lastname || '';
        this.roles = data.roles;
    }
    User.prototype.getFullName = function () {
        return this.firstname + ' ' + this.lastname;
    };
    User.prototype.hasRoleDPO = function () {
        return this.hasRole(RoleEnum.DPO);
    };
    User.prototype.hasRoleAdmin = function () {
        return this.hasRole(RoleEnum.ADMIN);
    };
    User.prototype.hasRoleLimited = function () {
        return this.hasRole(RoleEnum.LIMITED);
    };
    User.prototype.hasRoleLimitedOpposition = function () {
        return this.hasRole(RoleEnum.LIMITED_OPPOSITION);
    };
    User.prototype.hasRole = function (role) {
        return this.roles.indexOf(role) > -1;
    };
    User.prototype.getMainRole = function () {
        var _this = this;
        var filteredRoles = [
            RoleEnum.ADMIN,
            RoleEnum.DPO,
            RoleEnum.LIMITED,
            RoleEnum.LIMITED_OPPOSITION
        ].filter(function (role) {
            return _this.hasRole(role);
        });
        return filteredRoles.length > 0 ? filteredRoles[0] : RoleEnum.LIMITED;
    };
    return User;
}());
exports.User = User;
var ListUser = (function (_super) {
    __extends(ListUser, _super);
    function ListUser(data) {
        var _this = _super.call(this, data) || this;
        _this.lastConnectedAt = data.lastConnectedAt
            ? new Date(data.lastConnectedAt)
            : null;
        _this.enabled = data.enabled;
        return _this;
    }
    return ListUser;
}(User));
exports.ListUser = ListUser;
var CurrentUser = (function (_super) {
    __extends(CurrentUser, _super);
    function CurrentUser(data) {
        var _this = _super.call(this, data) || this;
        _this.company = data.company;
        _this.legalContact = data.legalContact;
        _this.impersonated = data.impersonated;
        return _this;
    }
    return CurrentUser;
}(User));
exports.CurrentUser = CurrentUser;
var RoleEnum;
(function (RoleEnum) {
    RoleEnum["ADMIN"] = "ROLE_ADVERTISER_ADMIN";
    RoleEnum["DPO"] = "ROLE_ADVERTISER_DPO";
    RoleEnum["LIMITED"] = "ROLE_ADVERTISER_LIMITED";
    RoleEnum["LIMITED_OPPOSITION"] = "ROLE_ADVERTISER_LIMITED_OPPOSITION";
})(RoleEnum = exports.RoleEnum || (exports.RoleEnum = {}));
