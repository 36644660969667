"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocaleService = void 0;
var inversify_1 = require("inversify");
var LocaleService = (function () {
    function LocaleService() {
        this.availableLocales = ['fr', 'it', 'es', 'en'];
    }
    LocaleService.prototype.getLocale = function () {
        var locale = window.localStorage.getItem('locale') ||
            window.navigator.language.slice(0, 2);
        if (this.availableLocales.indexOf(locale) < 0) {
            locale = 'en';
        }
        return locale;
    };
    LocaleService.prototype.setLocale = function (locale) {
        window.localStorage.setItem('locale', locale);
    };
    LocaleService = __decorate([
        inversify_1.injectable()
    ], LocaleService);
    return LocaleService;
}());
exports.LocaleService = LocaleService;
