var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        { attrs: { justify: "center", align: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-form",
                    {
                      staticClass: "pa-4",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.setPassword.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "text-body-2 mb-0 primary--text text-center",
                            },
                            [
                              _c("span", { staticClass: "text-h6" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("users.reset_password.title"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c("br"),
                              _vm._v(" "),
                              _c("span", { staticClass: "subheading" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("users.reset_password.headline")
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-alert",
                            {
                              attrs: {
                                color: "error",
                                value: _vm.globalError ? true : false,
                                icon: "warning",
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.globalError) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: { required: true, min: 8, max: 20 },
                                expression:
                                  "{ required: true, min: 8, max: 20 }",
                              },
                            ],
                            attrs: {
                              "append-icon": _vm.passwordVisibility
                                ? "visibility_off"
                                : "visibility",
                              "append-icon-cb": function () {
                                return (_vm.passwordVisibility =
                                  !_vm.passwordVisibility)
                              },
                              type: _vm.passwordVisibility
                                ? "text"
                                : "password",
                              placeholder: _vm.$t(
                                "users.reset_password.password"
                              ),
                              required: "",
                              name: "password",
                              "error-messages": _vm.errors.has("password")
                                ? [_vm.errors.first("password")]
                                : [],
                            },
                            model: {
                              value: _vm.model.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "password", $$v)
                              },
                              expression: "model.password",
                            },
                          }),
                          _vm._v(" "),
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  required: true,
                                  is: _vm.model.password,
                                },
                                expression:
                                  "{ required: true, is: model.password }",
                              },
                            ],
                            attrs: {
                              "append-icon": _vm.confirmPasswordVisibility
                                ? "visibility_off"
                                : "visibility",
                              "append-icon-cb": function () {
                                return (_vm.confirmPasswordVisibility =
                                  !_vm.confirmPasswordVisibility)
                              },
                              type: _vm.confirmPasswordVisibility
                                ? "text"
                                : "password",
                              placeholder: _vm.$t(
                                "users.reset_password.confirm_password"
                              ),
                              required: "",
                              name: "confirmPassword",
                              "error-messages": _vm.errors.has(
                                "confirmPassword"
                              )
                                ? [_vm.errors.first("confirmPassword")]
                                : [],
                            },
                            model: {
                              value: _vm.model.confirmationPassword,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "confirmationPassword", $$v)
                              },
                              expression: "model.confirmationPassword",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "secondary",
                              attrs: { type: "submit" },
                            },
                            [
                              _c("v-icon", { attrs: { dark: "", left: "" } }, [
                                _vm._v("check"),
                              ]),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$t("users.reset_password.action")
                                  ) +
                                  "\n            "
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-spacer"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }