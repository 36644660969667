"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_class_component_1 = require("vue-class-component");
var Chart_vue_1 = require("./Chart.vue");
var Flag_vue_1 = require("../campaign/Flag.vue");
var State_vue_1 = require("../campaign/State.vue");
var Type_vue_1 = require("../campaign/Type.vue");
var Filter_vue_1 = require("../filter/Filter.vue");
var decorators_1 = require("../../decorator/decorators");
var config_1 = require("../../dic/config");
var campaign_statistic_repository_1 = require("../../repositories/campaign_statistic.repository");
var List_vue_1 = require("../filter/campaign/List.vue");
var moment = require("moment");
var Progress_vue_1 = require("../layout/Progress.vue");
var vuex_class_1 = require("vuex-class");
var layoutModule = vuex_class_1.namespace('layout');
var StatisticComponent = (function (_super) {
    __extends(StatisticComponent, _super);
    function StatisticComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isError = false;
        _this.periodStartDialog = false;
        _this.periodEndDialog = false;
        return _this;
    }
    StatisticComponent.prototype.created = function () {
        this.filter = new campaign_statistic_repository_1.CampaignStatisticFilter();
        this.filter = new campaign_statistic_repository_1.CampaignStatisticFilter();
        var campaignUuid = this.$route.params.campaignUuid;
        if (campaignUuid) {
            this.filter.campaigns.push(campaignUuid);
        }
        else if (this.$route.query.campaigns) {
            this.filter.campaigns = this.$route.query.campaigns.split('|');
        }
        if (this.$route.query.periodStart) {
            this.filter.periodStart = new Date(this.$route.query.periodStart);
        }
        if (this.$route.query.periodEnd) {
            this.filter.periodEnd = new Date(this.$route.query.periodEnd);
        }
    };
    StatisticComponent.prototype.mounted = function () {
        this.updateStatistics();
    };
    StatisticComponent.prototype.data = function () {
        return {
            campaignStatistics: this.campaignStatistics,
            chartData: this.chartData,
            filter: this.filter
        };
    };
    StatisticComponent.prototype.setPeriodStart = function (date) {
        this.periodStartDialog = false;
        this.filter.periodStart = new Date(date);
        var maxDateEnd = moment(this.filter.periodStart).add(365, 'd');
        if (moment(this.filter.periodEnd).isAfter(maxDateEnd)) {
            this.filter.periodEnd = maxDateEnd.toDate();
        }
    };
    StatisticComponent.prototype.setPeriodEnd = function (date) {
        this.filter.periodEnd = new Date(date);
        this.periodEndDialog = false;
    };
    StatisticComponent.prototype.searchFilter = function () {
        this.updateStatistics();
        this.$router.push({
            name: 'statistics',
            query: {
                periodStart: moment(this.filter.periodStart).format('YYYY-MM-DD'),
                periodEnd: moment(this.filter.periodEnd).format('YYYY-MM-DD'),
                campaigns: this.filter.campaigns.join('|')
            }
        });
    };
    StatisticComponent.prototype.resetFilter = function () {
        this.chartData = undefined;
        this.campaignStatistics = undefined;
    };
    StatisticComponent.prototype.isFilterOpened = function () {
        return this.filter.campaigns.length === 0;
    };
    StatisticComponent.prototype.updateStatistics = function () {
        var _this = this;
        this.chartData = undefined;
        this.isError = false;
        if (this.filter.campaigns.length === 0) {
            return;
        }
        this.showProgressBar();
        this.campaignStatisticRepository
            .getCampaignStatistics(this.filter)
            .then(function (campaignStatistics) {
            _this.campaignStatistics = campaignStatistics;
            var labels = [];
            var datasets = [];
            _this.campaignStatistics.forEach(function (campaignStatistic) {
                var data = [];
                campaignStatistic.points.forEach(function (point) {
                    var date = moment(point.date).format('DD/MM');
                    if (labels.indexOf(date) === -1) {
                        labels.push(date);
                    }
                    data.push(point.count);
                });
                datasets.push({
                    data: data,
                    label: campaignStatistic.campaign.name
                });
            });
            _this.chartData = {
                datasets: datasets,
                labels: labels
            };
            _this.hideProgressBar();
        })
            .catch(function () {
            _this.isError = true;
            _this.hideProgressBar();
        });
    };
    Object.defineProperty(StatisticComponent.prototype, "maxDateEnd", {
        get: function () {
            return moment(this.filter.periodStart).add(90, 'd').format('YYYY-MM-DD');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StatisticComponent.prototype, "minDateEnd", {
        get: function () {
            return moment(this.filter.periodStart).format('YYYY-MM-DD');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StatisticComponent.prototype, "headers", {
        get: function () {
            return [
                {
                    text: this.$tc('statistics.headers.campaign'),
                    value: 'campaign',
                    sortable: false
                },
                {
                    text: this.$tc('statistics.headers.type'),
                    value: 'type',
                    sortable: false
                },
                {
                    text: this.$tc('statistics.headers.conversions'),
                    value: 'conversions',
                    sortable: false
                },
                {
                    text: this.$tc('statistics.headers.conversions_validated'),
                    value: 'leads',
                    sortable: false
                }
            ];
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        decorators_1.Container(config_1.default.REPOSITORY_CAMPAIGN_STATISTIC),
        __metadata("design:type", campaign_statistic_repository_1.CampaignStatisticRepository)
    ], StatisticComponent.prototype, "campaignStatisticRepository", void 0);
    __decorate([
        layoutModule.Action('showProgressBar'),
        __metadata("design:type", Function)
    ], StatisticComponent.prototype, "showProgressBar", void 0);
    __decorate([
        layoutModule.Action('hideProgressBar'),
        __metadata("design:type", Function)
    ], StatisticComponent.prototype, "hideProgressBar", void 0);
    StatisticComponent = __decorate([
        vue_class_component_1.default({
            components: {
                ChartComponent: Chart_vue_1.default,
                CampaignFlagComponent: Flag_vue_1.default,
                CampaignStateComponent: State_vue_1.default,
                CampaignTypeComponent: Type_vue_1.default,
                FilterComponent: Filter_vue_1.default,
                CampaignFilterList: List_vue_1.default,
                ProgressComponent: Progress_vue_1.default
            }
        })
    ], StatisticComponent);
    return StatisticComponent;
}(vue_1.default));
exports.default = StatisticComponent;
