"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_router_1 = require("vue-router");
var List_vue_1 = require("../components/campaign/List.vue");
var Statistic_vue_1 = require("../components/statistic/Statistic.vue");
var Profile_vue_1 = require("../components/account/Profile.vue");
var Contact_vue_1 = require("../components/contact/Contact.vue");
var ChangePassword_vue_1 = require("../components/account/ChangePassword.vue");
var AutoConnect_vue_1 = require("../components/login/AutoConnect.vue");
var List_vue_2 = require("../components/user/List.vue");
var Form_vue_1 = require("../components/consent/Form.vue");
var List_vue_3 = require("../components/consent/List.vue");
var List_vue_4 = require("../components/opposition/List.vue");
var List_vue_5 = require("../components/history/List.vue");
var Detail_vue_1 = require("../components/campaignFactory/Detail.vue");
var Forbidden_vue_1 = require("../components/user/Forbidden.vue");
var ResetPassword_vue_1 = require("../components/user/ResetPassword.vue");
var ExportWrapper_vue_1 = require("../components/campaign/ExportWrapper.vue");
var Logout_vue_1 = require("../components/layout/Logout.vue");
var List_vue_6 = require("../components/tagtracking/List.vue");
var Bat_vue_1 = require("../components/campaign/Bat.vue");
var container_1 = require("../dic/container");
var config_1 = require("../dic/config");
var store_1 = require("../store/store");
var client_service_1 = require("../services/client.service");
vue_1.default.use(vue_router_1.default);
var meta = {
    secure: true,
    roles: [
        'ROLE_ADVERTISER_LIMITED',
        'ROLE_ADVERTISER_DPO',
        'ROLE_ADVERTISER_ADMIN'
    ]
};
var routerOptions = {
    routes: [
        {
            path: '/campaigns',
            name: 'campaigns',
            component: List_vue_1.default,
            meta: meta
        },
        {
            path: '/campaigns/:campaignUuid/statistics',
            name: 'campaign_statistics',
            component: Statistic_vue_1.default,
            meta: meta
        },
        {
            path: '/campaigns/:campaignUuid/export/lead/state/:state/dateStart/:dateStart/dateEnd/:dateEnd',
            name: 'campaign_export',
            component: ExportWrapper_vue_1.default,
            meta: {
                secure: true,
                roles: ['ROLE_ADVERTISER_DPO', 'ROLE_ADVERTISER_ADMIN']
            }
        },
        {
            path: '/campaigns/:campaignUuid/conversions/:token',
            name: 'campaign_conversions',
            component: List_vue_6.default,
            meta: { secure: false }
        },
        {
            path: '/campaigns/:campaignUuid/conversions',
            name: 'campaign_conversions_logged',
            component: List_vue_6.default,
            meta: meta
        },
        {
            path: '/campaigns/:campaignUuid/bat/:token',
            name: 'campaign_bat_token',
            component: Bat_vue_1.default,
            meta: { secure: false }
        },
        {
            path: '/campaigns/:campaignUuid/bat',
            name: 'campaign_bat',
            component: Bat_vue_1.default,
            meta: meta
        },
        {
            path: '/statistics',
            name: 'statistics',
            component: Statistic_vue_1.default,
            meta: meta
        },
        {
            path: '/consents/search',
            name: 'consents_search',
            component: Form_vue_1.default,
            meta: {
                secure: true,
                roles: ['ROLE_ADVERTISER_DPO', 'ROLE_ADVERTISER_ADMIN']
            }
        },
        {
            path: '/consents/search/:email',
            name: 'consents_list',
            component: List_vue_3.default,
            meta: {
                secure: true,
                roles: ['ROLE_ADVERTISER_DPO', 'ROLE_ADVERTISER_ADMIN']
            }
        },
        {
            path: '/history',
            name: 'history',
            component: List_vue_5.default,
            meta: {
                secure: true,
                roles: ['ROLE_ADVERTISER_DPO', 'ROLE_ADVERTISER_ADMIN']
            }
        },
        {
            path: '/oppositions',
            name: 'oppositions',
            component: List_vue_4.default,
            meta: {
                secure: true,
                roles: [
                    'ROLE_ADVERTISER_DPO',
                    'ROLE_ADVERTISER_ADMIN',
                    'ROLE_ADVERTISER_LIMITED_OPPOSITION'
                ]
            }
        },
        {
            path: '/campaigns/:campaignUuid/factory/type/:type',
            name: 'CampaignLiveRequest',
            component: Detail_vue_1.default,
            meta: meta
        },
        {
            path: '/campaigns/:campaignUuid/factory/:factoryId/type/:type',
            name: 'CampaignLiveRequestEdit',
            component: Detail_vue_1.default,
            meta: meta
        },
        {
            path: '/users',
            name: 'users',
            component: List_vue_2.default,
            meta: {
                secure: true,
                roles: ['ROLE_ADVERTISER_DPO', 'ROLE_ADVERTISER_ADMIN']
            }
        },
        {
            path: '/account',
            name: 'profile',
            component: Profile_vue_1.default,
            meta: meta
        },
        {
            path: '/account/password',
            name: 'change-password',
            component: ChangePassword_vue_1.default,
            meta: meta
        },
        {
            path: '/contact',
            name: 'contact',
            component: Contact_vue_1.default,
            meta: meta
        },
        {
            path: '/forbidden',
            name: 'forbidden',
            component: Forbidden_vue_1.default,
            meta: { secure: false }
        },
        {
            path: '/connect/:impersonatedUserId/:userId/:token',
            name: 'connect_as',
            component: AutoConnect_vue_1.default,
            meta: { secure: false }
        },
        {
            path: '/connect/:userId/:token',
            name: 'connect',
            component: AutoConnect_vue_1.default,
            meta: { secure: false }
        },
        {
            path: '/logout',
            name: 'logout',
            component: Logout_vue_1.default,
            meta: { secure: false }
        },
        {
            path: '/reset/password/:token',
            name: 'reset_password',
            component: ResetPassword_vue_1.default,
            meta: { secure: false }
        },
        {
            path: '/*',
            name: 'index',
            redirect: { name: 'campaigns' },
            meta: meta
        }
    ],
    scrollBehavior: function () {
        return { x: 0, y: 0 };
    }
};
var router = new vue_router_1.default(routerOptions);
router.beforeEach(function (to, _from, next) {
    var _a, _b;
    var tokenStorage = container_1.default.get(config_1.default.ACCESS_TOKEN_STORAGE);
    if (((_a = to.meta) === null || _a === void 0 ? void 0 : _a.secure) === true && tokenStorage.hasAccessToken() === false) {
        next({ name: 'logout', query: { expired: '1' } });
    }
    else if (((_b = to.meta) === null || _b === void 0 ? void 0 : _b.secure) === true) {
        store_1.store
            .dispatch('user/loadUser')
            .then(function (user) {
            var _a, _b;
            if (((_a = to.meta) === null || _a === void 0 ? void 0 : _a.roles.length) > 0) {
                if ((_b = to.meta) === null || _b === void 0 ? void 0 : _b.roles.some(function (role) {
                    return user.hasRole(role);
                })) {
                    next();
                }
                else {
                    next({ name: 'forbidden' });
                }
            }
            else {
                next();
            }
        })
            .catch(function () {
            next({ name: 'logout', query: { expired: '1' } });
        });
    }
    else {
        next();
    }
});
client_service_1.ClientService.addInterceptor(function (request, response) {
    if ((response.status === undefined || response.status >= 400) &&
        response.status !== 404 &&
        typeof newrelic !== 'undefined') {
        if (response.error) {
            newrelic.noticeError(response.error);
        }
        else {
            newrelic.noticeError(new Error('API Error : ' +
                JSON.stringify({ request: request, response: response })));
        }
    }
    if (response.status === 401 && request.logout !== false) {
        store_1.store.dispatch('login/logout');
        router.push({ name: 'logout', query: { expired: '1' } });
    }
});
exports.default = router;
