"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogTypeEnum = exports.Log = void 0;
var Log = (function () {
    function Log(logData) {
        this.createdAt = new Date(logData.createdAt);
        this.firstname = logData.firstname || '';
        this.lastname = logData.lastname || '';
        this.type = logData.type;
        this.email = logData.email;
        var extraData = logData.extraData;
        if (extraData) {
            this.extraData = {
                email: extraData.email || undefined,
                dateStart: extraData.dateStart
                    ? new Date(extraData.dateStart)
                    : undefined,
                dateEnd: extraData.dateEnd ? new Date(extraData.dateEnd) : undefined,
                leadState: extraData.leadState || undefined,
                campaignName: extraData.campaignName || undefined
            };
        }
    }
    Log.prototype.getFullName = function () {
        return this.firstname + ' ' + this.lastname;
    };
    return Log;
}());
exports.Log = Log;
var LogTypeEnum;
(function (LogTypeEnum) {
    LogTypeEnum["SEARCH_CONSENT"] = "search_consent";
    LogTypeEnum["LOGIN"] = "login";
    LogTypeEnum["EXPORT_LEAD"] = "export_lead";
})(LogTypeEnum = exports.LogTypeEnum || (exports.LogTypeEnum = {}));
