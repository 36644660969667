var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pb-5" },
            [
              _c(
                "filter-component",
                {
                  attrs: { filter: _vm.filter },
                  on: { submit: _vm.searchFilter, reset: _vm.resetFilter },
                },
                [
                  _c("v-text-field", {
                    attrs: { label: _vm.$tc("oppositions.filter.email") },
                    model: {
                      value: _vm.filter.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.filter, "email", $$v)
                      },
                      expression: "filter.email",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            [
              _vm.user && !_vm.user.hasRoleLimitedOpposition()
                ? _c("opposition-setup-component")
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-data-table", {
        staticClass: "mt-3",
        attrs: {
          headers: _vm.headers,
          items: _vm.oppositions,
          "no-data-text": _vm.noDataText,
          "hide-default-footer": "",
          loading: _vm.progressBar,
          "loading-text": _vm.$t("loading"),
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function (props) {
              return [
                _c("tr", [
                  _c("td", [
                    _vm._v(_vm._s(_vm._f("gmtdate")(props.item.opposedAt))),
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(props.item.email))]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          props.item.phoneNumber === null
                            ? _vm.$t("oppositions.empty_data.phone_number")
                            : props.item.phoneNumber
                        ) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(_vm._s(_vm.getSentenceAction(props.item.type))),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    props.item.sendingDate
                      ? _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("gmtdate")(props.item.sendingDate)
                              ) +
                              "\n          "
                          ),
                        ])
                      : _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("oppositions.none")) +
                              "\n          "
                          ),
                        ]),
                  ]),
                  _vm._v(" "),
                  props.item.emailsSentTo &&
                  props.item.emailsSentTo.length === 0
                    ? _c("td", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("oppositions.none")) +
                            "\n        "
                        ),
                      ])
                    : _vm.user && !_vm.user.hasRoleLimitedOpposition()
                    ? _c(
                        "td",
                        _vm._l(props.item.emailsSentTo, function (email) {
                          return _c(
                            "div",
                            { key: props.item.email + "-" + email },
                            [
                              _c("a", { attrs: { href: "mailto:" + email } }, [
                                _vm._v(_vm._s(email)),
                              ]),
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            color: props.item.isSent() ? "green" : "red",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              props.item.isSent()
                                ? "mdi-check"
                                : "mdi-window-close"
                            )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mt-2" },
        [
          _c("v-spacer"),
          _vm._v(" "),
          _vm.totalCount
            ? _c("v-pagination", {
                attrs: { length: _vm.countPages(), "total-visible": "6" },
                model: {
                  value: _vm.page,
                  callback: function ($$v) {
                    _vm.page = $$v
                  },
                  expression: "page",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("v-spacer"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }