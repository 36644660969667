var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-select", {
    attrs: {
      "hide-details": "",
      multiple: "",
      "single-line": "",
      items: _vm.campaignTypes,
      placeholder: _vm.$t("campaigns.form.filters.placeholders.type"),
    },
    model: {
      value: _vm.filter.types,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "types", $$v)
      },
      expression: "filter.types",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }