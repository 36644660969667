var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _vm.displayMenu
        ? [
            _c(
              "v-navigation-drawer",
              {
                attrs: { app: "", fixed: "", clipped: "", "hide-overlay": "" },
                model: {
                  value: _vm.drawer,
                  callback: function ($$v) {
                    _vm.drawer = $$v
                  },
                  expression: "drawer",
                },
              },
              [
                _c(
                  "v-list",
                  { attrs: { dense: "" } },
                  [
                    !_vm.user.hasRoleLimitedOpposition()
                      ? _c(
                          "v-list-item",
                          { attrs: { color: "primary", to: "/campaigns" } },
                          [
                            _c(
                              "v-list-item-action",
                              [_c("v-icon", [_vm._v("wifi_tethering")])],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(_vm._s(_vm.$tc("menu.campaigns"))),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.user.hasRoleLimitedOpposition()
                      ? _c(
                          "v-list-item",
                          { attrs: { color: "primary", to: "/statistics" } },
                          [
                            _c(
                              "v-list-item-action",
                              [_c("v-icon", [_vm._v("insert_chart")])],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(_vm._s(_vm.$tc("menu.statistics"))),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.user.hasRoleDPO() || _vm.user.hasRoleAdmin()
                      ? _c(
                          "v-list-item",
                          {
                            attrs: { color: "primary", to: "/consents/search" },
                          },
                          [
                            _c(
                              "v-list-item-action",
                              [_c("v-icon", [_vm._v("check_circle_outline")])],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(_vm._s(_vm.$tc("menu.consents"))),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.user.hasRoleDPO() ||
                    _vm.user.hasRoleAdmin() ||
                    _vm.user.hasRoleLimitedOpposition()
                      ? _c(
                          "v-list-item",
                          { attrs: { color: "primary", to: "/oppositions" } },
                          [
                            _c(
                              "v-list-item-action",
                              [_c("v-icon", [_vm._v("pan_tool")])],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(_vm._s(_vm.$tc("menu.oppositions"))),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("v-divider"),
                    _vm._v(" "),
                    _vm.user.hasRoleDPO() || _vm.user.hasRoleAdmin()
                      ? _c(
                          "v-list-item",
                          { attrs: { color: "primary", to: "/history" } },
                          [
                            _c(
                              "v-list-item-action",
                              [_c("v-icon", [_vm._v("history")])],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(_vm._s(_vm.$tc("menu.history"))),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.user.hasRoleDPO() || _vm.user.hasRoleAdmin()
                      ? _c(
                          "v-list-item",
                          { attrs: { color: "primary", to: "/users" } },
                          [
                            _c(
                              "v-list-item-action",
                              [_c("v-icon", [_vm._v("people")])],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(_vm._s(_vm.$tc("menu.users"))),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-footer",
                  { attrs: { absolute: "", color: "transparent" } },
                  [
                    _c("v-spacer"),
                    _vm._v(" "),
                    _c("div", { staticClass: "text-caption" }, [
                      _vm._v("\n          ©" + _vm._s(_vm.currentYear) + " "),
                      _c("b", [_vm._v("WebRivage")]),
                    ]),
                    _vm._v(" "),
                    _c("v-spacer"),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-app-bar",
        {
          attrs: {
            app: "",
            dark: "",
            "clipped-left": "",
            fixed: "",
            color: "primary",
          },
        },
        [
          _c("v-app-bar-nav-icon", {
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.drawer = !_vm.drawer
              },
            },
          }),
          _vm._v(" "),
          _vm.progressBar ? _c("progress-component") : _vm._e(),
          _vm._v(" "),
          _c("span", { staticClass: "pt-2 hidden-xs-and-down" }, [
            _c("img", { attrs: { height: "35", src: "/assets/logo.png" } }),
          ]),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _vm.user && _vm.user.impersonated === true
            ? _c(
                "v-toolbar-items",
                { staticClass: "hidden-sm-and-down" },
                [
                  _c("v-btn", { attrs: { color: "error" } }, [
                    _vm._v(
                      _vm._s(_vm.$t("layout.impersonated")) +
                        " : " +
                        _vm._s(_vm.user.company.name)
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("locale-switcher-component"),
          _vm._v(" "),
          _c("settings-component"),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-content",
        { staticClass: "grey lighten-2" },
        [_c("router-view")],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }